import React, { useMemo } from 'react';
import { useGetStreamsQuery } from '../Api/apiSlice';
import { StreamList } from './StreamList';
import { LOTWConnector } from '../Stream/LOTWConnector';
import { StreamData, StreamFeed, StreamsFromApi } from '../Api/apiTypes';
import { Link, useParams } from 'react-router-dom';
import useShop from '../Shop/useShop';
import { Container } from '../../components/layout/Container';

interface DiscoverPageParams {
  shopId: string;
  streamId: string;
}

const DiscoverPage: React.FC = () => {
  const { data: streams } = useGetStreamsQuery();

  const { shopId: paramShopId, streamId: paramStreamId } =
    useParams<DiscoverPageParams>();
  const firstStream = useMemo(() => getFirstStream(streams), [streams]);

  const paramStreamIdAsNumber = paramStreamId
    ? parseInt(paramStreamId)
    : undefined;
  const streamId = paramStreamIdAsNumber || firstStream?.id;
  const shopId = paramShopId || firstStream?.shop;
  const { data: shop } = useShop(shopId);
  const selectedShopAndStream = `${shopId}-${streamId}`;

  return (
    <Container>
      <div className={`relative mt-6 flex-1`}>
        <Link to={`/shops/${shopId}`}>
          <h2 className={`mb-3`}>{shop?.shop_name || ''}</h2>
        </Link>
        <LOTWConnector shopId={shopId} streamId={streamId} />
      </div>

      <div className={`relative h-full w-full`}>
        {streams?.map((section: StreamFeed) => (
          <StreamList
            key={`section-${section.id}`}
            className="my-6"
            initialStreams={section.data}
            initialTotal={section.meta.total}
            lastPage={section.meta.last_page}
            perPage={section.meta.per_page}
            selectedShopAndStream={selectedShopAndStream}
            title={section.name}
            type={section.id}
          />
        ))}
      </div>
    </Container>
  );
};

export { DiscoverPage };

function getFirstStream(streams?: StreamsFromApi): StreamData {
  if (!streams) {
    return { shop: '', id: 0 } as StreamData;
  }

  const firstSection = streams?.find(
    (section: StreamFeed) => section.data.length > 0
  );

  return firstSection?.data?.[0] as StreamData;
}
