import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { marketplaceApi, useLogoutMutation } from '../Api/apiSlice';
import useGlobalLoadingMessage from '../../hooks/useGlobalLoadingMessage';
import useErrorHandling from '../../hooks/useErrorHandling';
import { useAppDispatch } from '../../store/hooks';
import { ToastType } from '../Toast/toastSlice';
import useToast from '../Toast/useToast';
import { ROOT_RESET_STATE } from '../../store/rootReducer';
import { getToken } from '../../helpers/getOrSetLocalStorageItem';

const SignOutPage: React.FC = () => {
  const [logout, { isLoading }] = useLogoutMutation();
  const [show, hide] = useGlobalLoadingMessage('Logging out...');
  const history = useHistory();
  const handleError = useErrorHandling();
  const dispatch = useAppDispatch();
  const token = getToken();
  const { setToastWithTimeout } = useToast();

  useEffect(() => {
    if (!token) {
      history.push('/');
    }
  }, [token, history]);

  useEffect(() => {
    isLoading ? show() : hide();
  }, [isLoading, show, hide]);

  useEffect(() => {
    const doLogout = async () => {
      try {
        await logout().unwrap();
        dispatch(marketplaceApi.util.resetApiState());
        dispatch({ type: ROOT_RESET_STATE });

        setToastWithTimeout({
          type: ToastType.info,
          message: 'You are logged out',
        });

        history.push('/');
      } catch (error) {
        handleError({ error });
      }
    };

    void doLogout();
  }, [logout, handleError, history, setToastWithTimeout, dispatch]);

  return null;
};

export { SignOutPage };
