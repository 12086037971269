import React from 'react';
import wavyBuddiesOrderCancelled from '../../../assets/wavy-buddies-order-cancelled.svg';
import { Button } from '../../atoms/Button';

interface CartFullProps {
  shopName: string;
  checkoutUrl: string;
  onEmpty: () => void;
  isAuthenticated: boolean;
  onGuestCheckout?: () => void;
}

const CartFull: React.FC<CartFullProps> = ({
  shopName,
  checkoutUrl,
  onEmpty,
  isAuthenticated,
  onGuestCheckout,
}) => {
  return (
    <div className={'flex h-full flex-col items-center justify-between p-4'}>
      {/* Spacer */}
      <div className={'h-1/5'} />

      <div className={'flex h-full flex-1 flex-col items-center text-center'}>
        <figure className={'h-72'}>
          <img
            alt={'Shopping bags flying away'}
            src={wavyBuddiesOrderCancelled}
          />
        </figure>

        <p className={'mt-2 text-textDarkSecondary'}>
          In order to add items from a new shop, you’ll first need to checkout.
        </p>
      </div>

      <div className={'mt-10 w-full'}>
        {isAuthenticated && (
          <Button asExternalLink fullWidth primary href={checkoutUrl}>
            Checkout With <span className={'ml-1 font-bold'}>{shopName}</span>
          </Button>
        )}

        {!isAuthenticated && (
          <Button fullWidth primary onClick={onGuestCheckout}>
            Checkout With <span className={'ml-1 font-bold'}>{shopName}</span>
          </Button>
        )}

        <Button fullWidth text className={'mt-4'} onClick={onEmpty}>
          Empty My Bag
        </Button>
      </div>
    </div>
  );
};

export { CartFull };
