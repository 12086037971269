import ReactGA from 'react-ga4';

export function getGoogleAnalyticsClientId() {
  return new Promise<string | null | undefined>((resolve, reject) => {
    ReactGA.ga(
      (tracker: {
        get: (arg0: string) => string | PromiseLike<string | null | undefined>;
      }) => {
        resolve(tracker.get('clientId'));
      }
    );
  });
}
