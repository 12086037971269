import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Button } from '../../atoms/Button';
import { InputWithLabel } from '../../molecules/InputWithLabel';

export interface ForgotPasswordInputs {
  email: string;
}

export interface ForgotPasswordFormProps {
  onSubmit: (data: ForgotPasswordInputs) => void;
}

const rules = {
  email: {
    required: 'Email is required',
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: 'Must be a valid email',
    },
  },
};

export const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({
  onSubmit: componentOnSubmit,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordInputs>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit: SubmitHandler<ForgotPasswordInputs> = async (data) => {
    try {
      setIsSubmitting(true);
      return await componentOnSubmit(data);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="relative" data-testid="forgot-password-form">
      <h1 className="mb-4">Forgot Password</h1>

      <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
        <InputWithLabel
          autoComplete="email"
          error={errors?.email?.message}
          id="email"
          labelText="Email Address"
          {...register('email', rules.email)}
        />
        <Button fullWidth primary isLoading={isSubmitting} type="submit">
          Submit
        </Button>
      </form>
    </div>
  );
};
