import React from 'react';
import { Button } from '../../components/atoms/Button';
import { BsCheckCircle, BsCheckCircleFill } from 'react-icons/bs';
import { WaitlistItemType } from './WaitlistItem';
import { OutlinedButton } from '../../components/atoms/OutlinedButton';

interface WaitlistItemButtonProps {
  item: WaitlistItemType;
  onClick: () => void;
}

const WaitlistItemButton: React.FC<WaitlistItemButtonProps> = ({
  item,
  onClick,
}) => {
  if (item.isCart) {
    return (
      <Button asExternalLink primary href={item.checkoutUrl}>
        Checkout
      </Button>
    );
  }

  if (item.isInStock && item.isWaitlist) {
    return (
      <Button primary onClick={onClick}>
        Checkout
      </Button>
    );
  }

  if (!item.allowPreAuth && !item.isInStock) {
    return null;
  }

  if (item.isPreAuthorized) {
    return (
      <div
        className={'w-fit flex items-center rounded border-2 p-3'}
        style={{
          backgroundColor: 'rgba(248, 254, 239, 1)',
          height: 48,
          color: 'rgba(18, 52, 43, 1)',
        }}
      >
        <BsCheckCircleFill className={'mr-2'} color={'rgba(18, 52, 43, 1)'} />
        Pre-Authorized
      </div>
    );
  } else {
    return (
      <OutlinedButton asExternalLink color="accent" href={item.preAuthUrl}>
        <BsCheckCircle className={'mr-2'} />
        Preauthorize Now!
      </OutlinedButton>
    );
  }
};

export { WaitlistItemButton };
