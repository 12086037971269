import React from 'react';
import { FaFacebook } from 'react-icons/fa';
import { Button } from './Button';

interface FacebookButtonProps {
  disabled?: boolean;
  isLoading?: boolean;
  onClick: () => void;
}

const FacebookButton: React.FC<FacebookButtonProps> = ({
  disabled,
  isLoading,
  onClick,
}) => {
  return (
    <Button
      facebook
      fullWidth
      disabled={disabled}
      isLoading={isLoading}
      onClick={onClick}
    >
      {!isLoading && <FaFacebook className="mr-2" size="1.25rem" />} Continue
      with Facebook
    </Button>
  );
};

export { FacebookButton };
