import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Button } from '../../atoms/Button';
import { InputWithLabel } from '../../molecules/InputWithLabel';
import { FaEye } from 'react-icons/fa';

export interface GuestSignUpInputs {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordConfirmation: string;
}

export interface GuestSignUpFormProps {
  onSubmit: (data: GuestSignUpInputs) => void;
}

const rules = {
  email: {
    required: 'Email is required',
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: 'Must be a valid email',
    },
  },
  password: {
    required: 'Password is required',
    minLength: {
      value: 8,
      message: 'Must be at least 8 characters',
    },
    maxLength: {
      value: 255,
      message: 'Must be less than 255 characters',
    },
    validate: {
      oneLower: (value: string) =>
        /[a-z]/.test(value) || 'Must contain at least one lowercase letter',
      oneUpper: (value: string) =>
        /[A-Z]/.test(value) || 'Must contain at least one uppercase letter',
      oneNumber: (value: string) =>
        /[0-9]/.test(value) || 'Must contain at least one number',
    },
  },
};

export const GuestSignUpForm: React.FC<GuestSignUpFormProps> = ({
  onSubmit: componentOnSubmit,
}) => {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<GuestSignUpInputs>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isShowingPassword, setIsShowingPassword] = useState(false);
  const [isShowingConfirmPassword, setIsShowingConfirmPassword] =
    useState(false);

  const onSubmit: SubmitHandler<GuestSignUpInputs> = async (data) => {
    try {
      setIsSubmitting(true);
      await componentOnSubmit(data);
      return;
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="space-y-4">
      <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
        <InputWithLabel
          autoComplete="email"
          id="email"
          labelText="Email Address"
          {...register('email', rules.email)}
          error={errors?.email?.message}
        />
        <div className="grid grid-cols-1 gap-y-1 gap-x-8 space-y-6 md:grid-cols-2 md:space-y-0">
          <InputWithLabel
            appendIcon={<FaEye />}
            appendIconId={'show-password-toggle'}
            id="password"
            labelText="Create Password"
            showAppendIcon={true}
            type={isShowingPassword ? 'text' : 'password'}
            onClickAppendIcon={() => setIsShowingPassword(!isShowingPassword)}
            {...register('password', rules.password)}
            error={errors?.password?.message}
          />
          <InputWithLabel
            appendIcon={<FaEye />}
            appendIconId={'show-confirm-password-toggle'}
            id="confirm-password"
            labelText="Confirm Password"
            showAppendIcon={true}
            type={isShowingConfirmPassword ? 'text' : 'password'}
            onClickAppendIcon={() =>
              setIsShowingConfirmPassword(!isShowingConfirmPassword)
            }
            {...register('passwordConfirmation', {
              validate: {
                matches: (value) =>
                  value === getValues('password') ||
                  'Password confirmation must match',
              },
            })}
            error={errors?.passwordConfirmation?.message}
          />
        </div>
        <Button fullWidth primary disabled={isSubmitting} type="submit">
          Create & Register Account
        </Button>
      </form>
    </div>
  );
};
