import React from 'react';
import cn from 'classnames';

export type ContainerProps = {
  className?: string;
};

export const Container: React.FC<ContainerProps> = ({
  children,
  className = '',
}) => <div className={cn(className, 'container mx-auto')}>{children}</div>;
