import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Button } from '../../atoms/Button';
import { FacebookButton } from '../../atoms/FacebookButton';
import { TextDivider } from '../../atoms/TextDivider';
import { InputWithLabel } from '../../molecules/InputWithLabel';

export interface SignInInputs {
  email: string;
  password: string;
}

export interface SignInFormProps {
  onSubmit: (data: SignInInputs) => Promise<void>;
  onSkipSignIn: () => void;
  onSignUp: () => void;
  onForgotPassword: () => void;
  onFacebookLogin: () => Promise<void>;
  hideSkipSignIn?: boolean;
}

const rules = {
  email: { required: 'Email is required' },
  password: { required: 'Password is required' },
};

export const SignInForm: React.FC<SignInFormProps> = ({
  onSubmit: componentOnSubmit,
  onSkipSignIn,
  onSignUp,
  onForgotPassword,
  onFacebookLogin,
  hideSkipSignIn,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignInInputs>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFacebookSubmitting, setIsFacebookSubmitting] = useState(false);
  const disabled = isSubmitting || isFacebookSubmitting;

  const onSubmit: SubmitHandler<SignInInputs> = async (data) => {
    try {
      setIsSubmitting(true);
      return await componentOnSubmit(data);
    } catch {
      setIsSubmitting(false);
    }
  };

  const onFacebookSubmit = async () => {
    try {
      setIsFacebookSubmitting(true);
      return await onFacebookLogin();
    } catch {
      setIsFacebookSubmitting(false);
    }
  };

  return (
    <div className="relative" data-testid="sign-in-form">
      <h1 className="mb-4">Sign In</h1>

      <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
        <InputWithLabel
          autoComplete="email"
          error={errors?.email?.message}
          id="email"
          labelText="Email Address"
          {...register('email', rules.email)}
        />

        <InputWithLabel
          autoComplete="current-password"
          error={errors?.password?.message}
          id="password"
          labelText="Password"
          type="password"
          {...register('password', rules.password)}
        />

        <Button onClick={onForgotPassword}>Forgot your password?</Button>

        <div className="mt-3 space-y-3">
          <Button
            fullWidth
            primary
            disabled={disabled}
            isLoading={isSubmitting}
            type="submit"
          >
            Sign In
          </Button>
          <TextDivider text="OR" />
          <FacebookButton
            disabled={disabled}
            isLoading={isFacebookSubmitting}
            onClick={onFacebookSubmit}
          />
        </div>
      </form>

      <div className="mt-6">
        <div className="mt-12 flex justify-between text-sm ">
          {!hideSkipSignIn && (
            <Button onClick={onSkipSignIn}>Skip Sign-In</Button>
          )}
          <Button onClick={onSignUp}>Need an account?</Button>
        </div>
      </div>
    </div>
  );
};
