import React from 'react';
import { Redirect } from 'react-router-dom';
import { Card } from '../../components/layout/Card';
import { Banner } from '../../components/molecules/Banner';
import { LoadingIndicator } from '../../components/molecules/LoadingIndicator';
import { OrderConfirmedCard } from '../../components/organisms/Checkout/OrderConfirmedCard';
import { convertOrder } from '../../components/organisms/OrderHistory/helpers/convertOrderHistoryApiResponseToOrderHistory';
import { OrderDetailItem } from '../../components/organisms/OrderHistory/OrderHistory/OrderDetailItem';
import { OrderProductDetails } from '../../components/organisms/OrderHistory/OrderHistory/OrderProductDetails';
import {
  GuestSignUpForm,
  GuestSignUpInputs,
} from '../../components/organisms/SignUpForm/GuestSignUpForm';
import useErrorHandling from '../../hooks/useErrorHandling';
import useQuery from '../../hooks/useQuery';
import { useAppSelector } from '../../store/hooks';
import { selectCurrentUser } from '../Auth/authSlice';
import useShop from '../Shop/useShop';
import useOrder from './useOrder';

interface OrderProps {
  test?: string | null;
}

const Order: React.FC<OrderProps> = ({ test }) => {
  const queryParams = useQuery();
  const shopId = queryParams.shop;
  const orderId = Number(queryParams.orderId);
  const { data: shop, isLoading: isLoadingShop } = useShop(shopId);
  const {
    data: orderResponse,
    isLoading: isLoadingOrder,
    registerGuest,
  } = useOrder(shopId, orderId);
  const user = useAppSelector(selectCurrentUser);
  const handleError = useErrorHandling();
  const order = orderResponse ? convertOrder(orderResponse) : null;
  const [didRegisterGuest, setDidRegisterGuest] = React.useState(false);

  const showRegisterGuestForm = user?.isGuest && !didRegisterGuest;

  const isLoading = isLoadingShop || isLoadingOrder;

  if (isLoading) {
    return <LoadingIndicator message="Loading order..." />;
  } else if (!orderId || !shopId || !order || !user) {
    return <Redirect to={'/sign-in'} />;
  }

  const handleSubmit = async (data: GuestSignUpInputs) => {
    try {
      await registerGuest(data).unwrap();
      setDidRegisterGuest(true);
    } catch (error) {
      handleError({ error });
    }
  };

  return (
    <>
      <OrderConfirmedCard
        continueShoppingLink={'/'}
        orderDateText={`Order placed at ${order.purchaseTime}`}
        orderId={String(order.orderNumber)}
        shopName={shop?.shop_name}
      />
      <div className="mt-4 grid grid-cols-1 gap-x-8 space-y-4 md:grid-cols-5 md:space-y-0 lg:grid-cols-3">
        <div className="col-span-1 space-y-8 md:col-span-3 lg:col-span-2">
          {showRegisterGuestForm && (
            <div>
              <div className="mb-4">
                <div className="mb-1 flex items-center justify-between">
                  <h2 className="font-medium">Register Your Account</h2>
                  <p className="text-gray-500">Optional</p>
                </div>
                <p>
                  Enter a password and create your account today for faster
                  checkout and stay updated on new products and promotions.
                </p>
              </div>
              <GuestSignUpForm onSubmit={handleSubmit} />
            </div>
          )}

          {didRegisterGuest && (
            <Banner id="Success!" type="success">
              Your account has been created
            </Banner>
          )}

          <Card className={'p-4'} withBorder={true} withShadow={false}>
            <h2 className="mb-4 font-medium">Items Ordered</h2>
            <div className="space-y-4">
              {order.orderProducts.map((orderProduct) => {
                return (
                  <Card
                    key={orderProduct.localIdForFrontend}
                    className={'p-4'}
                    withBorder={true}
                    withShadow={false}
                  >
                    <OrderProductDetails
                      orderProduct={orderProduct}
                      showPrice={true}
                      showShopInfo={false}
                      showStatus={false}
                    />
                  </Card>
                );
              })}
            </div>
          </Card>
        </div>
        <div className="col-span-1 md:col-span-2 lg:col-span-1">
          <Card className={'p-4'} withBorder={true} withShadow={false}>
            <h2 className="mb-4 font-medium">Order Details</h2>
            <div className={'space-y-1.5'}>
              {order.orderNumber != null && (
                <OrderDetailItem
                  title={'Order Number'}
                  value={order.orderNumber}
                />
              )}
              <OrderDetailItem
                title={'Purchase Date & Time'}
                value={order.purchaseDateAndTime}
              />
              <OrderDetailItem title={'Status'} value={order.status} />

              {order.localPickupLocation ? (
                <OrderDetailItem
                  title={'Local Pickup'}
                  value={`${order.localPickupLocation.streetAddress}`}
                  valueLine2={`${order.localPickupLocation.city}, ${order.localPickupLocation.state} ${order.localPickupLocation.zip}`}
                />
              ) : (
                <OrderDetailItem
                  title={'Shipping'}
                  value={
                    order?.shippingStreetAddress2
                      ? `${order.shippingStreetAddress}, ${order.shippingStreetAddress2}`
                      : order.shippingStreetAddress
                  }
                  valueLine2={order.shippingCityStateZip}
                />
              )}

              {order.paymentMethod === 'Credit Card' && (
                <OrderDetailItem
                  title={'Payment'}
                  value={`**** **** **** ${order.ccLast4} ${order.ccType}`}
                />
              )}
              {order.paymentMethod !== 'Credit Card' && (
                <OrderDetailItem
                  title={'Payment'}
                  value={order.paymentMethod}
                />
              )}
            </div>
          </Card>
        </div>
      </div>
    </>
  );
};

export { Order };
