import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store/store';

export interface GlobalLoadingMessage {
  key: string;
  message: string;
}

type UiState = {
  globalLoadingMessages: GlobalLoadingMessage[];
  isSidebarCartOpen: boolean;
  isSidebarWaitlistOpen: boolean;
};

const slice = createSlice({
  name: 'ui',
  initialState: {
    globalLoadingMessages: [],
    isSidebarCartOpen: false,
    isSidebarWaitlistOpen: false,
  } as UiState,
  reducers: {
    addGlobalLoadingMessage: (
      state,
      { payload }: PayloadAction<GlobalLoadingMessage>
    ) => {
      state.globalLoadingMessages.push(payload);
    },
    removeGlobalLoadingMessage: (state, { payload }: PayloadAction<string>) => {
      state.globalLoadingMessages = state.globalLoadingMessages.filter(
        (m) => m.key !== payload
      );
    },
    setIsSidebarCartOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isSidebarCartOpen = payload;
    },
    setIsSidebarWaitlistOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isSidebarWaitlistOpen = payload;
    },
  },
});

export const {
  addGlobalLoadingMessage,
  removeGlobalLoadingMessage,
  setIsSidebarCartOpen,
  setIsSidebarWaitlistOpen,
} = slice.actions;

export default slice.reducer;

export const selectCurrentGlobalLoadingMessages = (state: RootState) =>
  state.ui.globalLoadingMessages;

export const selectIsSidebarCartOpen = (state: RootState) =>
  state.ui.isSidebarCartOpen;

export const selectIsSidebarWaitlistOpen = (state: RootState) =>
  state.ui.isSidebarWaitlistOpen;
