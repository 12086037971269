import React from 'react';
import { Button } from '../../../atoms/Button';
import { FaChevronLeft } from 'react-icons/fa';
import { Order } from './OrderHistory';
import { Card } from '../../../layout/Card';
import { OrderProductDetails } from './OrderProductDetails';
import { OrderDetailItem } from './OrderDetailItem';

export function OrderDetails({
  order,
  onClose,
}: {
  order: Order;
  onClose: () => void;
}) {
  return (
    <div>
      <div
        className={'mb-6 flex items-center justify-between'}
        style={{ marginLeft: -16, marginTop: -16 }}
      >
        <Button
          text
          className={'flex items-center text-textDarkSecondary'}
          onClick={onClose}
        >
          <FaChevronLeft className={'mr-2'} />
          <span className={'font-medium'}>Your Order History</span>
        </Button>

        <Button
          outlined
          asExternalLink={true}
          href={`mailto:${order.shopEmail}`}
        >
          Contact Shop
        </Button>
      </div>

      <h2 className={'mb-4 font-medium'}>Order Details</h2>

      <div className={'mb-6 grid grid-cols-1 gap-x-7 gap-y-2 md:grid-cols-2'}>
        <div className={'space-y-1.5'}>
          {order.orderNumber != null && (
            <OrderDetailItem title={'Order Number'} value={order.orderNumber} />
          )}
          <OrderDetailItem
            title={'Purchase Date & Time'}
            value={order.purchaseDateAndTime}
          />
          <OrderDetailItem title={'Status'} value={order.status} />

          {order.localPickupLocation ? (
            <OrderDetailItem
              title={'Local Pickup'}
              value={`${order.localPickupLocation.streetAddress}`}
              valueLine2={`${order.localPickupLocation.city}, ${order.localPickupLocation.state} ${order.localPickupLocation.zip}`}
            />
          ) : (
            <OrderDetailItem
              title={'Shipping'}
              value={
                order?.shippingStreetAddress2
                  ? `${order.shippingStreetAddress}, ${order.shippingStreetAddress2}`
                  : order.shippingStreetAddress
              }
              valueLine2={order.shippingCityStateZip}
            />
          )}

          {order.paymentMethod === 'Credit Card' && (
            <OrderDetailItem
              title={'Payment'}
              value={`**** **** **** ${order.ccLast4} ${order.ccType}`}
            />
          )}
          {order.paymentMethod !== 'Credit Card' && (
            <OrderDetailItem title={'Payment'} value={order.paymentMethod} />
          )}
        </div>

        <div className={'space-y-1.5'}>
          <OrderDetailItem title={'Subtotal'} value={order.subtotal} />
          {/* Notice the minus before couponDiscountAmount */}
          <OrderDetailItem
            title={'Coupon Applied'}
            title2={order.couponId}
            value={`-${order.couponDiscountAmount}`}
            valueClassName={'text-red font-semibold'}
          />
          <OrderDetailItem
            title={'Fees & Estimated Tax'}
            value={order.taxesAndFees}
          />
          <OrderDetailItem title={'Shipping'} value={order.shippingFee} />
          <OrderDetailItem
            title={'Total'}
            titleClassName={'text-black'}
            value={order.total}
          />
        </div>
      </div>

      {order.orderProducts.map((orderProduct) => {
        return (
          <Card
            key={orderProduct.localIdForFrontend}
            className={'mb-3 p-4'}
            withBorder={true}
            withShadow={false}
          >
            <OrderProductDetails orderProduct={orderProduct} />
          </Card>
        );
      })}
    </div>
  );
}
