import React from 'react';
import { FaExclamationCircle } from 'react-icons/fa';
import { selectCurrentToast, ToastType } from '../../features/Toast/toastSlice';
import { useAppSelector } from '../../store/hooks';
import useToast from '../../features/Toast/useToast';
import { Link } from 'react-router-dom';
import { Icon, IconName } from '../atoms/Icon';

const Toast: React.FC = () => {
  const currentToast = useAppSelector(selectCurrentToast);
  const { setToast } = useToast();

  if (!currentToast) return null;

  return (
    <>
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 z-50 flex items-start px-4 py-20"
        data-testid="toast"
      >
        <div className="flex w-full flex-col items-center space-y-4">
          <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-softGray shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="p-4">
              <div className="flex items-center">
                <div className="flex-shrink-0" data-testid="toast-icon">
                  {chooseToastIcon(currentToast.type)}
                </div>

                <div className="ml-3 w-0 flex-1 pt-0.5">
                  {currentToast.message && (
                    <p className="text-base font-semibold text-gray-900">
                      {currentToast.message}
                    </p>
                  )}
                  {currentToast.details && (
                    <p className="text-sm text-gray-500">
                      {currentToast.details}
                    </p>
                  )}
                  {currentToast.link && (
                    <Link
                      className="mt-1 text-sm text-blue-500 underline"
                      to={currentToast.link.to}
                    >
                      {currentToast.link.name}
                    </Link>
                  )}
                </div>

                <div className="ml-4 flex flex-shrink-0">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setToast(null);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <Icon name={IconName.Close} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { Toast };

// PRIVATE FUNCTIONS

function chooseToastIcon(type: ToastType): React.ReactNode {
  switch (type) {
    case ToastType.success:
      return <Icon name={IconName.Confetti} />;
    case ToastType.warning:
      return <FaExclamationCircle color="var(--yellow)" />;
    case ToastType.error:
      return <Icon name={IconName.ErrorCircle} />;
    case ToastType.info:
      return <Icon name={IconName.Info} />;
  }
}
