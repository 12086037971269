import { useEffect, useMemo, useState } from 'react';
import { useGetSectionStreamsQuery } from '../Api/apiSlice';
import { StreamData } from '../Api/apiTypes';
import debounce from 'lodash/debounce';
import unionBy from 'lodash/unionBy';
import get from 'lodash/get';

interface StreamListParams {
  initialStreams: StreamData[];
  initialTotal: number;
  scrollTrigger: number;
  perPage: number;
  lastPage: number;
  type: string;
}

export default function useStreamList({
  initialStreams,
  initialTotal,
  scrollTrigger,
  perPage,
  lastPage,
  type,
}: StreamListParams) {
  const [page, setPage] = useState(1);
  const [streams, setStreams] = useState(initialStreams);
  const { data: pageData, isLoading } = useGetSectionStreamsQuery(
    {
      page,
      type,
    },
    { skip: page === 1 }
  );
  const { data: pollingData } = useGetSectionStreamsQuery(
    {
      page: 1,
      type,
    },
    { pollingInterval: 30000 }
  );
  const total = useMemo(
    () =>
      Math.max(
        initialTotal,
        get(pageData, 'meta.total', 0),
        get(pollingData, 'meta.total', 0)
      ),
    [initialTotal, pageData, pollingData]
  );
  const hasMore = page < lastPage;
  const skeletonCount = Math.min(perPage, total - streams.length);

  const appendStreams = (newStreams?: StreamData[]) => {
    if (!newStreams) return;
    setStreams((streams) =>
      unionBy(streams, newStreams, (stream) => `${stream?.shop}${stream?.id}`)
    );
  };

  useEffect(() => {
    appendStreams(pageData?.data);
  }, [pageData]);

  useEffect(() => {
    appendStreams(pollingData?.data);
  }, [pollingData]);

  const loadMore = debounce(() => {
    if (!hasMore || isLoading) return;
    setPage(page + 1);
  }, 500);

  const handleScroll = (scrollPosition: number, maxScroll: number) => {
    if (scrollPosition >= maxScroll - scrollTrigger) {
      loadMore();
    }
  };

  return {
    handleScroll,
    hasMore,
    isLoading,
    streams,
    skeletonCount,
  };
}
