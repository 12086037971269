import React from 'react';
import { useForm } from 'react-hook-form';
import { getBaseUrl, setBaseUrl } from '../../helpers/baseUrlHelpers';
import useToast from '../Toast/useToast';
import { ToastType } from '../Toast/toastSlice';
import { Container } from '../../components/layout/Container';
import {
  setGuestToken,
  setToken,
} from '../../helpers/getOrSetLocalStorageItem';

interface Inputs {
  baseUrl: string;
}

const DebugPage: React.FC = () => {
  const { register, handleSubmit } = useForm<Inputs>({
    defaultValues: {
      baseUrl: getBaseUrl(),
    },
  });
  const { setToast } = useToast();

  const onSubmit = (data: Inputs) => {
    setBaseUrl(data.baseUrl);

    setToast({
      type: ToastType.success,
      message: 'Logged out and API changed. Refreshing...',
    });

    setTimeout(() => {
      setToken(null);
      setGuestToken(null);
      window.location.reload();
    }, 1000);
  };

  return (
    <Container>
      <h1>Debug</h1>

      <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
        <label
          className="mr-4 text-sm font-medium text-gray-700"
          htmlFor="select"
        >
          API Base URL
        </label>

        <select
          id="select"
          {...register('baseUrl')}
          className="focus:outline-none mt-2 rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        >
          <option value="https://api.commentsold.com/marketplace/v1/">
            Prod
          </option>
          <option value="https://api.commentsold.local/marketplace/v1/">
            Local
          </option>
          <option value="https://api.commentsoldmp.com/marketplace/v1/">
            Marketplace
          </option>
          <option value="https://api.commentsoldshopx.com/marketplace/v1/">
            ShopX
          </option>
          <option value="https://api.commentsoldrt.com/marketplace/v1/">
            RT
          </option>
          <option value="https://api.commentsoldalpha.com/marketplace/v1/">
            Alpha
          </option>
          <option value="https://api.commentsoldindia.com/marketplace/v1/">
            India
          </option>
        </select>

        <button className="focus:outline-none mt-8 flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
          Submit
        </button>
      </form>
    </Container>
  );
};

export { DebugPage };
