import {
  GetOrderHistoryResponse,
  LocalPickupLocation,
  OrderResponse,
  Status,
} from '../../../../features/Api/apiTypes';
import { Order, OrderProduct } from '../OrderHistory/OrderHistory';
import { formatMoney } from '../../../../helpers/stringFormat';
import { v4 } from 'uuid';
import dayjs from 'dayjs';

const convertOrderHistoryApiResponseToOrderHistory = (
  res?: GetOrderHistoryResponse
): Order[] => {
  if (!res) return [];

  return res?.data.map((resOrder) => {
    const order: Order = convertOrder(resOrder);

    return order;
  });
};

export const convertOrder = (resOrder: OrderResponse): Order => {
  return {
    paymentMethod: resOrder.payment_method,
    ccLast4: resOrder.card_last_four,
    ccType: resOrder.card_brand?.toUpperCase(),
    couponDiscountAmount:
      resOrder.coupon_discount != null
        ? formatMoney(resOrder.coupon_discount)
        : null,
    couponId: resOrder.coupon,
    id: resOrder.id,
    orderNumber: `#${resOrder.id}`,
    orderProducts: createOrderProducts(resOrder),
    // Server sends seconds. We need milliseconds.
    purchaseDate: dayjs(resOrder.date * 1000).format('M/D/YYYY'),
    purchaseDateAndTime: dayjs(resOrder.date * 1000).format(
      'h:mma on MMM D, YYYY'
    ),
    purchaseTime: dayjs(resOrder.date * 1000).format('h:mm A'),
    shippingCityStateZip: `${resOrder.city}, ${resOrder.state} ${resOrder.zip}`,
    shippingFee: formatMoney(resOrder.ship_charged),
    status: normalizeStatus(resOrder.status),
    shippingStreetAddress: `${resOrder.street}`,
    shippingStreetAddress2: `${resOrder.apartment}`,
    shopEmail: resOrder.company_email,
    subtotal: formatMoney(resOrder.subtotal),
    taxesAndFees: formatMoney(resOrder.tax_total),
    total: formatMoney(resOrder.total),
    localPickupLocation: buildLocalPickupLocation(resOrder.location),
  };
};

const buildLocalPickupLocation = (
  location: LocalPickupLocation
): {
  city: string;
  state: string;
  zip: string;
  streetAddress: string;
  warehouseName: string | null;
} | null => {
  return (
    location && {
      city: location.location, // I dunno
      state: location.state,
      zip: location.zip_code,
      streetAddress: location.address,
      warehouseName: location.warehouse_name,
    }
  );
};

const createOrderProducts = (order: OrderResponse): OrderProduct[] => {
  return Object.entries(order.products).flatMap<OrderProduct>(
    ([trackingNumber, products]) =>
      products.map((product) => {
        const shippingTrackingNumber =
          trackingNumber === 'not-shipped' ? null : trackingNumber;

        return {
          localIdForFrontend: v4(),
          color: product.color,
          id: product.product_id,
          productImgSrc: product.filename || undefined,
          productName: product.product_name,
          shippingStatus: order.status,
          shippingTrackingNumber,
          shopEmail: order.company_email,
          shopName: order.shop_name,
          size: product.size,
          shippingTrackingUrl: product.tracking_url,
          price: formatMoney(product.price),
        };
      })
  );
};

const normalizeStatus = (status: Status): string => {
  if (status === 'Printed' || status === 'Processing') return 'Processing';

  return status;
};

export default convertOrderHistoryApiResponseToOrderHistory;
