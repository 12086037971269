import React from 'react';
import { FaCopyright } from 'react-icons/fa';

const Footer: React.FC = () => {
  return (
    <footer className="w-full border-t px-6 py-4">
      <div className="flex items-center text-xs">
        <FaCopyright className="mr-1 " />
        Copyright {new Date().getFullYear()} CommentSold
      </div>
    </footer>
  );
};

export { Footer };
