import React from 'react';
import { Providers } from './Providers';
import { Main } from '../Main/Main';
import { SkeletonTheme } from 'react-loading-skeleton';
import { Tracker } from '../Tracker/Tracker';

function App() {
  return (
    <Providers>
      <Tracker>
        <SkeletonTheme baseColor={'#f5f5f5'} highlightColor={'#fcfcfc'}>
          <Main />
        </SkeletonTheme>
      </Tracker>
    </Providers>
  );
}

export default App;
