export function loginFacebook() {
  return new Promise<fb.StatusResponse>((resolve, reject) => {
    FB.login(
      (response) => {
        if (response.authResponse) {
          resolve(response);
        } else {
          reject('Login Error');
        }
      },
      {
        scope: 'email, public_profile',
        return_scopes: true,
        auth_type: 'rerequest',
      }
    );
  });
}

export function logOutIfLoggedInFacebook() {
  return new Promise<fb.StatusResponse>((resolve, reject) => {
    FB.getLoginStatus((response) => {
      if (response.status === 'connected') {
        FB.logout((response) => {
          resolve(response);
        });
      } else {
        resolve(response);
      }
    });
  });
}
