import {
  addGlobalLoadingMessage,
  removeGlobalLoadingMessage,
} from '../features/App/uiSlice';
import { useAppDispatch } from '../store/hooks';
import { useCallback, useState } from 'react';
import { v4 } from 'uuid';

export default function useGlobalLoadingMessage(message = 'Loading...') {
  const [key] = useState(v4());
  const dispatch = useAppDispatch();

  const show = useCallback(() => {
    dispatch(
      addGlobalLoadingMessage({
        key,
        message,
      })
    );
  }, [key, message, dispatch]);

  const hide = useCallback(() => {
    dispatch(removeGlobalLoadingMessage(key));
  }, [dispatch, key]);

  return [show, hide];
}
