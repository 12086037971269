import React from 'react';
import { WaitlistItemButton } from './WaitlistItemButton';
import { Button } from '../../components/atoms/Button';
import { Card } from '../../components/layout/Card';
import { ImageWithFallback } from '../../components/atoms/ImageWithFallback';

export interface WaitlistItemType {
  id: string;
  productTitle: string;
  isInStock: boolean;
  isPreAuthorized?: boolean;
  priceDisplay?: string;
  allowPreAuth?: boolean;
  shopName: string;
  shopId: string;
  variantSelections: { id: string; value: [string, string] }[];
  checkoutUrl?: string;
  preAuthUrl?: string;
  imgUrl?: string;
  isWaitlist?: boolean;
  isCart?: boolean;
  createdAt: number;
}

interface WaitlistItemProps {
  item: WaitlistItemType;
  onRemove?: (item: WaitlistItemType) => void;
  isSmallWidth?: boolean;
  onInitiateCheckout?: (item: WaitlistItemType) => void;
  isSidebar?: boolean;
}

const WaitlistItem: React.FC<WaitlistItemProps> = ({
  item,
  onRemove,
  isSmallWidth,
  isSidebar = false,
  onInitiateCheckout = () => undefined,
}) => {
  const imageDimensions = isSmallWidth ? 96 : 128;
  const handleClick = () => {
    onInitiateCheckout(item);
  };

  return (
    <Card
      borderPosition={'b'}
      className={'p-4'}
      rounded={!isSidebar}
      withBorder={!isSidebar}
      withShadow={false}
    >
      <div className={'flex'}>
        <ImageWithFallback
          alt={item.productTitle}
          className={'rounded border object-contain'}
          src={item.imgUrl}
          style={{ width: imageDimensions, height: imageDimensions }}
        />

        <div className={'ml-4 flex flex-1 justify-between'}>
          <div className={'mr-4'}>
            <h3 className={'font-bold'}>{item.productTitle}</h3>

            <div className={'mt-2 text-sm'}>
              {item.variantSelections.map((v) => (
                <div key={v.id}>
                  <span className={'mr-1 text-textDarkTertiary'}>
                    {v.value[0]}:
                  </span>
                  {v.value[1]}
                </div>
              ))}
            </div>
          </div>

          <div className={'text-right'}>
            <div className={'font-bold'}>{item.priceDisplay}</div>
            <div className={'text-sm'}>{item.shopName}</div>
          </div>
        </div>
      </div>

      <div className={'mt-5 flex justify-between'}>
        <Button
          text
          className={'text-sm text-textDarkSecondary'}
          onClick={() => onRemove?.(item)}
        >
          Remove
        </Button>

        <div>
          <WaitlistItemButton item={item} onClick={handleClick} />
        </div>
      </div>
    </Card>
  );
};

export { WaitlistItem };
