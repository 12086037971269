import React from 'react';
import { Modal } from '../../components/molecules/Modal';
import { useAppSelector } from '../../store/hooks';
import { CartFull } from '../../components/organisms/Cart/CartFull';
import useCart from './useCart';
import useToast from '../Toast/useToast';
import { ToastType } from '../Toast/toastSlice';
import useErrorHandling from '../../hooks/useErrorHandling';
import { selectCurrentUser } from '../Auth/authSlice';
import { useCallbacks } from '../../context/CallbacksContext';

export const CartFullModal: React.FC = () => {
  const user = useAppSelector(selectCurrentUser);
  const { shopName, checkoutUrl, emptyCart, guestCheckout } = useCart();
  const { setToastWithTimeout } = useToast();
  const handleError = useErrorHandling();
  const { emptyCartCallback, setEmptyCartCallback } = useCallbacks();

  const handleEmpty = async () => {
    try {
      await emptyCart();

      setToastWithTimeout({
        type: ToastType.success,
        message: 'Your bag has been emptied',
      });

      // Forgive me, for I have sinned.
      // This timeout is due to a CSS problem when opening the sidebar from this callback
      // and then immediately closing this modal at setEmptyCartCallback(undefined) below.
      // This one-second wait time makes sure that this modal is fully closed, and the
      // transition is completed, before a new transition is started when opening the sidebar.
      // Doing it this way was way easier than trying to hack out the CSS problem. The UX is fine too.
      setTimeout(() => {
        emptyCartCallback?.();
      }, 1000);
    } catch (error) {
      handleError({ error, message: 'There was a problem emptying your bag' });
    } finally {
      setEmptyCartCallback(undefined);
    }
  };

  const handleClose = () => {
    setEmptyCartCallback(undefined);
  };

  return (
    <Modal isShown={!!emptyCartCallback} onClose={handleClose}>
      <CartFull
        checkoutUrl={checkoutUrl}
        isAuthenticated={!!user}
        shopName={shopName}
        onEmpty={handleEmpty}
        onGuestCheckout={guestCheckout}
      />
    </Modal>
  );
};
