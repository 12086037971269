import React, { useState } from 'react';
import { Card } from '../../../layout/Card';
import { OrderHistoryItem } from './OrderHistoryItem';
import { OrderDetails } from './OrderDetails';
import { LoadingIndicator } from '../../../molecules/LoadingIndicator';
import wavyBuddiesOrderCancelled from '../../../../assets/wavy-buddies-order-cancelled.svg';
import { Link } from 'react-router-dom';
import { OrderPaymentMethods } from '../../../../features/Api/apiTypes';

export interface Order {
  id: number;
  orderNumber: string;
  purchaseDate: string;
  purchaseDateAndTime: string;
  purchaseTime: string;
  total: string;
  shopEmail: string;
  orderProducts: OrderProduct[];
  status: string;
  localPickupLocation: {
    city: string;
    state: string;
    zip: string;
    streetAddress: string;
    warehouseName: string | null;
  } | null;
  shippingStreetAddress: string;
  shippingStreetAddress2: string | null;
  shippingCityStateZip: string;
  paymentMethod: OrderPaymentMethods;
  ccLast4: string;
  ccType: string;
  subtotal: string;
  couponId: string | null;
  couponDiscountAmount: string | null;
  taxesAndFees: string;
  shippingFee: string;
}

export interface OrderProduct {
  localIdForFrontend: string; // Needed to be unique
  id: number;
  productName: string;
  shopName: string;
  shopEmail: string;
  price: string;
  size?: string;
  color?: string;
  productImgSrc?: string;
  shippingStatus: string;
  shippingTrackingNumber: string | null;
  shippingTrackingUrl: string | null;
}

interface OrderHistoryProps {
  orders: Order[];
  isLoading?: boolean;
  isError?: boolean;
}

const OrderHistory: React.FC<OrderHistoryProps> = ({
  orders,
  isLoading,
  isError,
}) => {
  const [selectedOrder, setSelectedOrder] = useState<Order | undefined>();

  const getContent = () => {
    if (isError) {
      return <div>There was a problem retreiving your order history</div>;
    }

    if (isLoading) {
      return <LoadingIndicator inline message="Loading order history..." />;
    }

    if (selectedOrder) {
      return (
        <OrderDetails
          order={selectedOrder}
          onClose={() => setSelectedOrder(undefined)}
        />
      );
    }

    return (
      <>
        <h2 className={'mb-7 font-medium'}>Your Order History</h2>

        {orders.length ? (
          orders.map((order) => {
            return (
              <div key={order.id} className={'mb-5'}>
                <OrderHistoryItem
                  order={order}
                  onClick={() => setSelectedOrder(order)}
                />
              </div>
            );
          })
        ) : (
          <div className={'flex flex-col items-center justify-between p-4'}>
            {/* Spacer */}
            <div className={'h-1/5'} />

            <div className={'flex flex-1 flex-col items-center text-center'}>
              <figure>
                <img
                  alt={'Shopping bags flying away'}
                  height={279}
                  src={wavyBuddiesOrderCancelled}
                />
              </figure>

              <p className={'mt-4 font-semibold'}>
                You have not placed any orders!
              </p>

              <p className={'mt-1 text-sm text-textDarkSecondary'}>
                View the{' '}
                <Link className={'underline hover:no-underline'} to={'/'}>
                  Discover
                </Link>{' '}
                page to start shopping now!
              </p>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <Card className={'relative px-4 py-6'} withBorder={true} withShadow={false}>
      {getContent()}
    </Card>
  );
};

export { OrderHistory };
