export function formatMoney(
  amount: number,
  currency = 'USD',
  locale = 'en-US'
): string {
  return new Intl.NumberFormat(locale, { style: 'currency', currency }).format(
    amount
  );
}

export function prefixZero(num: number): string {
  return num < 10 ? `0${num}` : `${num}`;
}

export function formatTime(seconds: number) {
  const s = Math.floor(seconds % 60);
  const m = Math.floor((seconds / 60) % 60);
  const h = Math.floor(seconds / 3600);

  return `${prefixZero(h)}:${prefixZero(m)}:${prefixZero(s)}`;
}
