import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import cn from 'classnames';

interface VariantSwiperProps {
  onOptionClick: (option: string) => void;
  optionsArray: [string, number][];
  selectedOption?: string;
}

const VariantSwiper: React.FC<VariantSwiperProps> = ({
  onOptionClick,
  optionsArray,
  selectedOption,
}) => {
  return (
    <Swiper slidesPerView="auto" spaceBetween={16}>
      {optionsArray.map(([title, quantity]) => {
        return (
          <SwiperSlide
            key={title}
            data-testid={`${title}-slide`}
            style={{ width: 'auto', minWidth: 70 }}
          >
            <button
              className={cn(
                'flex w-full items-center rounded-lg border px-2 py-1 ',
                {
                  'border-indigo-500': title === selectedOption,
                }
              )}
              onClick={() => onOptionClick(title)}
            >
              <div className="text-left">
                <div>{title}</div>
                <div className="text-xs">
                  {quantity > 0 ? `${quantity} left` : 'Waitlist'}
                </div>
              </div>
            </button>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export { VariantSwiper };
