import React from 'react';
import { formatMoney } from '../../../helpers/stringFormat';
import { Button } from '../../atoms/Button';

interface CartSummaryProps {
  numberOfItems: number;
  subtotal: number;
  checkoutUrl: string;
  isAuthenticated: boolean;
  onGuestCheckout?: () => void;
}

const CartSummary: React.FC<CartSummaryProps> = ({
  numberOfItems,
  subtotal,
  checkoutUrl,
  isAuthenticated,
  onGuestCheckout,
}) => {
  return (
    <div>
      <div className="flex items-center justify-between">
        <h2>Summary</h2>
        <span className="rounded-full bg-gray-100 px-3 py-1.5 text-sm text-gray-700">
          {numberOfItems} item{numberOfItems === 1 ? '' : 's'}
        </span>
      </div>

      <div className="mt-6 flex justify-between">
        <div className="text-sm text-gray-500">
          Shipping & taxes calculated at checkout
        </div>
      </div>

      <div className="mt-2 flex justify-between">
        <div className="text-sm">Subtotal</div>
        <div>{formatMoney(subtotal)}</div>
      </div>

      <div className="mt-6 grid">
        {isAuthenticated && (
          <Button asExternalLink primary href={checkoutUrl}>
            Checkout
          </Button>
        )}
        {!isAuthenticated && (
          <Button primary onClick={onGuestCheckout}>
            Checkout
          </Button>
        )}
      </div>
    </div>
  );
};

export { CartSummary };
