import React, { useState } from 'react';
import { SectionHeader } from '../../components/organisms/Cart/SectionHeader';
import { Button } from '../../components/atoms/Button';
import { Icon, IconName } from '../../components/atoms/Icon';
import { Card } from '../../components/layout/Card';
import { WaitlistItem, WaitlistItemType } from './WaitlistItem';
import useDimensions from 'react-cool-dimensions';
import { LoadingIndicator } from '../../components/molecules/LoadingIndicator';
import cn from 'classnames';

interface WaitlistProps {
  items: WaitlistItemType[];
  isLoading?: boolean;
  isCloseable?: boolean;
  isSidebar?: boolean;
  onClose?: () => void;
  onRemove?: (item: WaitlistItemType) => void;
  onInitiateCheckout?: (item: WaitlistItemType) => void;
}

const Waitlist: React.FC<WaitlistProps> = ({
  items,
  isLoading = false,
  isCloseable,
  isSidebar = false,
  onClose,
  onRemove,
  onInitiateCheckout,
}) => {
  const [isMobile, setIsMobile] = useState(false);

  const { observe } = useDimensions({
    onResize: ({ width }) => {
      setIsMobile(width < 768);
    },
  });

  if (!items.length) {
    return <p>You have no waitlisted items</p>;
  }

  return (
    <div
      ref={observe}
      className={'flex h-full w-full flex-col overflow-hidden overflow-y-auto'}
    >
      {isLoading && (
        <LoadingIndicator containerClassName="bg-gray-500 bg-opacity-25" />
      )}
      <Card
        borderPosition={isSidebar ? 'b' : ''}
        className={'mb-4 flex p-4'}
        rounded={!isSidebar}
        withBorder={isMobile || isSidebar}
        withShadow={isMobile && !isSidebar}
      >
        <SectionHeader
          rightElement={
            <div
              className={
                'text-small rounded-xl bg-gray-100 px-2 text-textDarkSecondary'
              }
            >
              {items.length} Item{items.length === 1 ? '' : 's'}
            </div>
          }
          sectionName={'Waitlist'}
        />

        {isCloseable && onClose && (
          <Button data-testid={'cartCloseButton'} onClick={onClose}>
            <Icon name={IconName.Close} />
          </Button>
        )}
      </Card>

      <div
        className={cn('flex flex-1 flex-wrap', {
          'overflow-y-auto': isSidebar,
        })}
      >
        <Card
          className={cn('w-full', {
            'divide-y divide-gray-200': isSidebar,
          })}
          rounded={!isSidebar}
          style={{ maxWidth: 696 }}
          withBorder={false}
          withShadow={isMobile && !isSidebar}
        >
          {items.map((item) => (
            <WaitlistItem
              key={`${item.shopId}/${item.id}`}
              isSidebar={isSidebar}
              isSmallWidth={isMobile}
              item={item}
              onInitiateCheckout={onInitiateCheckout}
              onRemove={onRemove}
            />
          ))}
        </Card>
      </div>
    </div>
  );
};

export { Waitlist };
