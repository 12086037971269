import React from 'react';

const Checkout: React.FC = () => {
  return <div>Not implemented</div>;

  // const dispatch = useAppDispatch();
  // const user = useAppSelector(selectCurrentUser);
  // const paymentMethod = useAppSelector(selectCurrentPaymentMethod);
  // const shippingMethod = useAppSelector(selectCurrentShippingMethod);
  // const handleError = useErrorHandling();
  // const {
  //   cart,
  //   isUninitialized,
  //   isLoading,
  //   removeFromCart,
  //   itemQuantities,
  //   handleChangeQuantity,
  // } = useCart();
  //
  // if (!user) {
  //   return <div className="p-4">No User</div>;
  // }
  //
  // if (isUninitialized || isLoading) {
  //   return (
  //     <div className="p-4">
  //       <LoadingIndicator message="Loading checkout..." inline={true} />
  //     </div>
  //   );
  // }
  //
  // if (!cart?.items?.length) {
  //   return (
  //     <div className="p-4">
  //       <div>Your cart is empty</div>
  //     </div>
  //   );
  // }
  //
  // const handleRemove = (item: CartItemType) => async () => {
  //   try {
  //     await removeFromCart({
  //       shopId: cart.shop,
  //       userId: user.id,
  //       variantId: item.id,
  //     }).unwrap();
  //   } catch (error) {
  //     handleError({ error, message: 'Could not remove item from cart' });
  //   }
  // };
  //
  // const normalizedCartItems = normalizeCartItems(cart.items);
  //
  // return (
  //   <div className="mx-auto max-w-7xl">
  //     <div className="mt-6">
  //       <ShopNameHeader shopName={cart.shop} />
  //     </div>
  //     <hr className="my-5" />
  //     <div className="flex items-center justify-between">
  //       <h1 className="flex items-center text-2xl font-light">
  //         <FaLock size="1.25rem" className="mr-2" />
  //         Checkout
  //       </h1>
  //       <div className="flex items-center text-sm">
  //         <span className="underline">Secure Payments</span>
  //         <FaCheckCircle size="1rem" className="ml-2" />
  //       </div>
  //     </div>
  //     <div className="mt-5 flex flex-wrap items-start gap-6 lg:flex-nowrap">
  //       <div className="w-full lg:w-4/6">
  //         <div className="mb-4 flex items-center justify-between rounded-md border bg-white p-5">
  //           <div>
  //             Order Notes <span className="text-sm">(optional)</span>
  //           </div>
  //           <div>
  //             <button className="rounded-full bg-gray-100 px-3 py-1.5 text-xs hover:bg-gray-50">
  //               Add Note
  //             </button>
  //           </div>
  //         </div>
  //         <div className="mb-5 rounded-md border bg-white p-5">
  //           <div className="mb-4 flex items-center justify-between rounded-md">
  //             <div>Shipping</div>
  //             <div>
  //               <button className="flex items-center rounded-full bg-gray-100 px-3 py-1.5 text-xs hover:bg-gray-50">
  //                 <FaPen size=".75rem" className="mr-1" />
  //                 Change
  //               </button>
  //             </div>
  //           </div>
  //           <ShippingMethods
  //             selected={shippingMethod}
  //             onChange={(event) =>
  //               dispatch(
  //                 setShippingMethod(event.target.value as ShippingMethod)
  //               )
  //             }
  //             isShowingShippingForm={shippingMethod === ShippingMethod.Standard}
  //           />
  //         </div>
  //         <div className="mb-5 rounded-md border bg-white p-5">
  //           <div className="mb-4 flex items-center justify-between">
  //             <div>Payment</div>
  //             <div>
  //               <button className="rounded-full bg-gray-100 px-3 py-1.5 text-xs hover:bg-gray-50">
  //                 Add Card
  //               </button>
  //             </div>
  //           </div>
  //           <PaymentMethods
  //             selected={paymentMethod}
  //             onChange={(event) =>
  //               dispatch(setPaymentMethod(event.target.value as PaymentMethod))
  //             }
  //           />
  //         </div>
  //         <div className="rounded-md border bg-white p-5">
  //           <div>Review Cart</div>
  //           <div>
  //             {normalizedCartItems.map((item) => (
  //               <div className="my-4 rounded-md border" key={item.id}>
  //                 <CartItem
  //                   // key={item.id}
  //                   item={item}
  //                   quantity={itemQuantities[item.id]}
  //                   onChange={handleChangeQuantity(item)}
  //                   onRemove={handleRemove(item)}
  //                 />
  //               </div>
  //             ))}
  //           </div>
  //         </div>
  //       </div>
  //
  //       <div className="w-full rounded-md bg-white px-4 py-5 shadow-md lg:w-2/6">
  //         <CartSummary
  //           numberOfItems={cart.items.length}
  //           total={cart.subtotal}
  //           couponAmount={10}
  //           tax={cart.tax_total}
  //           subtotal={cart.subtotal}
  //           ctaText="Place Order"
  //         />
  //       </div>
  //     </div>
  //   </div>
  // );
};

export { Checkout };
