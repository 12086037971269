import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  ResetPasswordInputs,
  ResetPasswordForm,
} from '../../components/organisms/ResetPasswordForm/ResetPasswordForm';
import { useResetPasswordMutation } from '../Api/apiSlice';

const ResetPasswordPage: React.FC = () => {
  const history = useHistory();
  const [resetPassword] = useResetPasswordMutation();

  const onSubmit = async (data: ResetPasswordInputs) => {
    await resetPassword(data).unwrap();
    history.push('/sign-in');
  };

  const { token } = useParams<{ token: string }>();

  return (
    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <ResetPasswordForm token={token} onSubmit={onSubmit} />
      </div>
    </div>
  );
};

export { ResetPasswordPage };
