import React, { HTMLAttributes, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import cn from 'classnames';

dayjs.extend(duration);

interface CountdownProps extends HTMLAttributes<HTMLSpanElement> {
  seconds: number;
}

const Countdown: React.FC<CountdownProps> = ({ seconds, ...props }) => {
  const [secondsLeft, setSecondsLeft] = useState(seconds);

  useEffect(() => {
    const interval = setInterval(() => {
      setSecondsLeft((prevState) => (prevState > 0 ? prevState - 1 : 0));
    }, 1000);

    return () => clearInterval(interval);
  }, [seconds]);

  const timeLeft = formatTimeLeft(secondsLeft);

  return (
    <span
      {...props}
      className={cn(
        {
          'text-red-500': secondsLeft === 0,
        },
        props.className
      )}
    >
      {timeLeft}
    </span>
  );
};

export { Countdown };

// PRIVATE FUNCTIONS

function formatTimeLeft(secondsLeft: number) {
  return new Date(secondsLeft * 1000).toISOString().substr(11, 8);
}
