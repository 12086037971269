import React from 'react';
import cn from 'classnames';

interface StreamPreviewStatProps {
  className?: string;
  icon?: string | React.ReactElement;
}

export const StreamPreviewStat: React.FC<StreamPreviewStatProps> = ({
  className,
  children,
  icon,
}) => {
  return (
    <div
      className={cn(
        `flex h-6 items-center gap-1 rounded bg-black px-2 py-1 text-xs text-white`,
        className
      )}
    >
      {icon}
      <div>{children}</div>
    </div>
  );
};
