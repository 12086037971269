import React from 'react';
import { makeContext } from './helpers/makeContext';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { User } from '../features/Auth/authTypes';

type CallbacksContextType = {
  emptyCartCallback: (() => unknown) | undefined;
  setEmptyCartCallback: Dispatch<SetStateAction<(() => unknown) | undefined>>;
  signInCallback: ((user: User) => unknown) | undefined;
  setSignInCallback: Dispatch<
    SetStateAction<((user: User) => unknown) | undefined>
  >;
};

const { MadeProvider: CallbacksContextProvider, useMadeContext: useCallbacks } =
  makeContext<CallbacksContextType>();

const CallbacksProvider: FC = ({ children }) => {
  const [emptyCartCallback, setEmptyCartCallback] = useState<
    (() => unknown) | undefined
  >(undefined);
  const [signInCallback, setSignInCallback] = useState<
    ((user: User) => unknown) | undefined
  >(undefined);

  return (
    <CallbacksContextProvider
      value={{
        emptyCartCallback,
        setEmptyCartCallback,
        signInCallback,
        setSignInCallback,
      }}
    >
      {children}
    </CallbacksContextProvider>
  );
};

export { CallbacksProvider, useCallbacks };
