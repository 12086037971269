import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Button } from '../../atoms/Button';
import { InputWithLabel } from '../../molecules/InputWithLabel';
import get from 'lodash/get';

export interface SignUpInputs {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export interface SignUpFormProps {
  onSubmit: (data: SignUpInputs) => void;
  onSkipSignIn: () => void;
  onSignIn: () => void;
  hideSkipSignIn?: boolean;
}

const rules = {
  firstName: {
    required: 'First name is required',
  },
  lastName: {
    required: 'Last name is required',
  },
  email: {
    required: 'Email is required',
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: 'Must be a valid email',
    },
  },
  password: {
    required: 'Password is required',
    minLength: {
      value: 8,
      message: 'Must be at least 8 characters',
    },
    maxLength: {
      value: 255,
      message: 'Must be less than 255 characters',
    },
    validate: {
      oneLower: (value: string) =>
        /[a-z]/.test(value) || 'Must contain at least one lowercase letter',
      oneUpper: (value: string) =>
        /[A-Z]/.test(value) || 'Must contain at least one uppercase letter',
      oneNumber: (value: string) =>
        /[0-9]/.test(value) || 'Must contain at least one number',
    },
  },
};

export const SignUpForm: React.FC<SignUpFormProps> = ({
  onSubmit: componentOnSubmit,
  onSkipSignIn,
  onSignIn,
  hideSkipSignIn,
}) => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    setValue,
  } = useForm<SignUpInputs>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit: SubmitHandler<SignUpInputs> = async (data) => {
    try {
      setIsSubmitting(true);
      await componentOnSubmit(data);
    } catch (error) {
      const errors = get(error, 'data.errors', {});

      for (const key in errors) {
        setError(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          key as any,
          { type: 'manual', message: errors[key] },
          { shouldFocus: true }
        );
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const preventSpaces = (
    event: React.FormEvent<HTMLInputElement> | undefined,
    key: 'firstName'
  ) => {
    const element = event?.currentTarget as HTMLInputElement;
    setValue(key, element.value?.replace(/\s/g, ''));
  };

  return (
    <div className="relative" data-testid="sign-up-form">
      <h1 className="mb-4">Sign Up</h1>

      <form noValidate className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
        <InputWithLabel
          autoComplete="first name"
          error={errors?.firstName?.message}
          id="firstName"
          labelText="First Name"
          type="text"
          {...register('firstName', rules.firstName)}
          onChange={(event) => preventSpaces(event, 'firstName')}
        />

        <InputWithLabel
          autoComplete="lastName"
          error={errors?.lastName?.message}
          id="lastName"
          labelText="Last Name"
          type="text"
          {...register('lastName', rules.lastName)}
        />

        <InputWithLabel
          autoComplete="email"
          error={errors?.email?.message}
          id="email"
          labelText="Email Address"
          type="email"
          {...register('email', rules.email)}
        />

        <InputWithLabel
          autoComplete="current-password"
          error={errors?.password?.message}
          id="password"
          labelText="Password"
          type="password"
          {...register('password', rules.password)}
        />

        <div>
          <Button fullWidth primary isLoading={isSubmitting} type="submit">
            Sign Up
          </Button>
        </div>
      </form>

      <div className="mt-6">
        <div className="mt-12 flex justify-between text-sm ">
          {!hideSkipSignIn && (
            <Button data-testid="skip-login-link" onClick={onSkipSignIn}>
              Skip Sign-In
            </Button>
          )}
          <Button data-testid="sign-up-link" onClick={onSignIn}>
            Already have an account?
          </Button>
        </div>
      </div>
    </div>
  );
};
