import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store/store';

type TrackerState = {
  googleClientId: string | null;
};

const slice = createSlice({
  name: 'tracker',
  initialState: {
    googleClientId: null,
  } as TrackerState,
  reducers: {
    setGoogleClientId: (state, { payload }: PayloadAction<string | null>) => {
      state.googleClientId = payload;
    },
  },
});

export const { setGoogleClientId } = slice.actions;

export default slice.reducer;

export const selectGoogleClientId = (state: RootState) =>
  state.tracker.googleClientId;
