import React, { useEffect } from 'react';
import { Routes } from '../App/Routes';
import { Footer } from '../../components/organisms/Footer/Footer';
import { Toast } from '../../components/molecules/Toast';
import useAuth from '../Auth/useAuth';
import { LoadingIndicator } from '../../components/molecules/LoadingIndicator';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  selectCurrentGlobalLoadingMessages,
  selectIsSidebarCartOpen,
  selectIsSidebarWaitlistOpen,
  setIsSidebarCartOpen,
  setIsSidebarWaitlistOpen,
} from '../App/uiSlice';
import { Sidebar } from '../../components/layout/Sidebar';
import { SignInModal } from '../Auth/SignInModal';
import { CartContainer } from '../Cart/CartContainer';
import { Link, useLocation } from 'react-router-dom';
import { CartFullModal } from '../Cart/CartFullModal';
import { WaitlistContainer } from '../Waitlist/WaitlistContainer';
import { NavContainer } from '../Nav/NavContainer';

const Main: React.FC = () => {
  useAuth(); // Magic

  const globalLoadingMessages = useAppSelector(
    selectCurrentGlobalLoadingMessages
  );
  const isSidebarCartOpen = useAppSelector(selectIsSidebarCartOpen);
  const isSidebarWaitlistOpen = useAppSelector(selectIsSidebarWaitlistOpen);
  const dispatch = useAppDispatch();

  const mostRecentGlobalLoadingMessage =
    globalLoadingMessages[globalLoadingMessages.length - 1];
  const location = useLocation();

  useEffect(() => {
    dispatch(setIsSidebarCartOpen(false));
    dispatch(setIsSidebarWaitlistOpen(false));
  }, [location, dispatch]);

  return (
    <div className={'flex min-h-screen flex-col overflow-hidden'}>
      <NavContainer />

      <main className="relative flex-grow bg-background px-6 py-4">
        <Routes />
      </main>

      <Footer />

      <CartFullModal />

      <SignInModal />

      <Toast />

      {mostRecentGlobalLoadingMessage && (
        <LoadingIndicator
          blockScreen={true}
          message={mostRecentGlobalLoadingMessage.message}
        />
      )}

      <Sidebar
        isOpen={isSidebarCartOpen}
        onClose={() => dispatch(setIsSidebarCartOpen(false))}
      >
        <div className={'flex h-full flex-col items-center'}>
          <CartContainer isCloseable isSidebar />
          <div className="mt-auto w-full pb-4 text-center">
            <Link
              className={'inline-block hover:underline '}
              to={'/cart'}
              onClick={() => dispatch(setIsSidebarCartOpen(false))}
            >
              View Cart Full Page
            </Link>
          </div>
        </div>
      </Sidebar>

      <Sidebar
        isOpen={isSidebarWaitlistOpen}
        onClose={() => dispatch(setIsSidebarWaitlistOpen(false))}
      >
        <div className={'flex h-full flex-col items-center'}>
          <WaitlistContainer isCloseable isSidebar />
          <div className="mt-auto w-full py-4 text-center">
            <Link
              className={'inline-block hover:underline '}
              to={'/waitlist'}
              onClick={() => dispatch(setIsSidebarWaitlistOpen(false))}
            >
              View Full Waitlist Page
            </Link>
          </div>
        </div>
      </Sidebar>
    </div>
  );
};

export { Main };
