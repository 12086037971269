import React, { useMemo, useState } from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Button } from '../../../components/atoms/Button';
import { FlexSpacer } from '../../../components/atoms/FlexSpacer';
import VisaImage from '../../../assets/visa.svg';
import MasterCardImage from '../../../assets/mastercard.svg';
import DiscoverImage from '../../../assets/discover.svg';
import AmexImage from '../../../assets/amex.svg';
import { PaymentMethod } from '../../Api/apiTypes';
import { useParams } from 'react-router-dom';
import { usePaymentMethods } from './usePaymentMethods';
import { PaymentPreview } from './PaymentPreview';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';

type FormData = {
  name: string;
};

interface EditPaymentFormProps {
  onClose: () => void;
}

export const EditPaymentForm: React.FC<EditPaymentFormProps> = ({
  onClose,
}) => {
  const { id: paymentId } = useParams<{ id: string }>();
  const { paymentMethods, addCreditCard } = usePaymentMethods();
  const [isCardValid, setIsCardValid] = useState(false);
  const [cardErrorMessage, setCardErrorMessage] = useState<string | null>(null);

  const selectedPayment = useMemo<PaymentMethod>(
    () =>
      (paymentMethods || []).find(
        (payment) => Number(payment.id) === Number(paymentId)
      ) as PaymentMethod,
    [paymentId, paymentMethods]
  );
  const isEditing = !!selectedPayment;
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>({
    defaultValues: {} as FormData,
  });

  const onSubmit: SubmitHandler<FormData> = async () => {
    if (!selectedPayment) {
      await addCreditCard();
    }

    onClose();
  };

  const handleCardChange = (event: StripeCardElementChangeEvent) => {
    event.error
      ? setCardErrorMessage(event.error.message)
      : setCardErrorMessage(null);
    if (event.complete && !event.error) {
      setIsCardValid(true);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={`my-4 flex items-center justify-between gap-2 px-4`}>
        <h2 className={`text-lg`}>
          {isEditing ? 'Edit' : 'Add'} Payment Method
        </h2>

        <FlexSpacer />

        <Button className={`flex items-center border py-2`} onClick={onClose}>
          Cancel
        </Button>
        <Button
          primary
          className={`flex items-center border py-2`}
          disabled={!isCardValid}
          isLoading={isSubmitting}
          type="submit"
        >
          Save
        </Button>
      </div>
      <div className="border-t px-4 py-6">
        <div className="flex gap-3">
          <h4>Credit Card</h4>

          <FlexSpacer />

          <img alt="Visa" src={VisaImage} />
          <img alt="MasterCard" src={MasterCardImage} />
          <img alt="Discover" src={DiscoverImage} />
          <img alt="American Express" src={AmexImage} />
        </div>

        {isEditing ? (
          <PaymentPreview className="my-4" payment={selectedPayment} />
        ) : (
          <>
            <CardElement
              className={'my-4 h-12 rounded border border-gray-200 px-2'}
              options={{
                style: {
                  base: {
                    lineHeight: '48px',
                    fontSize: '16px',
                    fontFamily: 'Roboto, sans-serif',
                  },
                },
              }}
              onChange={handleCardChange}
            />
            {cardErrorMessage && (
              <div className="text-right text-sm font-bold text-red">
                {cardErrorMessage}
              </div>
            )}
          </>
        )}
      </div>
    </form>
  );
};
