import React from 'react';
import Skeleton from 'react-loading-skeleton';

interface CartItemSkeletonProps {
  children?: React.ReactNode;
}

const CartItemSkeleton: React.FC<CartItemSkeletonProps> = () => {
  return (
    <div className="items-spread flex justify-between">
      <Skeleton className="flex-0 rounded" height={130} width={100} />

      <div className="mx-2 flex-1 overflow-x-hidden py-2">
        <p>
          <Skeleton width={300} />
        </p>
        <div className="mt-2">
          <Skeleton width={100} />
        </div>
        <div className="mt-2 text-sm font-bold">
          <Skeleton width={100} />
        </div>
      </div>
    </div>
  );
};

export { CartItemSkeleton };
