import cn from 'classnames';
import React, { useState } from 'react';
import { Button } from '../../../components/atoms/Button';
import { Modal } from '../../../components/molecules/Modal';

interface RemovePaymentModalProps {
  isShown: boolean;
  onClose: () => void;
  onRemove: () => void;
}

export const RemovePaymentModal: React.FC<RemovePaymentModalProps> = ({
  isShown,
  onClose,
  onRemove,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleRemove = async () => {
    try {
      setIsLoading(true);
      await onRemove();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal isShown={isShown} onClose={onClose}>
      <div className="p-4">
        <p className="text-center">
          Do you really want to remove this payment?
        </p>

        <div className="mt-6 flex justify-end gap-2">
          <Button className="border" onClick={onClose}>
            Cancel
          </Button>
          <Button
            primary
            className={cn({
              'bg-error': !isLoading,
            })}
            isLoading={isLoading}
            onClick={handleRemove}
          >
            Remove
          </Button>
        </div>
      </div>
    </Modal>
  );
};
