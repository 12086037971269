import React, { useCallback } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { FaTrash } from 'react-icons/fa';
import { Card } from '../../../components/layout/Card';
import { EditPaymentForm } from './EditPaymentForm';
import { Button } from '../../../components/atoms/Button';
import styles from '../Account.module.css';
import { PaymentPreview } from './PaymentPreview';
import { Section } from '../../../components/atoms/Section';
import { usePaymentMethods } from './usePaymentMethods';
import { LoadingIndicator } from '../../../components/molecules/LoadingIndicator';
import { RemovePaymentModal } from './RemovePaymentModal';
import { PaymentMethod } from '../../Api/apiTypes';
import useErrorHandling from '../../../hooks/useErrorHandling';
import get from 'lodash/get';
import { OutlinedButton } from '../../../components/atoms/OutlinedButton';

export const PaymentMethodsSection: React.FC = () => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const handleError = useErrorHandling();
  const {
    paymentMethods,
    isFetching,
    makePrimary,
    isMakingPrimary,
    removePayment,
    selectedPayment,
    setSelectedPayment,
  } = usePaymentMethods();

  const handleClose = useCallback(() => {
    history.push(path);
  }, [history, path]);

  const handleRemove = async () => {
    if (!selectedPayment) return;

    try {
      await removePayment(selectedPayment);
    } catch (error) {
      handleError({ error, message: get(error, 'data.message', '') });
    } finally {
      setSelectedPayment(undefined);
    }
  };

  return (
    <Card className={styles.tabPanel} withBorder={true} withShadow={false}>
      <Switch>
        <Route exact path={path}>
          <div className="pb-6">
            <Section>
              <div className={`mb-4 flex items-center justify-between`}>
                <h2 className={`font-medium`}>Payment Methods</h2>

                <Button asInternalLink primary href={`${path}/add`}>
                  Add Payment Method
                </Button>
              </div>

              {isFetching ? (
                <div className="flex justify-center">
                  <LoadingIndicator inline /> Loading Payment Methods...
                </div>
              ) : isMakingPrimary ? (
                <div className="flex justify-center">
                  <LoadingIndicator inline /> Updating Primary Method...
                </div>
              ) : !paymentMethods || paymentMethods.length === 0 ? (
                <div className="flex rounded border p-4">
                  No payment methods found.
                </div>
              ) : (
                paymentMethods?.map((payment) => (
                  <PaymentPreview
                    key={`payment-preview-${payment.id}`}
                    action={
                      <div className="flex items-center justify-center">
                        {payment?.default ? (
                          <>
                            <div className="font-bold">Primary</div>
                          </>
                        ) : (
                          <>
                            <Button
                              className="text-gray-300 hover:text-error"
                              data-testid="remove-payment-button"
                              onClick={() =>
                                setSelectedPayment(payment as PaymentMethod)
                              }
                            >
                              <FaTrash />
                            </Button>
                            <OutlinedButton
                              color="accent"
                              style={{ padding: '0.25rem 0.5rem' }}
                              onClick={() => makePrimary(payment)}
                            >
                              Make Primary
                            </OutlinedButton>
                          </>
                        )}
                      </div>
                    }
                    className="mb-4 items-center"
                    payment={payment}
                  />
                ))
              )}
            </Section>

            <RemovePaymentModal
              isShown={!!selectedPayment}
              onClose={() => setSelectedPayment(undefined)}
              onRemove={handleRemove}
            />
          </div>
        </Route>
        <Route path={[`${path}/add`, `${path}/edit/:id`]}>
          <EditPaymentForm onClose={handleClose} />
        </Route>
      </Switch>
    </Card>
  );
};
