import { useGetOrderQuery, useGuestRegisterMutation } from '../Api/apiSlice';
import { skipToken } from '@reduxjs/toolkit/query';

export default function useOrder(
  shopId?: string | null,
  orderId?: number | null
) {
  const {
    data,
    isUninitialized,
    isFetching: isFetchingOrder,
    isLoading: isLoadingOrder,
  } = useGetOrderQuery(shopId && orderId ? { shopId, orderId } : skipToken);
  const isLoading = isFetchingOrder || isLoadingOrder || isUninitialized;
  const [registerGuest] = useGuestRegisterMutation();

  return {
    data,
    isLoading,
    registerGuest,
  };
}
