import React from 'react';
import { CartItem, CartItemWithQuantity } from './CartItem';

export interface CartProps {
  items: CartItemWithQuantity[];
  onChangeQuantity: (item: CartItemWithQuantity, quantity: number) => void;
  onRemoveItem: (item: CartItemWithQuantity) => void;
  allowRemove: boolean;
  allowUpdateQuantity: boolean;
  showExpiration?: boolean;
}

export const Cart: React.FC<CartProps> = ({
  items,
  onChangeQuantity,
  onRemoveItem,
  allowRemove,
  allowUpdateQuantity,
  showExpiration = true,
}) => {
  const handleChangeQuantity =
    (item: CartItemWithQuantity) => (newQuantity: number) => {
      if (!allowRemove && newQuantity === 0) return;

      onChangeQuantity(item, newQuantity);
    };

  const handleRemoveItem = (item: CartItemWithQuantity) => () => {
    onRemoveItem(item);
  };

  return (
    <>
      <div className="flex-1 overflow-y-auto px-4 py-4">
        <div className={'divide-y divide-gray-300'}>
          {items.map((item) => (
            <div key={item.id} className="py-4">
              <CartItem
                allowUpdateQuantity={allowUpdateQuantity}
                item={item}
                showExpiration={showExpiration}
                showRemoveButton={allowRemove}
                onChangeQuantity={handleChangeQuantity(item)}
                onRemove={handleRemoveItem(item)}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
