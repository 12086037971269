import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useErrorHandling from '../../hooks/useErrorHandling';
import { SignInForm } from '../../components/organisms/SignInForm/SignInForm';
import { selectAuth, setShouldShowSignInModal } from './authSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import useAuth from './useAuth';
import { logOutIfLoggedInFacebook } from '../../helpers/facebookSdk';

type Inputs = {
  email: string;
  password: string;
};

const SignInPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { shouldShowSignInModal } = useAppSelector(selectAuth);
  const history = useHistory();
  const handleError = useErrorHandling();
  const { loginUser, loginWithFacebook } = useAuth();

  useEffect(() => {
    if (!shouldShowSignInModal) return;
    dispatch(setShouldShowSignInModal(false));
  }, [shouldShowSignInModal, dispatch]);

  const handleSubmit = async (data: Inputs) => {
    try {
      await loginUser(data);
      history.push('/');
    } catch (error) {
      if ('status' in error && error.status === 401) {
        handleError({ message: 'Invalid email or password' });
      } else {
        handleError({ error });
      }

      throw error; // to reset form
    }
  };

  const handleLoginWithFacebook = async () => {
    try {
      await loginWithFacebook();
      history.push('/');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      await logOutIfLoggedInFacebook();
      handleError({ error, message: error?.data?.error });
      throw error; // to reset form
    }
  };

  return (
    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <SignInForm
          onFacebookLogin={handleLoginWithFacebook}
          onForgotPassword={() => history.push('/forgot-password')}
          onSignUp={() => history.push('/sign-up')}
          onSkipSignIn={() => history.push('/')}
          onSubmit={handleSubmit}
        />
      </div>
    </div>
  );
};

export { SignInPage };
