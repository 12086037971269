import { UserResponse } from '../../Api/apiTypes';
import { User } from '../authTypes';

export default function convertUserResToUser(userRes: UserResponse): User {
  return {
    id: userRes.id,
    firstName: userRes.first_name,
    lastName: userRes.last_name,
    email: userRes.email,
    streetAddress: userRes.street_address,
    city: userRes.city,
    state: userRes.state,
    zip: userRes.zip,
    apartment: userRes.apartment,
    countryCode: userRes.country_code,
    phoneNumber: userRes.phone_number,
    isGuest: userRes.is_guest,
  };
}
