import React from 'react';
import { ImageWithFallback } from '../../atoms/ImageWithFallback';
import { Countdown } from '../../molecules/Countdown';
import { FaTrash } from 'react-icons/fa';
import { CartItem as CartItemType } from '../../../features/Api/apiTypes';
import { PlusMinusInput } from '../../atoms/PlusMinusInput';
import { Icon, IconName } from '../../atoms/Icon';
import { formatMoney } from '../../../helpers/stringFormat';

export interface CartItemWithQuantity extends CartItemType {
  quantity: number;
}

interface CartItemProps {
  item: CartItemWithQuantity;
  onChangeQuantity: (newQuantity: number) => void;
  showRemoveButton?: boolean;
  onRemove?: () => void;
  allowUpdateQuantity: boolean;
  showExpiration?: boolean;
}

const CartItem: React.FC<CartItemProps> = ({
  item,
  onChangeQuantity,
  showRemoveButton = false,
  onRemove,
  allowUpdateQuantity,
  showExpiration = true,
}) => {
  return (
    <div className="items-spread flex justify-between">
      <ImageWithFallback
        className="flex-0 rounded"
        height={200}
        src={item.thumbnail || undefined}
        style={{ objectFit: 'cover' }}
        width={100}
      />

      <div className="mx-2 flex-1 py-2">
        <p>{item.product_name}</p>
        <div className="mt-2">
          {item.size !== '' && (
            <span className="rounded bg-gray-200 p-1 text-xs">{item.size}</span>
          )}
          {item.color !== '' && (
            <span className="ml-2 rounded bg-gray-200 p-1 text-xs">
              {item.color}
            </span>
          )}
        </div>

        <div className="mt-2 text-sm font-bold">{formatMoney(item.price)}</div>

        {allowUpdateQuantity ? (
          <PlusMinusInput
            quantity={item.quantity}
            onChange={onChangeQuantity}
          />
        ) : item.quantity > 1 ? (
          <div className={'my-2 text-sm'}>Quantity: {item.quantity}</div>
        ) : null}

        {showExpiration && (
          <div className={`mt-2 flex items-center gap-2 text-sm text-gray-500`}>
            <Icon name={IconName.Clock} />
            Expires in:{' '}
            <Countdown
              className={`text-red-500 ml-1`}
              seconds={item.expires_in}
            />
          </div>
        )}
      </div>

      {showRemoveButton && (
        <button
          className="flex-0 flex items-center text-gray-300"
          data-testid="cart-item-remove-button"
          onClick={onRemove}
        >
          <FaTrash />
        </button>
      )}
    </div>
  );
};

export { CartItem };
