import React from 'react';
import { FaStore } from 'react-icons/fa';
import { ImageWithFallback } from '../../atoms/ImageWithFallback';

interface ShopNameHeaderProps {
  sectionName: string;
  imageUrl?: string | null;
  rightElement?: React.ReactNode;
}

const SectionHeader: React.FC<ShopNameHeaderProps> = ({
  sectionName,
  imageUrl,
  rightElement,
}) => {
  return (
    <div className="flex w-full items-center justify-between">
      <h2 className="flex items-center text-lg font-normal text-textDarkSecondary">
        {imageUrl ? (
          <ImageWithFallback
            className="mr-2 inline-block h-8 w-8 rounded-full"
            src={imageUrl}
          />
        ) : (
          <span className="mr-2 inline-block rounded-full bg-gray-100 p-2">
            <FaStore className="text-gray-500" size="1rem" />
          </span>
        )}

        {sectionName}
      </h2>

      {rightElement}
    </div>
  );
};

export { SectionHeader };
