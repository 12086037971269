import React, { useState } from 'react';
import useStreamList from './useStreamList';
import { StreamData } from '../Api/apiTypes';
import { StreamPreview } from '../../components/molecules/StreamPreview';
import { FlexSpacer } from '../../components/atoms/FlexSpacer';
import { HiArrowNarrowLeft, HiArrowNarrowRight } from 'react-icons/hi';
import { IconButton } from '../../components/atoms/IconButton';
import useDimensions from 'react-cool-dimensions';
import { useSwipeable } from 'react-swipeable';
import clamp from 'lodash/clamp';

interface StreamListProps {
  title: string;
  initialStreams: StreamData[];
  initialTotal: number;
  perPage: number;
  lastPage: number;
  type: string;
  selectedShopAndStream?: string;
  className?: string;
}

export const StreamList: React.FC<StreamListProps> = ({
  title,
  initialStreams,
  initialTotal,
  selectedShopAndStream,
  perPage,
  lastPage,
  type,
  className = '',
}) => {
  const [position, setPosition] = useState(0);
  const [offset, setOffset] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const { observe: previewObserve, width: previewWidth } = useDimensions();
  const { observe: containerObserve, width: containerWidth } = useDimensions();
  const { observe: listObserve, width: listWidth } = useDimensions();
  const { observe: skeletonsObserve, width: scrollTrigger } = useDimensions();
  const { handleScroll, hasMore, skeletonCount, streams } = useStreamList({
    initialStreams,
    initialTotal,
    scrollTrigger,
    perPage,
    lastPage,
    type,
  });
  const maxScroll = listWidth - containerWidth;

  const scrollList = (amount: number) => {
    const adjustedAmount = amount - (amount % previewWidth);
    const newPosition = clamp(position + adjustedAmount, 0, maxScroll);

    setPosition(newPosition);
    setOffset(0);
    setIsDragging(false);
    handleScroll(newPosition, maxScroll);
  };

  const scrollLeft = () => scrollList(-containerWidth);
  const scrollRight = () => scrollList(containerWidth);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: scrollRight,
    onSwipedRight: scrollLeft,
    onSwiping: ({ deltaX }) => {
      setOffset(deltaX);
      setIsDragging(true);
    },
    trackMouse: true,
  });

  const handleSelect = (index: number) => {
    const newPosition = clamp(previewWidth * index, 0, maxScroll);
    setPosition(newPosition);
    handleScroll(newPosition, maxScroll);
  };

  return (
    <>
      {streams.length > 0 && (
        <div {...swipeHandlers} className={className}>
          <div className="flex items-center gap-3">
            <h2 className="my-4">{title}</h2>

            <FlexSpacer />

            <IconButton onClick={() => scrollLeft()}>
              <HiArrowNarrowLeft />
            </IconButton>
            <IconButton onClick={() => scrollRight()}>
              <HiArrowNarrowRight />
            </IconButton>
          </div>
          <div
            ref={containerObserve}
            className={`whitespace-nowrap pb-3`}
            data-testid="stream-list"
            id={`stream-list-${type}`}
          >
            <div
              ref={listObserve}
              className="inline-block"
              style={{
                transform: `translateX(-${position - offset}px)`,
                transition: !isDragging ? `transform 300ms ease` : '',
                pointerEvents: !isDragging ? 'auto' : 'none',
              }}
            >
              {streams.map((stream, i) => (
                <StreamPreview
                  key={`${stream.name} - ${stream.id} [${i}]`}
                  className="mr-3"
                  endTime={stream.ended_at}
                  gifSrc={stream.animated_thumb || stream.source_thumb}
                  isLive={stream.is_live} // Disabling until we get a better source for small inline logos
                  isSelected={
                    selectedShopAndStream === `${stream.shop}-${stream.id}`
                  }
                  shopId={stream.shop}
                  shopLogo={stream.app_icon || stream.favicon || ''}
                  shopName={stream.shop_name || stream.shop}
                  startTime={stream.started_at}
                  streamId={stream.id}
                  streamName={stream.name}
                  thumbnailSrc={stream.source_thumb}
                  views={stream.peak_viewers}
                  onClick={() => handleSelect(i)}
                />
              ))}

              {skeletonCount > 0 && (
                <div ref={skeletonsObserve} className="inline-block align-top">
                  {[...Array(skeletonCount)].map((_, i) => (
                    <StreamPreview
                      key={`${type}-skeleton-${i}`}
                      isLoading
                      className={`mr-3`}
                    />
                  ))}
                </div>
              )}

              {!hasMore && (
                <div className="inline-flex h-80 w-72 items-center justify-center rounded-lg bg-brightGray align-top">
                  <p className="italic">End of Streams</p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {/* DUMMY THUMBNAIL TO GET WIDTH */}
      <div
        ref={previewObserve}
        className="invisible absolute inline-block opacity-0"
      >
        <StreamPreview className={`mr-3`} />
      </div>
    </>
  );
};
