import React from 'react';
import { Waitlist } from './Waitlist';
import useWaitlists from '../Cart/useWaitlists';
import useCart from '../Cart/useCart';
import { WaitlistItemType } from './WaitlistItem';
import { ResizeObserver, ResizeObserverEntry } from '@juggle/resize-observer';
import { Button } from '../../components/atoms/Button';
import { Icon, IconName } from '../../components/atoms/Icon';
import { WaitlistEmpty } from './WaitlistEmpty';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setIsSidebarWaitlistOpen } from '../App/uiSlice';
import { selectIsAddingToCart } from '../Cart/cartSlice';
import { WaitlistSkeleton } from './WaitlistSkeleton';

if (!('ResizeObserver' in window)) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).ResizeObserver = ResizeObserver;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).ResizeObserverEntry = ResizeObserverEntry;
}

const WaitlistContainer: React.FC<{
  isCloseable?: boolean;
  isSidebar?: boolean;
}> = ({ isCloseable = false, isSidebar = false }) => {
  const dispatch = useAppDispatch();
  const isAddingToCart = useAppSelector(selectIsAddingToCart);
  const {
    items,
    deleteItem: deleteWaitlistItem,
    waitlistCheckout,
    isLoading,
  } = useWaitlists();
  const { removeItem: deleteCartItem, isRemovingItem } = useCart();
  const isLoadingWaitlist = isAddingToCart || isLoading || isRemovingItem;

  const handleClose = () => dispatch(setIsSidebarWaitlistOpen(false));

  const handleRemove = (item: WaitlistItemType) => {
    if (item.isCart) {
      void deleteCartItem({
        shopId: item.shopId,
        variantId: Number(item.id),
      });

      return;
    }

    deleteWaitlistItem({
      shopId: item.shopId,
      waitlistItemId: item.id,
    });
  };

  const handleInitiateCheckout = async (item: WaitlistItemType) => {
    const { pay_url } = await waitlistCheckout({
      shopId: item.shopId,
      waitlistId: item.id,
    });
    window.location.href = pay_url;
  };

  if (!isLoadingWaitlist && !items.length) {
    return (
      <div className="mb-8">
        {isCloseable && (
          <div className="absolute top-6 right-6">
            <Button data-testid={'cartCloseButton'} onClick={handleClose}>
              <Icon name={IconName.Close} />
            </Button>
          </div>
        )}

        <div className={'mt-16'}>
          <WaitlistEmpty />
        </div>
      </div>
    );
  }

  if (isLoadingWaitlist && !items.length) {
    return <WaitlistSkeleton />;
  }

  return (
    <Waitlist
      isCloseable={isCloseable}
      isLoading={isLoadingWaitlist}
      isSidebar={isSidebar}
      items={items}
      onClose={handleClose}
      onInitiateCheckout={handleInitiateCheckout}
      onRemove={handleRemove}
    />
  );
};

export { WaitlistContainer };
