import React from 'react';
import { Route } from 'react-router-dom';
import { Container } from '../../components/layout/Container';
import { DiscoverPage } from '../DiscoverPage/DiscoverPage';
import { Shop } from './Shop';

const ShopPage: React.FC = () => {
  return (
    <Container>
      <Route exact path={'/shops/:shopId'}>
        <Shop />
      </Route>
      <Route exact path={'/shops/:shopId/streams/:streamId'}>
        <DiscoverPage />
      </Route>
    </Container>
  );
};

export { ShopPage };
