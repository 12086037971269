import { getOrSetLocalStorageItem } from './getOrSetLocalStorageItem';

const DEV_BASE_URL = 'https://api.commentsold.local/marketplace/v1/';
const PROD_BASE_URL = 'https://api.commentsold.com/marketplace/v1/';

export function getBaseUrl(): string {
  if (process.env.REACT_APP_ENVIRONMENT === 'production') {
    return PROD_BASE_URL;
  }

  return (
    getOrSetLocalStorageItem('baseUrl') ||
    process.env.REACT_APP_DEBUG_BASE_URL ||
    DEV_BASE_URL
  );
}

export function setBaseUrl(baseUrl: string) {
  getOrSetLocalStorageItem('baseUrl', baseUrl);
}
