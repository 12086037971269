import React from 'react';
import '@commentsold/live-on-the-web/dist/index.css';
import {
  LotwProvider,
  StreamViewer,
  AddToCartDataStructure,
  LotwConfig,
} from '@commentsold/live-on-the-web';
import { getBaseUrl } from '../../helpers/baseUrlHelpers';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setStreamId, setIsLive } from './streamSlice';
import useCart from '../Cart/useCart';
import { selectCurrentUser, setShouldShowSignInModal } from '../Auth/authSlice';
import useShop from '../Shop/useShop';
import useToast from '../Toast/useToast';
import { ToastType } from '../Toast/toastSlice';
import { useCallbacks } from '../../context/CallbacksContext';
import { User } from '../Auth/authTypes';
import { selectIsAddingToCart } from '../Cart/cartSlice';
import useQuery from '../../hooks/useQuery';
import { FaHeartBroken } from 'react-icons/fa';

interface LOTWConnectorProps {
  onLiveSaleEnded?: () => void;
  streamId?: number;
  shopId?: string;
}

const LOTWConnector: React.FC<LOTWConnectorProps> = ({
  streamId,
  shopId = '',
  onLiveSaleEnded,
}) => {
  const dispatch = useAppDispatch();
  const baseUrl = getBaseUrl();
  const { addItem } = useCart();
  const { data: shop } = useShop(shopId);
  const { setToastWithTimeout } = useToast();
  const user = useAppSelector(selectCurrentUser);
  const isAddingToCart = useAppSelector(selectIsAddingToCart);
  const queryParams = useQuery();
  const replayTimestamp = Number(queryParams.t || 0);
  const hideAvailableStockCount =
    !!shop?.settings?.hideAvailableStockCount || false;
  const { setSignInCallback } = useCallbacks();

  const lotwConfig: LotwConfig = {
    baseUrl,
    shopId,
    soldKeyword: shop?.settings?.soldKeyword || '',
    primaryColors: {
      bg: '#241826', // primary. See tailwind.config.js
      text: '#ffffff', // secondary. See tailwind.config.js
    },
    showSoldCommentsDuringLives: false, // Hardcoded across marketplace, regardless of shop setting
    dataProvider: 'marketplace',
    isReadOnly: false,
    replayTimestamp,
    hideAvailableStockCount,
  };

  const customer = {
    avatarUrl: '',
    email: user?.email || '',
    id: user?.id || Math.floor(Math.random() * 1000000),
    isGuest: !user, // The login link when guest is currently hardcoded to webstore login, so we need to disable that for now by setting isReadOnly
    name: `${user?.firstName} ${user?.lastName}`.trim(),
  };

  function handleConnectToStream({
    streamId,
    isLive,
  }: {
    streamId: number;
    isLive: boolean;
  }) {
    dispatch(setStreamId(streamId));
    dispatch(setIsLive(isLive));
  }

  async function handleAddToCart(item: AddToCartDataStructure) {
    if (item.type === 'noVariant' || !shopId) return;

    const isWaitlist = item.quantityAvailable <= 0;

    if (isWaitlist && (!user || user?.isGuest)) {
      setToastWithTimeout({
        type: ToastType.error,
        message: 'You must be logged in to add items to your waitlist.',
      });

      // This is for UX purposes so that the toast is seen before the
      // SignIn modal pops up
      setTimeout(() => {
        setSignInCallback(
          () => (userArg: User) =>
            addItem({
              shopId,
              variantId: item.id,
              quantity: item.quantityDesired,
              userArg,
            })
        );
      }, 1000);

      return;
    }

    await addItem({
      shopId,
      variantId: item.id,
      quantity: item.quantityDesired,
      isWaitlist,
    });
  }

  function handleLogin() {
    dispatch(setShouldShowSignInModal(true));
  }

  return (
    <LotwProvider
      key={shopId}
      config={lotwConfig}
      customer={customer}
      isAddingToCart={isAddingToCart}
      streamId={streamId}
      onAddToCart={handleAddToCart}
      onConnectToStream={handleConnectToStream}
      onLiveSaleEnded={onLiveSaleEnded}
      onLogin={handleLogin}
    >
      <StreamViewer
        autoConnectToLive={false}
        autoConnectToReplay={false}
        errorChildren={
          <div className="text-center text-error">
            {shopId && streamId && (
              <p>
                <FaHeartBroken className="inline" /> Sorry, but there was a
                problem loading this stream. Please try again a little later.
              </p>
            )}
          </div>
        }
      />
    </LotwProvider>
  );
};

export { LOTWConnector };
