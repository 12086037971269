import React from 'react';

interface TextDividerProps {
  text: string;
}

const TextDivider: React.FC<TextDividerProps> = ({ text }) => {
  return (
    <div className="relative flex items-center py-5">
      <div className="flex-grow border-t border-gray-400"></div>
      <span className="mx-4 flex-shrink text-gray-400">{text}</span>
      <div className="flex-grow border-t border-gray-400"></div>
    </div>
  );
};

export { TextDivider };
