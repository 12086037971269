import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider, ReactReduxContext } from 'react-redux';
import { store, history } from '../../store/store';
import { CallbacksProvider } from '../../context/CallbacksContext';

const Providers: React.FC = ({ children }) => {
  return (
    <React.StrictMode>
      <Provider context={ReactReduxContext} store={store}>
        <ConnectedRouter context={ReactReduxContext} history={history}>
          <CallbacksProvider>{children}</CallbacksProvider>
        </ConnectedRouter>
      </Provider>
    </React.StrictMode>
  );
};

export { Providers };
