import React, { useEffect } from 'react';
import { LoadingIndicator } from '../../components/molecules/LoadingIndicator';
import { useGetProductQuery } from '../Api/apiSlice';
import { ProductDetail } from './ProductDetail';
import close from '../../assets/icons/close-icon.svg';
import leftArrow from '../../assets/left-arrow.svg';
import useGlobalLoadingMessage from '../../hooks/useGlobalLoadingMessage';
import useCart from '../Cart/useCart';

interface ProductDetailContainerProps {
  shopId: string;
  productId: number;
  soldText?: string;
  onBack?: () => void;
  onClose?: () => void;
  hideHeader?: boolean;
}

const ProductDetailContainer: React.FC<ProductDetailContainerProps> = ({
  shopId,
  productId,
  soldText,
  onClose,
  onBack,
  hideHeader,
}) => {
  const { data: product, isLoading } = useGetProductQuery({
    shopId,
    productId: productId.toString(),
  });
  const [show, hide] = useGlobalLoadingMessage('Adding to cart...');
  const { addItem, isAddingItem } = useCart();

  useEffect(() => {
    isAddingItem ? show() : hide();
  }, [isAddingItem, show, hide]);

  const handleAdd = async (variantId: number) => {
    await addItem({ shopId, variantId });
  };

  return (
    <div className="relative flex h-full flex-col">
      {!hideHeader && (
        <div className="flex-0 flex items-center justify-between">
          <button onClick={onBack}>
            <img alt="Back" src={leftArrow} />
          </button>

          <h2 className="text-base font-normal">Product Details</h2>

          <button onClick={onClose}>
            <img alt="Close" src={close} />
          </button>
        </div>
      )}

      <div className="mt-4 flex-1 overflow-auto">
        {isLoading && (
          <LoadingIndicator inline={true} message="Loading product..." />
        )}

        {!isLoading && !product && (
          <div>
            <div className="mt-6">
              An error has occured. Unable to load product details.
            </div>
            <button className="mt-4 underline" onClick={onClose}>
              Go back
            </button>
          </div>
        )}

        {!isLoading && product && (
          <ProductDetail
            product={product}
            soldText={soldText}
            onAdd={handleAdd}
          />
        )}
      </div>
    </div>
  );
};

export { ProductDetailContainer };
