import React from 'react';
import { Dialog, Transition } from '@headlessui/react';
import cn from 'classnames';
import { Card } from '../layout/Card';
import { Icon, IconName } from '../atoms/Icon';
import { Button } from '../atoms/Button';

export interface ModalProps {
  className?: string;
  isShown?: boolean;
  onClose?: () => void;
}

export const Modal: React.FC<ModalProps> = ({
  children,
  className,
  isShown = false,
  onClose = () => null,
}) => {
  return (
    <Transition afterLeave={onClose} show={isShown}>
      <Dialog
        as="div"
        className="fixed inset-0 z-30 overflow-hidden"
        onClose={() => null}
      >
        <Transition.Child
          enter="ease-in-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className="absolute inset-0 bg-gray-500 bg-opacity-75"
            onClick={onClose}
          ></div>

          <Card
            withBorder
            className={cn(
              className,
              'absolute left-1/2 top-1/2 w-screen max-w-sm -translate-x-1/2 -translate-y-1/2 transform sm:w-screen'
            )}
          >
            <div className="absolute z-50 flex w-full justify-end py-2">
              <Button onClick={onClose}>
                <Icon name={IconName.Close} />
              </Button>
            </div>
            {children}
          </Card>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
};
