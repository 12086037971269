import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectCurrentUser } from '../Auth/authSlice';
import { CartItem as CartItemType } from '../Api/apiTypes';
import { LoadingIndicator } from '../../components/molecules/LoadingIndicator';
import { CartSummary } from '../../components/organisms/Cart/CartSummary';
import useCart from './useCart';
import { Card } from '../../components/layout/Card';
import { SectionHeader } from '../../components/organisms/Cart/SectionHeader';
import { Cart } from '../../components/organisms/Cart/Cart';
import cn from 'classnames';
import useDimensions from 'react-cool-dimensions';
import { Icon, IconName } from '../../components/atoms/Icon';
import { Button } from '../../components/atoms/Button';
import { CartEmpty } from '../../components/organisms/Cart/CartEmpty';
import { setIsSidebarCartOpen } from '../App/uiSlice';
import { getGuestToken } from '../../helpers/getOrSetLocalStorageItem';
import Skeleton from 'react-loading-skeleton';
import { CartItemSkeleton } from '../../components/organisms/Cart/CartItemSkeleton';
import { selectIsAddingToCart } from './cartSlice';

export interface CartContainerProps {
  isCloseable?: boolean;
  isSidebar?: boolean;
}

export const CartContainer: React.FC<CartContainerProps> = ({
  isCloseable = false,
  isSidebar = false,
}) => {
  const [isMobile, setIsMobile] = useState(false);
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectCurrentUser);
  const isAddingToCart = useAppSelector(selectIsAddingToCart);
  const guestToken = getGuestToken();

  const {
    isLoading,
    isFetching,
    isAddingItem,
    isRemovingItem,
    removeItem,
    changeQuantity,
    cartQuantity,
    checkoutUrl,
    itemsWithQuantities,
    shopId,
    shopName,
    shopLogo,
    subtotal,
    allowUpdateQuantity,
    allowRemove,
    guestCheckout,
    isLoadingGuestCheckout,
  } = useCart();

  const { observe } = useDimensions({
    onResize: ({ width }) => {
      setIsMobile(width < 768);
    },
  });

  const isWaitingOnCart =
    isLoading ||
    isRemovingItem ||
    isAddingToCart ||
    isAddingItem ||
    isFetching ||
    isLoadingGuestCheckout;
  const isLoadingNewCart = isWaitingOnCart && itemsWithQuantities.length < 1;

  const handleClose = () => dispatch(setIsSidebarCartOpen(false));

  if (!isWaitingOnCart && !itemsWithQuantities.length) {
    return (
      <div className="mb-8">
        {isCloseable && (
          <div className="absolute top-6 right-6">
            <Button data-testid={'cartCloseButton'} onClick={handleClose}>
              <Icon name={IconName.Close} />
            </Button>
          </div>
        )}

        <div className={'mt-16'}>
          <CartEmpty />
        </div>
      </div>
    );
  }

  const handleRemoveItem = (item: CartItemType) => {
    if (user) {
      void removeItem({ shopId, userId: user.id, variantId: item.id });
      return;
    }
    if (guestToken) {
      void removeItem({ shopId, guestToken, variantId: item.id });
      return;
    }
  };

  return (
    <div
      ref={observe}
      className={
        'mx-auto flex h-full w-full flex-col overflow-hidden overflow-y-auto'
      }
      data-testid={'cartContainer'}
    >
      {isWaitingOnCart && (
        <LoadingIndicator containerClassName="bg-gray-500 bg-opacity-25" />
      )}

      {isLoadingNewCart && (
        <div className="mt-2 px-4">
          <Skeleton className="p-4" />
        </div>
      )}

      {!isLoadingNewCart && (
        <Card
          borderPosition={isSidebar ? 'b' : ''}
          className={'flex p-4'}
          rounded={!isSidebar}
          withBorder={isSidebar || isMobile}
          withShadow={!isSidebar}
        >
          {!isLoadingNewCart && (
            <SectionHeader imageUrl={shopLogo} sectionName={shopName} />
          )}

          {isCloseable && !isLoadingNewCart && (
            <Button data-testid={'cartCloseButton'} onClick={handleClose}>
              <Icon name={IconName.Close} />
            </Button>
          )}
        </Card>
      )}

      <div
        className={cn('flex flex-1 flex-wrap items-start', {
          'mb-6 flex-nowrap gap-6': !isMobile,
          'mt-6': !isSidebar,
          'overflow-y-auto': isSidebar,
        })}
      >
        <div className={cn('flex-1', isMobile ? 'w-full' : 'w-4/6')}>
          {!isLoadingNewCart && (
            <Card withBorder={!isSidebar && isMobile} withShadow={!isSidebar}>
              <Cart
                allowRemove={allowRemove}
                allowUpdateQuantity={allowUpdateQuantity}
                items={itemsWithQuantities}
                showExpiration={!!user && !user.isGuest}
                onChangeQuantity={changeQuantity}
                onRemoveItem={handleRemoveItem}
              />
            </Card>
          )}
          {isLoadingNewCart && (
            <div className="mt-4 px-4">
              <CartItemSkeleton />
            </div>
          )}
        </div>
        {!isSidebar && (
          <div className={cn(isMobile ? 'mt-6 w-full' : 'w-2/6')}>
            <Card
              borderPosition={isSidebar ? 't' : ''}
              rounded={!isSidebar}
              withBorder={isSidebar || isMobile}
            >
              <div className="p-4">
                <CartSummary
                  checkoutUrl={checkoutUrl}
                  isAuthenticated={!!user}
                  numberOfItems={cartQuantity}
                  subtotal={subtotal}
                  onGuestCheckout={guestCheckout}
                />
              </div>
            </Card>
          </div>
        )}
      </div>
      {isSidebar && (
        <div className="w-full">
          <Card
            borderPosition={isSidebar ? 't' : ''}
            rounded={!isSidebar}
            withBorder={isSidebar || isMobile}
          >
            <div className="p-4">
              <CartSummary
                checkoutUrl={checkoutUrl}
                isAuthenticated={!!user}
                numberOfItems={cartQuantity}
                subtotal={subtotal}
                onGuestCheckout={guestCheckout}
              />
            </div>
          </Card>
        </div>
      )}
    </div>
  );
};
