import {
  Action,
  configureStore,
  createSelector,
  ThunkAction,
} from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { marketplaceApi, rtkQueryMiddleware } from '../features/Api/apiSlice';
import { rootReducer } from './rootReducer';

export const history = createBrowserHistory();

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      routerMiddleware(history),
      marketplaceApi.middleware,
      rtkQueryMiddleware
    ),
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
// noinspection JSUnusedGlobalSymbols
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const selectRouter = (state: RootState) => state.router;
export const selectQueryParams = createSelector(
  selectRouter,
  (router) => router.location.query
);
