import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { ProductDetailContainer } from './ProductDetailContainer';
import { useAppSelector } from '../../store/hooks';
import { selectCurrentUser } from '../Auth/authSlice';
import { Container } from '../../components/layout/Container';

const ProductPage: React.FC = () => {
  const { productId, shopId } =
    useParams<{ productId: string; shopId: string }>();
  const productIdNum = parseInt(productId);
  const user = useAppSelector(selectCurrentUser);
  const userId = user?.id;

  if (!userId) {
    return (
      <div>
        You must{' '}
        <Link className={`underline`} to="/sign-in">
          sign in{' '}
        </Link>{' '}
        in to view products
      </div>
    );
  }

  return (
    <Container>
      <pre>Shop ID: {shopId}</pre>
      <pre className="mb-8">Product ID: {productId}</pre>

      {!isNaN(productIdNum) ? (
        <ProductDetailContainer
          hideHeader={true}
          productId={productIdNum}
          shopId={shopId}
        />
      ) : (
        <div>Bad product ID</div>
      )}
    </Container>
  );
};

export { ProductPage };
