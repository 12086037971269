import React from 'react';
import { Container } from '../../components/layout/Container';
import { WaitlistContainer } from './WaitlistContainer';

const WaitlistPage: React.FC = () => {
  return (
    <Container>
      <WaitlistContainer />
    </Container>
  );
};

export { WaitlistPage };
