import { useAppSelector } from '../../store/hooks';
import {
  useDeleteWaitlistItemMutation,
  useGetWaitlistedCartsQuery,
  useGetWaitlistsQuery,
  useWaitlistCheckoutMutation,
} from '../Api/apiSlice';
import { selectCurrentUser } from '../Auth/authSlice';
import { skipToken } from '@reduxjs/toolkit/query';
import { useMemo } from 'react';
import { WaitlistItemType } from '../Waitlist/WaitlistItem';
import {
  GetWaitlistedCartsResponse,
  GetWaitlistsResponse,
} from '../Api/apiTypes';
import { v1 } from 'uuid';

export default function useWaitlists() {
  const user = useAppSelector(selectCurrentUser);

  const {
    data: waitlistsResponse,
    isLoading: areWaitlistsLoading,
    isFetching: areWaitlistsFetching,
  } = useGetWaitlistsQuery(
    // TODO: I may be melting some servers with setting this to 100.
    //   I don't want to build pagination right now.
    user?.id ? { userId: user.id, perPage: 100 } : skipToken
  );

  const {
    data: waitlistedCartsResponse,
    isLoading: areWaitlistedCartsLoading,
  } = useGetWaitlistedCartsQuery(
    // TODO: I may be melting some servers with setting this to 100.
    //   I don't want to build pagination right now.
    user?.id ? { perPage: 100 } : skipToken
  );

  const [deleteWaitlistItemMutation, { isLoading: isDeletingWaitlistItem }] =
    useDeleteWaitlistItemMutation();

  const [waitlistCheckoutMutation, { isLoading: isCheckingOutWaitlist }] =
    useWaitlistCheckoutMutation();

  const isLoading =
    areWaitlistsLoading ||
    areWaitlistsFetching ||
    isDeletingWaitlistItem ||
    areWaitlistedCartsLoading ||
    isCheckingOutWaitlist;
  const items = useMemo(() => {
    return convertWaitlistsResponseToWaitlists(
      waitlistsResponse,
      waitlistedCartsResponse
    );
  }, [waitlistsResponse, waitlistedCartsResponse]);

  const deleteItem = (params: { shopId: string; waitlistItemId: string }) => {
    deleteWaitlistItemMutation(params);
  };

  const waitlistCheckout = async (params: {
    shopId: string;
    waitlistId: string;
  }) => waitlistCheckoutMutation(params).unwrap();

  return {
    items,
    deleteItem,
    waitlistCheckout,
    areWaitlistsLoading,
    isLoading,
  };
}

// PRIVATE

function convertWaitlistsResponseToWaitlists(
  waitlistsResponse?: GetWaitlistsResponse,
  waitlistedCartsResponse?: GetWaitlistedCartsResponse
): WaitlistItemType[] {
  const origin = window.location.origin;

  if (!waitlistsResponse || !waitlistedCartsResponse)
    return [] as WaitlistItemType[];

  return [
    ...waitlistsResponse?.data.map((w) => {
      const waitlistItem: WaitlistItemType = {
        allowPreAuth: w.pre_authorization_enabled,
        checkoutUrl: w.pay_url,
        id: w.id.toString(),
        imgUrl: w.thumbnail || undefined,
        isInStock: w.variant.quantity > 0,
        isPreAuthorized: w.preauthorized,
        preAuthUrl: `${w.pre_auth_url}&successUrl=${origin}`,
        priceDisplay: w.price_label,
        productTitle: w.product_name,
        shopId: w.shop,
        shopName: w.shop_name,
        variantSelections: buildVariantSelections(
          w.variant.color,
          w.variant.size
        ),
        isWaitlist: true,
        isCart: false,
        createdAt: w.created_at,
      };

      return waitlistItem;
    }),
    ...waitlistedCartsResponse?.data.map((w) => {
      const waitlistItem: WaitlistItemType = {
        checkoutUrl: w.pay_url,
        id: w.id.toString(),
        imgUrl: w.thumbnail || undefined,
        isInStock: w.variant.quantity > 0,
        priceDisplay: '$' + String(w.variant.price),
        productTitle: w.product_name,
        shopId: w.shop,
        shopName: w.shop_name,
        variantSelections: buildVariantSelections(
          w.variant.color,
          w.variant.size
        ),
        isWaitlist: false,
        isCart: true,
        createdAt: w.created_at,
      };

      return waitlistItem;
    }),
  ].sort(function (a, b) {
    return b.createdAt - a.createdAt;
  });
}

function buildVariantSelections(color: string | null, size: string | null) {
  const variantSelection = [];

  if (color) {
    variantSelection.push({
      id: v1(),
      value: ['Color', color] as [string, string],
    });
  }

  if (size) {
    variantSelection.push({
      id: v1(),
      value: ['Size', size] as [string, string],
    });
  }

  return variantSelection;
}
