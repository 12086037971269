import { ToastType } from '../features/Toast/toastSlice';
import useToast from '../features/Toast/useToast';
import { useCallback } from 'react';
import { useAppDispatch } from '../store/hooks';
import { setShouldShowSignInModal } from '../features/Auth/authSlice';
import { User } from '../features/Auth/authTypes';
import AuthError from '../types/AuthError';

export interface ErrorParams {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;
  message?: string;
  promptSignIn?: boolean;
  postSignInAction?: (user?: User) => void;
}

export default function useErrorHandling() {
  const { setToastWithTimeout } = useToast();
  const dispatch = useAppDispatch();

  return useCallback(
    ({
      error,
      message = 'There was a problem',
      promptSignIn = true,
    }: ErrorParams) => {
      if (
        promptSignIn &&
        (error instanceof AuthError || error?.status === 401)
      ) {
        dispatch(setShouldShowSignInModal(true));
        return;
      }

      if (error) {
        console.error(error);
      }

      setToastWithTimeout(
        {
          type: ToastType.error,
          message: 'Error',
          details: message,
        },
        10000
      );
    },
    [setToastWithTimeout, dispatch]
  );
}
