import React from 'react';
import { Card } from '../../components/layout/Card';
import { NavigationListTab } from '../../components/atoms/NavigationListTab';
import { FaBookmark, FaCreditCard, FaRegUserCircle } from 'react-icons/fa';
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { Button } from '../../components/atoms/Button';
import { useHistory } from 'react-router-dom';
import { PaymentMethodsSection } from './PaymentMethods/PaymentMethodsSection';
import { ProfileSection } from './Profile/ProfileSection';
import { OrderHistoryContainer } from '../../components/organisms/OrderHistory/OrderHistory/OrderHistoryContainer';
import { StripeProvider } from '../App/Stripe';
import useAuth from '../Auth/useAuth';
import { LoadingIndicator } from '../../components/molecules/LoadingIndicator';
import { Container } from '../../components/layout/Container';

const AccountPage: React.FC = () => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const { user, isLoading, isError } = useAuth();

  const tabActive = (tab: string) => {
    return location.pathname === `${path}/${tab}`;
  };

  if (isLoading) return <LoadingIndicator />;

  if (isError || !user || user?.isGuest) {
    return <Redirect to={'/sign-in'} />;
  }

  return (
    <StripeProvider>
      <Container className="grid grid-cols-1 gap-y-4 md:grid-cols-3 md:gap-x-4">
        <div className="col-span-1">
          <Card withBorder={true} withShadow={false}>
            <div aria-label="Profile Menu" className="divide-y" role="tablist">
              <NavigationListTab
                active={tabActive('profile')}
                aria-controls="profile-tab"
                aria-selected={tabActive('profile')}
                icon={<FaRegUserCircle size={'1.5rem'} />}
                id="profile-button"
                title="Profile"
                onClick={() => history.push(`${path}/profile`)}
              />
              <NavigationListTab
                active={tabActive('payment-methods')}
                aria-controls="payment-methods-tab"
                aria-selected={tabActive('payment-methods')}
                icon={<FaCreditCard size={'1.5rem'} />}
                id="payment-methods-button"
                title="Payment Methods"
                onClick={() => history.push(`${path}/payment-methods`)}
              />
              <NavigationListTab
                active={tabActive('orders')}
                aria-controls="order-history-tab"
                aria-selected={tabActive('orders')}
                icon={<FaBookmark size={'1.5rem'} />}
                id="order-history-button"
                title="Order History"
                onClick={() => history.push(`${path}/orders`)}
              />
            </div>
          </Card>
          <Button
            fullWidth
            tertiary
            className="mt-3"
            rounded="full"
            onClick={() => {
              history.push('/sign-out');
            }}
          >
            Sign Out
          </Button>
        </div>
        <div className="col-span-2">
          <Switch>
            <Route exact path={path}>
              <Redirect to={`${path}/profile`} />
            </Route>

            <Route path={`${path}/profile`}>
              <div
                aria-labelledby="profile-button"
                id="profile-tab"
                role="tabpanel"
              >
                <ProfileSection />
              </div>
            </Route>

            <Route path={`${path}/payment-methods`}>
              <div
                aria-labelledby="payment-methods-button"
                id="payment-methods-tab"
                role="tabpanel"
              >
                <PaymentMethodsSection />
              </div>
            </Route>

            <Route path={`${path}/orders`}>
              <div
                aria-labelledby="order-history-button"
                id="order-history-tab"
                role="tabpanel"
              >
                <OrderHistoryContainer />
              </div>
            </Route>
          </Switch>
        </div>
      </Container>
    </StripeProvider>
  );
};

export { AccountPage };
