import React, { FormEvent, useEffect, useState } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';

interface PlusMinusInputProps {
  quantity: number;
  onChange: (newQuantity: number) => void;
}

// Set to 1 initially, because we don't have quanity setup yet
const PlusMinusInput: React.FC<PlusMinusInputProps> = ({
  quantity = 1,
  onChange,
}) => {
  const [localQuantity, setLocalQuantity] = useState(quantity);

  useEffect(() => {
    setLocalQuantity(quantity);
  }, [quantity]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    onChange(localQuantity);
  };

  return (
    <div className="mt-2 inline-flex rounded border border-gray-200">
      <button
        className="flex items-center border-r border-gray-200 px-4 text-2xl"
        data-testid="minus-button"
        onClick={() => onChange(quantity - 1)}
      >
        <FaMinus size="0.5rem" />
      </button>

      <form onSubmit={handleSubmit}>
        <input
          className="border-r border-gray-200 py-1 text-center"
          data-testid={'plusMinusInputInput'}
          readOnly={true}
          style={{ width: 50 }}
          value={localQuantity}
          onChange={(e) => setLocalQuantity(parseInt(e.target.value))} // Right now, useCart can only add or subtract one at a time
        />
      </form>

      <button
        className="flex items-center border-r border-gray-200 px-4 text-2xl"
        data-testid="plus-button"
        onClick={() => onChange(quantity + 1)}
      >
        <FaPlus size="0.5rem" />
      </button>
    </div>
  );
};

export { PlusMinusInput };
