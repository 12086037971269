import React from 'react';
import { OrderHistory } from './OrderHistory';
import { useGetOrderHistoryQuery } from '../../../../features/Api/apiSlice';
import { skipToken } from '@reduxjs/toolkit/query';
import { useAppSelector } from '../../../../store/hooks';
import { selectCurrentUser } from '../../../../features/Auth/authSlice';
import convertOrderHistoryApiResponseToOrderHistory from '../helpers/convertOrderHistoryApiResponseToOrderHistory';

const OrderHistoryContainer: React.FC = () => {
  const user = useAppSelector(selectCurrentUser);

  const {
    data: ordersResponse,
    isLoading,
    isError,
  } = useGetOrderHistoryQuery(
    // TODO: Once again, I may be melting some servers with setting this to 100.
    //   I don't want to build pagination right now.
    user?.id ? { userId: user.id, perPage: 100 } : skipToken
  );

  const orders = convertOrderHistoryApiResponseToOrderHistory(ordersResponse);

  return (
    <OrderHistory isError={isError} isLoading={isLoading} orders={orders} />
  );
};

export { OrderHistoryContainer };
