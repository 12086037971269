import React from 'react';
import cn from 'classnames';
import styles from './LoadingIndicator.module.css';

interface LoadingIndicatorProps {
  message?: string;
  blockScreen?: boolean;
  inline?: boolean;
  spinnerClassName?: string;
  containerClassName?: string;
}

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({
  message,
  blockScreen = false,
  inline = false,
  spinnerClassName = '',
  containerClassName = '',
}) => {
  return inline ? (
    <span className={`flex items-center`} data-testid="loading-indicator">
      <span className={cn(spinnerClassName, styles.spinner)} />
      <span className="ml-2 text-gray-600">{message}</span>
    </span>
  ) : (
    <div
      className={cn(
        styles.wrapper,
        {
          [styles.blockScreen]: blockScreen,
        },
        containerClassName
      )}
      data-testid="loading-indicator"
    >
      <div className={cn(spinnerClassName, styles.spinner)} />
      <span className="mt-2">{message}</span>
    </div>
  );
};

export { LoadingIndicator };
