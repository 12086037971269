import React, { ButtonHTMLAttributes, forwardRef } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { LoadingIndicator } from '../molecules/LoadingIndicator';

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  primary?: boolean;
  secondary?: boolean;
  tertiary?: boolean;
  outlined?: boolean;
  facebook?: boolean;
  text?: boolean;
  fullWidth?: boolean;
  rounded?: 'default' | 'full' | null;
  isLoading?: boolean;
  disabled?: boolean;
  asExternalLink?: boolean;
  asInternalLink?: boolean;
  href?: string;
  targetBlank?: boolean;
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className = '',
      fullWidth = false,
      primary = false,
      secondary = false,
      tertiary = false,
      outlined = false,
      facebook = false,
      text = false,
      rounded = 'default',
      asExternalLink = false,
      asInternalLink = false,
      isLoading = false,
      disabled = isLoading,
      href = '',
      targetBlank = false,
      ...props
    },
    ref
  ) => {
    const classes = cn(
      className,
      'w-fit flex items-center justify-center px-4 py-2 outline-none',
      {
        'bg-primary text-white': primary && !disabled,
        'text-neutral-700 bg-secondary': secondary && !disabled,
        'text-neutral-700 bg-tertiary': tertiary && !disabled,
        'bg-gray-100 text-gray-400': disabled,
        'bg-facebook text-white': facebook,
        'font-bold': !primary && !secondary && !facebook,
        'w-full': fullWidth,
        rounded: rounded === 'default',
        'rounded-full': rounded === 'full',
        'border border-black font-medium text-textDarkSecondary': outlined,
        'opacity-50': outlined && disabled,
      }
    );

    if (asExternalLink) {
      return (
        // You can trust me, eslint
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line react/jsx-no-target-blank
        <a
          {...props}
          className={classes}
          href={href}
          rel={targetBlank ? 'noreferrer' : undefined}
          target={targetBlank ? '_blank' : undefined}
        >
          {isLoading && <LoadingIndicator inline />}
          {children}
        </a>
      );
    }

    if (asInternalLink) {
      return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Link
          {...props}
          className={classes}
          rel={targetBlank ? 'noreferrer' : undefined}
          target={targetBlank ? '_blank' : undefined}
          to={href}
        >
          {isLoading && <LoadingIndicator inline />}
          {children}
        </Link>
      );
    }

    return (
      <button
        {...props}
        ref={ref}
        className={classes}
        disabled={disabled}
        type={props.type || 'button'}
      >
        {isLoading && <LoadingIndicator inline />}
        {children}
      </button>
    );
  }
);

Button.displayName = 'Button';
