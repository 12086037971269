import {
  BaseQueryFn,
  createApi,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import {
  AddPaymentMethodsParams,
  AddPaymentMethodsResponse,
  AddToCartParams,
  AddToCartResponse,
  CheckoutCartParams,
  CheckoutCartResponse,
  CreateAddressParams,
  CreateAddressResponse,
  ForgotPasswordParams,
  GetCartParams,
  GetCartResponse,
  GetPaymentMethodsParams,
  GetPaymentMethodsResponse,
  LoginParams,
  LoginResponse,
  ProductFromApi,
  ProductFromApiParams,
  RegisterParams,
  UserResponse,
  RemoveFromCartParams,
  RemoveFromCartResponse,
  ResetPasswordParams,
  SetDefaultPaymentMethodParams,
  SetDefaultPaymentMethodResponse,
  StreamFromApi,
  StreamFromApiParams,
  StreamsFromApiParams,
  LiveStreamFromApiParams,
  StreamsFromApi,
  StreamFeed,
  UpdateAddressParams,
  UpdateAddressResponse,
  UserParams,
  GetShopResponse,
  GetShopParams,
  GetWaitlistsParams,
  GetWaitlistsResponse,
  GetGuestTokenResponse,
  GuestCheckoutResponse,
  GuestCheckoutParams,
  GetOrderHistoryResponse,
  GetOrderHistoryParams,
  GetWaitlistedCartsParams,
  WaitlistCheckoutParams,
  WaitlistCheckoutResponse,
  OrderResponse,
  GetOrderParams,
  GuestRegisterParams,
  RemovePaymentResponse,
  RemovePaymentParams,
  FacebookLoginParams,
  FollowShopParams,
  UnfollowShopParams,
  GetFollowedShopsResponse,
} from './apiTypes';
import { getBaseUrl } from '../../helpers/baseUrlHelpers';
import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit';
import {
  getToken,
  setGuestToken,
  setToken,
} from '../../helpers/getOrSetLocalStorageItem';
import { RootState } from '../../store/rootReducer';

const BASE_URL = getBaseUrl();

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers) => {
    setHeaders(headers);

    return headers;
  },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const baseQueryWrapper: BaseQueryFn<any, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  const state = api.getState() as RootState;
  if (['POST', 'PUT', 'PATCH', 'DELETE'].includes(args?.method)) {
    args.body = withTracking(args.body, state.tracker.googleClientId);
  }
  return baseQuery(args, api, extraOptions);
};

export const marketplaceApi = createApi({
  reducerPath: 'marketplaceApi',
  baseQuery: baseQueryWrapper,
  tagTypes: [
    'Cart',
    'User',
    'PaymentMethods',
    'Shop',
    'Waitlists',
    'OrderHistory',
    'WaitlistedCarts',
    'FollowedShops',
  ],
  endpoints: (builder) => ({
    register: builder.mutation<UserResponse, RegisterParams>({
      query: ({ firstName, lastName, email, password, token }) => ({
        url: 'register',
        method: 'POST',
        body: {
          firstName,
          lastName,
          email,
          password,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      invalidatesTags: ['User'],
    }),
    login: builder.mutation<LoginResponse, LoginParams>({
      query: ({ email, password, token, migrateShopCart }) => ({
        url: 'login',
        method: 'POST',
        body: {
          email,
          password,
          migrateShopCart,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      invalidatesTags: ['User'],
    }),
    facebookLogin: builder.mutation<LoginResponse, FacebookLoginParams>({
      query: ({ accessToken, token }) => ({
        url: 'login/facebook',
        method: 'POST',
        body: {
          access_token: accessToken,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      invalidatesTags: ['User'],
    }),
    logout: builder.mutation<void, void>({
      query: () => ({
        url: 'logout',
        method: 'POST',
      }),
    }),
    forgotPassword: builder.mutation<void, ForgotPasswordParams>({
      query: ({ email }) => ({
        url: 'passwords/forgot-link',
        method: 'POST',
        body: {
          email: email,
        },
      }),
    }),
    resetPassword: builder.mutation<void, ResetPasswordParams>({
      query: (body) => ({
        url: 'passwords/reset',
        method: 'POST',
        body,
      }),
    }),
    getMyUserWithSavedToken: builder.query<UserResponse, void>({
      query: () => ({
        url: 'users/me',
        method: 'GET',
      }),
      providesTags: ['User'],
    }),
    updateMyUser: builder.mutation<UserResponse, UserParams>({
      query: ({
        firstName,
        lastName,
        email,
        streetAddress,
        city,
        state,
        zip,
        apartment,
        countryCode,
        phoneNumber,
      }) => ({
        url: `/users/me`,
        method: 'PUT',
        body: {
          name: firstName + ' ' + lastName,
          email,
          street_address: streetAddress,
          city,
          state,
          zip,
          apartment,
          country_code: countryCode,
          phone_number: phoneNumber,
        },
      }),
      invalidatesTags: ['User'],
    }),
    getStreams: builder.query<StreamsFromApi, void>({
      query: () => `streams`,
    }),
    getSectionStreams: builder.query<StreamFeed, StreamsFromApiParams>({
      query: ({ page = 1, type }) => `streams/sections/${type}?page=${page}`,
    }),
    getLiveStreams: builder.query<StreamFeed, LiveStreamFromApiParams>({
      query: ({ page = 1 }) => `streams/sections/lives?page=${page}`,
    }),
    getLiveReplayStreams: builder.query<StreamFeed, LiveStreamFromApiParams>({
      query: ({ page = 1, shop = null }) => ({
        url: `streams/sections/replays`,
        params: { page, shop },
      }),
    }),
    getStream: builder.query<StreamFromApi, StreamFromApiParams>({
      query: ({ shopId, streamId }) => `/${shopId}/streams/${streamId}`,
    }),
    getProduct: builder.query<ProductFromApi, ProductFromApiParams>({
      query: ({ shopId, productId }) => `/${shopId}/products/${productId}`,
    }),
    addToCart: builder.mutation<AddToCartResponse, AddToCartParams>({
      query: function ({
        shopId,
        userId,
        variantId,
        experience,
        experienceId,
        platform,
        guestToken,
      }) {
        if (guestToken) {
          return {
            url: `/${shopId}/guest/cart/add`,
            method: 'POST',
            body: {
              experience,
              experience_id: experienceId,
              platform,
              inventory_id: variantId,
            },
            headers: {
              Authorization: `Bearer ${guestToken}`,
            },
          };
        }

        return {
          url: `/${shopId}/users/${userId}/cart/${variantId}`,
          method: 'POST',
          body: {
            experience,
            experience_id: experienceId,
            platform,
          },
        };
      },
      invalidatesTags: ['Cart', 'Waitlists'],
    }),
    getCart: builder.query<GetCartResponse, GetCartParams>({
      query: function ({ guestToken }) {
        if (guestToken) {
          return {
            url: '/guest/cart',
            headers: {
              Authorization: `Bearer ${guestToken}`,
            },
          };
        }
        return {
          url: `/users/me/cart`,
        };
      },
      providesTags: ['Cart'],
    }),
    removeFromCart: builder.mutation<
      RemoveFromCartResponse,
      RemoveFromCartParams
    >({
      // query: ({ shopId, userId, variantId }) => ({
      //   url: `/${shopId}/users/${userId}/cart/${variantId}`,
      //   method: 'DELETE',
      // }),
      query: function ({ shopId, userId, variantId, guestToken }) {
        if (guestToken) {
          return {
            url: `/${shopId}/guest/cart/remove/${variantId}`,
            method: 'DELETE',
            headers: {
              Authorization: `Bearer ${guestToken}`,
            },
          };
        }

        return {
          url: `/${shopId}/users/${userId ?? 'me'}/cart/${variantId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Cart', 'WaitlistedCarts'],
      // This is how we do optimistic updates with RTK Query. This is awesome.
      // https://redux-toolkit.js.org/rtk-query/usage/optimistic-updates
      async onQueryStarted(
        { userId, variantId },
        { dispatch, queryFulfilled }
      ) {
        // Very small TS error here on 'getCart' but the code works fine.
        // noinspection TypeScriptValidateJSTypes
        const patchResult = dispatch(
          marketplaceApi.util.updateQueryData('getCart', { userId }, (cart) => {
            const removedItem = cart.items.find(
              (item) => item.id === variantId
            );

            // Should be impossible not to find this item
            if (removedItem) {
              cart.subtotal -= removedItem.price;
              cart.items = cart.items.filter((item) => item.id !== variantId);
            }
          })
        );

        // Undo the optimistic update if there is an error
        queryFulfilled.catch(patchResult.undo);
      },
    }),
    checkoutCart: builder.mutation<CheckoutCartResponse, CheckoutCartParams>({
      query: ({ userId, cardId: card, note }) => ({
        url: `/users/${userId}/cart`,
        method: 'POST',
        body: {
          card,
          note,
        },
      }),
      invalidatesTags: ['Cart'],
    }),
    guestCheckout: builder.mutation<GuestCheckoutResponse, GuestCheckoutParams>(
      {
        query: ({ shopId, guestToken }) => ({
          url: `/${shopId}/guest/start-checkout`,
          method: 'POST',
          headers: {
            Authorization: `Bearer ${guestToken}`,
          },
        }),
        invalidatesTags: ['User', 'Cart'],
      }
    ),
    getGuestToken: builder.mutation<GetGuestTokenResponse, void>({
      query: () => `guest/token`,
    }),
    guestRegister: builder.mutation<void, GuestRegisterParams>({
      query: ({ email, password, passwordConfirmation }) => ({
        url: '/users/me/guest-register',
        method: 'POST',
        body: {
          email,
          password,
          password_confirmation: passwordConfirmation,
        },
      }),
      invalidatesTags: ['User'],
    }),
    createAddress: builder.mutation<CreateAddressResponse, CreateAddressParams>(
      {
        query: ({
          userId,
          streetAddress,
          city,
          state,
          zip,
          apartment,
          isBilling,
        }) => ({
          url: `/users/${userId}/addresses`,
          method: 'POST',
          body: {
            street_address: streetAddress,
            city,
            state,
            zip,
            apartment,
            is_billing: isBilling,
          },
        }),
        invalidatesTags: ['User'],
      }
    ),
    updateAddress: builder.mutation<UpdateAddressResponse, UpdateAddressParams>(
      {
        query: ({
          addressId,
          streetAddress,
          city,
          state,
          zip,
          apartment,
          isBilling,
        }) => ({
          url: `/addresses/${addressId}`,
          method: 'PUT',
          body: {
            street_address: streetAddress,
            city,
            state,
            zip,
            apartment,
            is_billing: isBilling,
          },
        }),
        invalidatesTags: ['User'],
      }
    ),
    getPaymentMethods: builder.query<
      GetPaymentMethodsResponse,
      GetPaymentMethodsParams
    >({
      query: ({ userId }) => ({
        url: `/users/${userId}/payment-methods`,
      }),
      providesTags: ['PaymentMethods'],
    }),
    addPaymentMethod: builder.mutation<
      AddPaymentMethodsResponse,
      AddPaymentMethodsParams
    >({
      query: ({ userId, token }) => ({
        url: `/users/${userId}/payment-methods`,
        method: 'POST',
        body: {
          token,
        },
      }),
      invalidatesTags: ['PaymentMethods'],
    }),
    setDefaultPaymentMethod: builder.mutation<
      SetDefaultPaymentMethodResponse,
      SetDefaultPaymentMethodParams
    >({
      query: ({ userId, cardId }) => ({
        url: `/users/${userId}/payment-methods`,
        method: 'PUT',
        body: {
          card: cardId,
        },
      }),
      invalidatesTags: ['PaymentMethods'],
    }),
    removePaymentMethod: builder.mutation<
      RemovePaymentResponse,
      RemovePaymentParams
    >({
      query({ userId, cardId }) {
        return {
          url: `/users/${userId}/payment-methods/${cardId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['PaymentMethods'],
    }),
    getShop: builder.query<GetShopResponse, GetShopParams>({
      query: ({ shopId }) => `shops/${shopId}`,
      providesTags: ['Shop'],
    }),
    emptyCart: builder.mutation<
      void,
      {
        userId?: number | null;
        guestToken?: string | null;
        shopId?: string | null;
      }
    >({
      query: function ({ userId, guestToken, shopId }) {
        if (guestToken) {
          return {
            url: `/${shopId}/guest/cart/empty`,
            method: 'DELETE',
            headers: {
              Authorization: `Bearer ${guestToken}`,
            },
          };
        }

        return {
          url: `/users/${userId ?? 'me'}/cart`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Cart'],
    }),
    getWaitlists: builder.query<GetWaitlistsResponse, GetWaitlistsParams>({
      query: ({ userId, perPage }) =>
        `users/${userId}/waitlist-items?per_page=${perPage}`,
      providesTags: ['Waitlists'],
    }),
    getWaitlistedCarts: builder.query<
      GetWaitlistsResponse,
      GetWaitlistedCartsParams
    >({
      query: ({ perPage }) =>
        `/users/me/waitlisted-cart-items?per_page=${perPage}`,
      providesTags: ['WaitlistedCarts'],
    }),
    waitlistCheckout: builder.mutation<
      WaitlistCheckoutResponse,
      WaitlistCheckoutParams
    >({
      query: ({ shopId, waitlistId }) => ({
        url: `/${shopId}/users/me/waitlists/${waitlistId}/move`,
        method: 'POST',
      }),
      invalidatesTags: ['Waitlists', 'WaitlistedCarts'],
    }),
    deleteWaitlistItem: builder.mutation<
      void,
      { shopId: string; waitlistItemId: string }
    >({
      query: ({ shopId, waitlistItemId }) => ({
        url: `${shopId}/waitlists/${waitlistItemId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Waitlists'],
    }),
    getOrder: builder.query<OrderResponse, GetOrderParams>({
      query: ({ shopId, orderId }) => `/${shopId}/orders/${orderId}`,
    }),
    getOrderHistory: builder.query<
      GetOrderHistoryResponse,
      GetOrderHistoryParams
    >({
      query: ({ userId, perPage, pageNumber }) =>
        `users/${userId}/orders?per_page=${perPage}&page=${pageNumber}`,
      providesTags: ['OrderHistory'],
    }),
    getFollowedShops: builder.query<GetFollowedShopsResponse, void>({
      query: () => `/users/me/follow`,
      providesTags: ['FollowedShops'],
    }),
    followShop: builder.mutation<void, FollowShopParams>({
      query: ({ shopId }) => ({
        url: `/users/me/follow/${shopId}`,
        method: 'POST',
      }),
      invalidatesTags: ['Shop', 'FollowedShops'],
    }),
    unfollowShop: builder.mutation<void, UnfollowShopParams>({
      query: ({ shopId }) => ({
        url: `/users/me/follow/${shopId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Shop', 'FollowedShops'],
    }),
  }),
});

// noinspection JSUnusedGlobalSymbols
export const {
  useForgotPasswordMutation,
  useGetStreamsQuery,
  useGetLiveStreamsQuery,
  useGetLiveReplayStreamsQuery,
  useGetStreamQuery,
  useGetSectionStreamsQuery,
  useRegisterMutation,
  useResetPasswordMutation,
  useLoginMutation,
  useFacebookLoginMutation,
  useGetMyUserWithSavedTokenQuery,
  useUpdateMyUserMutation,
  useLogoutMutation,
  useGetProductQuery,
  useAddToCartMutation,
  useGetCartQuery,
  useRemoveFromCartMutation,
  useCheckoutCartMutation,
  useGetGuestTokenMutation,
  useGuestRegisterMutation,
  useGuestCheckoutMutation,
  useCreateAddressMutation,
  useUpdateAddressMutation,
  useGetPaymentMethodsQuery,
  useAddPaymentMethodMutation,
  useSetDefaultPaymentMethodMutation,
  useRemovePaymentMethodMutation,
  useGetShopQuery,
  useEmptyCartMutation,
  useGetWaitlistsQuery,
  useGetWaitlistedCartsQuery,
  useWaitlistCheckoutMutation,
  useDeleteWaitlistItemMutation,
  useGetOrderQuery,
  useGetOrderHistoryQuery,
  useGetFollowedShopsQuery,
  useFollowShopMutation,
  useUnfollowShopMutation,
} = marketplaceApi;

export const rtkQueryMiddleware: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const {
      payload: { status },
    } = action;
    if (status === 401) {
      setToken(null);
      setGuestToken(null);
    }
  }

  return next(action);
};

// Private functions

function setHeaders(headers: Headers): Headers {
  const token = getToken();

  if (token) {
    headers.set('Authorization', `Bearer ${token}`);
  }

  headers.set('Accept', 'application/json');

  return headers;
}

function withTracking<T>(
  body: T,
  googleClientId?: string | null
): T & { google_client_id: string | null } {
  return {
    ...body,
    google_client_id: googleClientId ?? null,
  };
}
