import { selectCurrentUser, setUser } from './authSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useEffect, useState } from 'react';
import {
  useFacebookLoginMutation,
  useGetMyUserWithSavedTokenQuery,
  useLoginMutation,
  useRegisterMutation,
} from '../Api/apiSlice';
import { selectCart } from '../Cart/cartSlice';
import {
  FacebookLoginParams,
  LoginParams,
  RegisterParams,
} from '../Api/apiTypes';
import { SignUpInputs } from '../../components/organisms/SignUpForm/SignUpForm';
import { useTracker } from '../Tracker/useTracker';
import convertUserResToUser from './helpers/convertUserResToUser';
import { loginFacebook } from '../../helpers/facebookSdk';
import { User } from './authTypes';
import {
  getGuestToken,
  getToken,
} from '../../helpers/getOrSetLocalStorageItem';

export default function useAuth() {
  const [isFinishedAuthorizing, setIsFinishedAuthorizing] = useState(false);
  const dispatch = useAppDispatch();
  const user: User = useAppSelector(selectCurrentUser);
  const cart = useAppSelector(selectCart);
  const token = getToken();
  const guestToken = getGuestToken();
  const { identify, trackEvent } = useTracker();

  const [login] = useLoginMutation();
  const [facebookLoginMutation] = useFacebookLoginMutation();
  const [registerNewUser] = useRegisterMutation();
  const skipGetUser = !!guestToken || !token;
  const { data: userRes, isError } = useGetMyUserWithSavedTokenQuery(
    undefined,
    {
      skip: skipGetUser,
    }
  );

  useEffect(() => {
    if (userRes) {
      dispatch(setUser(convertUserResToUser(userRes)));
    }
  }, [userRes, dispatch]);

  useEffect(() => {
    if (skipGetUser || userRes) {
      setIsFinishedAuthorizing(true);
    }
  }, [userRes, dispatch, skipGetUser]);

  const loginUser = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    let params: LoginParams = {
      email,
      password,
      token: guestToken ? guestToken : null,
    };

    if (user?.isGuest && cart && cart.items?.length > 0) {
      params = {
        ...params,
        migrateShopCart: cart.shop,
        token,
      };
    }

    const response = await login(params).unwrap();

    // Track login
    identify(
      `${response?.first_name} ${response?.last_name}`.trim(),
      response?.email
    );
    trackEvent('login');

    return response;
  };

  const registerUser = async (data: SignUpInputs) => {
    const params = {
      ...data,
      token: guestToken || null,
    } as RegisterParams;

    await registerNewUser(params).unwrap();
  };

  const loginWithFacebook = async () => {
    const response = await loginFacebook();
    let params: FacebookLoginParams = {
      accessToken: response.authResponse.accessToken,
      token: guestToken ? guestToken : null,
    };

    if (user?.isGuest && cart && cart.items?.length > 0) {
      params = {
        ...params,
        migrateShopCart: cart.shop,
        token,
      };
    }

    return await facebookLoginMutation(params).unwrap();
  };

  return {
    user,
    loginUser,
    registerUser,
    isLoading: !isFinishedAuthorizing,
    isError,
    loginWithFacebook,
  };
}
