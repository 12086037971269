import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { Icon, IconName } from '../atoms/Icon';
import { ImageWithFallback } from '../atoms/ImageWithFallback';
import { StreamPreviewStat } from '../atoms/StreamPreviewStat';
import { animateScroll as scroll } from 'react-scroll';
import { formatTime } from '../../helpers/stringFormat';
import shopIcon from '../../assets/icons/shop-icon.svg';
import Skeleton from 'react-loading-skeleton';

interface StreamPreviewProps {
  className?: string;
  shopId?: string;
  shopName?: string;
  shopLogo?: string;
  streamId?: number;
  streamName?: string;
  thumbnailSrc?: string;
  gifSrc?: string;
  views?: number;
  startTime?: number;
  endTime?: number;
  isLive?: boolean;
  isSelected?: boolean;
  isLoading?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  useExplicitSize?: boolean;
  imageClassNames?: string;
}

export const StreamPreview: React.FC<StreamPreviewProps> = ({
  className,
  shopId,
  shopName,
  shopLogo,
  streamId,
  streamName,
  thumbnailSrc,
  gifSrc,
  views,
  startTime,
  endTime,
  isLive = false,
  isSelected = false,
  isLoading = false,
  onClick = () => null,
  useExplicitSize = true,
  imageClassNames = '',
}) => {
  const [hovering, setHovering] = useState(false);
  const currentTime = new Date().getTime() / 1000;

  const handleClick = (e: React.MouseEvent) => {
    onClick(e);
    scroll.scrollToTop({
      smooth: true,
      duration: 500,
    });
  };

  return (
    <Link
      className={cn(
        `inline-flex transform align-top`,
        {
          'pointer-events-none': !shopId || !streamId,
          'cursor-default': !shopId || !streamId,
          'w-72': useExplicitSize,
          'w-full': !useExplicitSize,
        },
        className
      )}
      data-testid={`stream-button-${shopId}/${streamId}`}
      draggable={false}
      to={`/shops/${shopId}/streams/${streamId}`}
      onBlur={() => setHovering(false)}
      onClick={handleClick}
      onFocus={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      onMouseOver={() => setHovering(true)}
    >
      <figure className="relative flex w-full flex-col">
        {isLoading ? (
          <Skeleton className="h-80 rounded-lg" />
        ) : (
          <ImageWithFallback
            alt={shopName}
            className={cn(
              `mx-auto w-full overflow-hidden rounded-lg object-cover`,
              {
                'rounded-xl border-4 border-secondary': isSelected,
                'h-80': useExplicitSize,
                'h-full': !useExplicitSize,
              },
              imageClassNames
            )}
            data-testid={`stream-image-${shopId}/${streamId}`}
            draggable={false}
            src={hovering ? gifSrc : thumbnailSrc}
          />
        )}

        <div className={`absolute left-2 top-2 flex gap-1`}>
          {isLive && (
            <StreamPreviewStat
              className={`rounded-full bg-red text-white`}
              icon={<Icon className={`h-3`} name={IconName.Record} />}
            >
              LIVE
            </StreamPreviewStat>
          )}
          {typeof views === 'number' && (
            <StreamPreviewStat
              icon={<Icon className={`h-3`} name={IconName.Eye} />}
            >
              {views}
            </StreamPreviewStat>
          )}
          {!!(startTime && (endTime || currentTime)) && (
            <StreamPreviewStat
              icon={<Icon className={`h-3`} name={IconName.ClockSolid} />}
            >
              {formatTime((endTime || currentTime) - startTime)}
            </StreamPreviewStat>
          )}
        </div>

        <figcaption className="mt-2 flex truncate leading-none">
          <div className="flex items-center">
            {shopLogo && (
              <ImageWithFallback
                alt={`${shopName} logo`}
                className="mr-2 inline-block h-6"
                data-testid={`shop-stream-logo-${shopId}/${streamId}`}
                fallbackSrc={shopIcon}
                src={shopLogo}
              />
            )}
          </div>

          <div>
            <h3
              className={`font-bold text-textDarkSecondary`}
              data-testid={`stream-shopname-${shopId}/${streamId}`}
            >
              {isLoading ? <Skeleton width={200} /> : shopName}
            </h3>

            <div
              className={`text-xs text-textDarkTertiary`}
              data-testid={`stream-name-${shopId}/${streamId}`}
            >
              {isLoading ? <Skeleton width={140} /> : streamName}
            </div>
          </div>
        </figcaption>
      </figure>
    </Link>
  );
};
