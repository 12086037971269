import React from 'react';
import { Nav } from '../../components/organisms/Nav/Nav';
import useCart from '../Cart/useCart';
import useWaitlists from '../Cart/useWaitlists';

const NavContainer: React.FC = () => {
  const { cartQuantity } = useCart();
  const { items } = useWaitlists();

  return <Nav numCartItems={cartQuantity} numWaitlistItems={items.length} />;
};

export { NavContainer };
