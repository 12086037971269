import React, { useEffect, useMemo, useState } from 'react';
import { InventoryItem, ProductFromApi } from '../../../features/Api/apiTypes';
import findInventoryItem from '../../../features/Product/helpers/findInventoryItem';
import createOptions from '../../../features/Product/helpers/createOptions';
import { VariantSwiper } from './VariantSwiper';

interface OptionSelectorProps {
  product: ProductFromApi;
  onChoose: (x?: InventoryItem) => void;
}

const VariantSelector: React.FC<OptionSelectorProps> = ({
  product,
  onChoose,
}) => {
  const [selectedSize, setSelectedSize] = useState<string>('');
  const [selectedColor, setSelectedColor] = useState<string>('');

  useEffect(() => {
    const inventoryItem = findInventoryItem({
      inventoryItems: product.inventory,
      selectedSize: selectedSize,
      selectedColor: selectedColor,
    });

    const doesInventoryItemMatchSelections =
      inventoryItem?.size === selectedSize &&
      inventoryItem?.color === selectedColor;

    onChoose(doesInventoryItemMatchSelections ? inventoryItem : undefined);
  }, [product.inventory, onChoose, selectedColor, selectedSize]);

  const options = useMemo(() => {
    return createOptions({
      inventoryItems: product.inventory,
      selectedSize: selectedSize,
      selectedColor: selectedColor,
    });
  }, [product, selectedSize, selectedColor]);

  const handleOptionClick =
    (setter: React.Dispatch<React.SetStateAction<string>>) =>
    (optionTitle: string) => {
      setter((prevTitle) => (prevTitle === optionTitle ? '' : optionTitle));
    };

  const sizesArray = Object.entries(options.sizes);
  const colorsArray = Object.entries(options.colors);

  return (
    <div>
      {/* Could be zero */}
      {options.singleOption !== undefined && (
        <div className="text-lg text-black">{options.singleOption} left</div>
      )}

      {!!sizesArray.length && (
        <>
          <h4 className="mb-2">Sizes</h4>

          <VariantSwiper
            optionsArray={sizesArray}
            selectedOption={selectedSize}
            onOptionClick={handleOptionClick(setSelectedSize)}
          />
        </>
      )}

      {!!colorsArray.length && (
        <>
          <h4 className="mt-4 mb-2">Colors</h4>

          <VariantSwiper
            optionsArray={colorsArray}
            selectedOption={selectedColor}
            onOptionClick={handleOptionClick(setSelectedColor)}
          />
        </>
      )}
    </div>
  );
};

export { VariantSelector };
