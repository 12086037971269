import React from 'react';
import cn from 'classnames';

export function OrderDetailItem({
  title,
  title2,
  titleClassName,
  value,
  valueClassName,
  valueLine2,
}: {
  title: string;
  title2?: string | null;
  value: string;
  valueLine2?: string | null;
  titleClassName?: string;
  valueClassName?: string;
}) {
  return (
    <div className={'flex justify-between text-sm'}>
      <div className={'mr-2'}>
        <span className={cn('text-textDarkTertiary', titleClassName)}>
          {title}
        </span>
        {title2 && <span className={'ml-2'}>{title2}</span>}
      </div>

      <div className={valueClassName}>
        <div className={'text-right'}>{value}</div>
        {valueLine2 && <div className={'text-right'}>{valueLine2}</div>}
      </div>
    </div>
  );
}
