// noinspection JSUnusedGlobalSymbols

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetCartResponse } from '../Api/apiTypes';
import { RootState } from '../../store/store';

type CartState = {
  cart: GetCartResponse | null;
  paymentMethod: PaymentMethod | null;
  shippingMethod: ShippingMethod | null;
  isAddingToCart: boolean;
};

export enum PaymentMethod {
  CreditCard = 'creditcard',
  PayPal = 'paypal',
  Sezzle = 'sezzle',
  ApplePay = 'applepay',
}

export enum ShippingMethod {
  Local = 'local',
  Standard = 'standard',
}

const slice = createSlice({
  name: 'cart',
  initialState: {
    cart: null,
    paymentMethod: null,
    shippingMethod: null,
    isAddingToCart: false,
  } as CartState,
  reducers: {
    setCart: (state, { payload }: PayloadAction<GetCartResponse | null>) => {
      state.cart = payload;
    },
    setPaymentMethod: (
      state,
      { payload }: PayloadAction<PaymentMethod | null>
    ) => {
      state.paymentMethod = payload;
    },
    setShippingMethod: (
      state,
      { payload }: PayloadAction<ShippingMethod | null>
    ) => {
      state.shippingMethod = payload;
    },
    setIsAddingToCart: (state, { payload }: PayloadAction<boolean>) => {
      state.isAddingToCart = payload;
    },
  },
});

export const {
  setCart,
  setPaymentMethod,
  setShippingMethod,
  setIsAddingToCart,
} = slice.actions;

export default slice.reducer;

export const selectCart = (state: RootState) => state.cart.cart;
export const selectCurrentPaymentMethod = (state: RootState) =>
  state.cart.paymentMethod;
export const selectCurrentShippingMethod = (state: RootState) =>
  state.cart.shippingMethod;
export const selectIsAddingToCart = (state: RootState) =>
  state.cart.isAddingToCart;
