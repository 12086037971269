import React, { HTMLAttributes } from 'react';
import cn from 'classnames';

interface CardProps extends HTMLAttributes<HTMLDivElement> {
  withBorder?: boolean;
  withShadow?: boolean;
  borderPosition?: 't' | 'b' | 'l' | 'r' | '';
  rounded?: boolean;
  className?: string;
}

const Card: React.FC<CardProps> = ({
  children,
  withBorder = false,
  withShadow = true,
  rounded = true,
  borderPosition = '',
  ...props
}) => {
  const classes = cn(
    'border-gray-200 bg-white',
    withBorder ? `border${borderPosition ? `-${borderPosition}` : ''}` : '',
    {
      'shadow-md': withShadow,
      'rounded-md': rounded,
    },
    props.className
  );

  return (
    <div {...props} className={classes}>
      {children}
    </div>
  );
};

export { Card };
