import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store/store';

type StreamState = {
  streamId: number | null;
  isLive: boolean;
};

const slice = createSlice({
  name: 'stream',
  initialState: {
    streamId: null,
    isLive: false,
  } as StreamState,
  reducers: {
    setStreamId: (state, { payload }: PayloadAction<number | null>) => {
      state.streamId = payload;
    },
    setIsLive: (state, { payload }: PayloadAction<boolean>) => {
      state.isLive = payload;
    },
  },
});

export const { setStreamId, setIsLive } = slice.actions;

export default slice.reducer;

export const selectCurrentStreamId = (state: RootState) =>
  state.stream.streamId;
export const selectIsLive = (state: RootState) => state.stream.isLive;
