import React, { ButtonHTMLAttributes } from 'react';
import { FaChevronRight } from 'react-icons/fa';
import cn from 'classnames';
import { Button } from './Button';

export type NavigationListTabProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  icon: React.ReactNode;
  title: string;
  active?: boolean;
};

const NavigationListTab: React.FC<NavigationListTabProps> = ({
  icon,
  title,
  active = false,
  ...props
}) => {
  return (
    <Button
      fullWidth
      className={cn('flex w-full items-center p-4', {
        'bg-gray-50': active,
      })}
      {...props}
    >
      <div className="mr-3">{icon}</div>
      <div>{title}</div>
      <div className="ml-auto">
        <FaChevronRight />
      </div>
    </Button>
  );
};

export { NavigationListTab };
