import React, { useEffect, useLayoutEffect, useState } from 'react';
import { VscChromeClose } from 'react-icons/vsc';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { A11y, Navigation } from 'swiper';
import { ImageWithFallback } from '../atoms/ImageWithFallback';
import imagePlaceholder from '../../assets/image-placeholder.png';
import videojs, { VideoJsPlayer } from 'video.js';
import 'swiper/components/a11y/a11y.min.css';
import 'swiper/components/navigation/navigation.min.css';

SwiperCore.use([A11y, Navigation]);

interface Media {
  id: string;
  videoSrc?: string;
  src: string;
  alt: string;
}

interface LightboxMediaGalleryProps {
  selectedIndex: number;
  media: Media[];
  onClose: () => void;
}

const LightboxMediaGallery: React.FC<LightboxMediaGalleryProps> = ({
  selectedIndex,
  media,
  onClose,
}) => {
  const [currentIndex, setCurrentIndex] = useState(selectedIndex);
  return (
    <div
      className={
        'fixed inset-0 z-30 flex items-center justify-center bg-black bg-opacity-70 transition-opacity'
      }
      data-testid="lightbox-media-gallery"
    >
      <button className="absolute top-3 right-3 z-10" onClick={onClose}>
        <VscChromeClose color="white" size="2rem" />
      </button>

      <Swiper
        autoHeight={true}
        initialSlide={selectedIndex}
        navigation={true}
        slidesPerView={1}
        onSlideChange={(swiper) => setCurrentIndex(swiper.activeIndex)}
      >
        {media.map((mediaItem, i) => {
          return (
            <SwiperSlide key={mediaItem.id}>
              <div className="flex items-center justify-center">
                {mediaItem.videoSrc ? (
                  <div
                    className="overflow-hidden rounded-md"
                    style={{
                      width: 500,
                      maxWidth: '100vw',
                      height: 900,
                      maxHeight: '100vh',
                    }}
                  >
                    {currentIndex === i && (
                      <LightboxVideoPlayer streamUrl={mediaItem.videoSrc} />
                    )}
                  </div>
                ) : (
                  <ImageWithFallback
                    alt={mediaItem.alt}
                    className="rounded-md object-cover"
                    fallbackSrc={imagePlaceholder}
                    src={mediaItem.src}
                  />
                )}
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export { LightboxMediaGallery };

// PRIVATE FUNCTIONS

function LightboxVideoPlayer({ streamUrl }: { streamUrl: string }) {
  const [wasMainVideoMuted] = useState(window.videojs?.muted());
  const [player, setPlayer] = useState<VideoJsPlayer | undefined>();
  const videoRef = React.useRef(null);

  useLayoutEffect(() => {
    const wasMainVideoMuted = window.videojs?.muted();
    window.videojs?.muted(true);

    return () => {
      window.videojs?.muted(wasMainVideoMuted);
    };
  }, []);

  useEffect(() => {
    const videoJsOptions = {
      autoplay: true,
      muted: false,
      responsive: true,
      fluid: true,
      sources: [
        {
          src: streamUrl,
          type: 'application/x-mpegURL',
        },
      ],
      playsinline: true, // For mobile Safari
      retryOnError: true,
    };

    const videoElement = videoRef.current;

    if (videoElement && !player) {
      setPlayer(videojs(videoElement, videoJsOptions));
    }

    return () => {
      player?.dispose();
    };
  }, [player, wasMainVideoMuted, streamUrl]);

  return (
    <div data-vjs-player="true">
      <video ref={videoRef} className="video-js" />
    </div>
  );
}
