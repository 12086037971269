import React, { useState } from 'react';
import { InputWithLabel } from '../../molecules/InputWithLabel';
import { Button } from '../../atoms/Button';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import get from 'lodash/get';
import useErrorHandling from '../../../hooks/useErrorHandling';

export interface ResetPasswordInputs {
  email: string;
  token: string;
  password: string;
  password_confirmation: string;
}

export interface ResetPasswordFormProps {
  onSubmit: (data: ResetPasswordInputs) => Promise<void>;
  token: string;
}

export const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({
  onSubmit: componentOnSubmit,
  token,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<ResetPasswordInputs>();
  const handleError = useErrorHandling();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit: SubmitHandler<ResetPasswordInputs> = async (data) => {
    try {
      setIsSubmitting(true);
      return await componentOnSubmit(data);
    } catch (error) {
      handleError({ error, message: get(error, 'data.error', '') });
    } finally {
      setIsSubmitting(false);
    }
  };

  const passwordState = useWatch({
    control,
    name: 'password',
  });

  const rules = {
    email: {
      required: 'Email is required',
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: 'Must be a valid email',
      },
    },
    password: {
      required: 'Password is required',
      minLength: {
        value: 8,
        message: 'Must be at least 8 characters',
      },
      maxLength: {
        value: 255,
        message: 'Must be less than 255 characters',
      },
      validate: {
        oneLower: (value: string) =>
          /[a-z]/.test(value) || 'Must contain at least one lowercase letter',
        oneUpper: (value: string) =>
          /[A-Z]/.test(value) || 'Must contain at least one uppercase letter',
        oneNumber: (value: string) =>
          /[0-9]/.test(value) || 'Must contain at least one number',
      },
    },
    password_confirmation: {
      validate: {
        match: (value: string) =>
          value === passwordState || 'Passwords must match',
      },
    },
  };

  return (
    <div className="relative" data-testid="reset-password-form">
      <h1 className="mb-4">Reset Password</h1>

      <form noValidate className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
        <input
          type="hidden"
          value={token}
          {...register('token', { required: true })}
        />

        <InputWithLabel
          autoComplete="email"
          error={errors?.email?.message}
          id="email"
          labelText="Email Address"
          type="email"
          {...register('email', rules.email)}
        />

        <InputWithLabel
          error={errors?.password?.message}
          id="password"
          labelText="Password"
          type="password"
          {...register('password', rules.password)}
        />

        <InputWithLabel
          error={errors?.password_confirmation?.message}
          id="password_confirmation"
          labelText="Confirm Password"
          type="password"
          {...register('password_confirmation', rules.password_confirmation)}
        />

        <div>
          <Button fullWidth primary isLoading={isSubmitting} type="submit">
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
};
