import { setToast as setToastAction, ToastState } from './toastSlice';
import { useAppDispatch } from '../../store/hooks';
import { useCallback } from 'react';

const DEFAULT_TOAST_TIMEOUT = 5000;
let savedTimeout: ReturnType<typeof setTimeout>;

export default function useToast() {
  const dispatch = useAppDispatch();

  const setToast = useCallback(
    (t: ToastState) => {
      clearTimeout(savedTimeout);
      dispatch(setToastAction(t));
    },
    [dispatch]
  );

  const setToastWithTimeout = useCallback(
    (t: ToastState, timeout: number = DEFAULT_TOAST_TIMEOUT) => {
      setToast(t);

      savedTimeout = setTimeout(() => {
        setToast(null);
      }, timeout);
    },
    [setToast]
  );

  return { setToastWithTimeout, setToast };
}
