import React from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { Button } from '../../components/atoms/Button';
import { ImageWithFallback } from '../../components/atoms/ImageWithFallback';
import {
  useFollowShopMutation,
  useGetFollowedShopsQuery,
  useGetLiveReplayStreamsQuery,
  useUnfollowShopMutation,
} from '../Api/apiSlice';
import useShop from './useShop';
import shopPlaceholder from '../../assets/shop-placeholder.svg';
import { FollowedShop } from '../Api/apiTypes';
import { StreamPreview } from '../../components/molecules/StreamPreview';
import useAuth from '../Auth/useAuth';
import useToast from '../Toast/useToast';
import { ToastType } from '../Toast/toastSlice';
import { useCallbacks } from '../../context/CallbacksContext';
import { User } from '../Auth/authTypes';
import { LoadingIndicator } from '../../components/molecules/LoadingIndicator';

interface ShopPageParams {
  shopId: string;
}

const Shop: React.FC = () => {
  const { shopId: paramShopId } = useParams<ShopPageParams>();
  const { user } = useAuth();
  const { data: streams, isLoading: isLoadingStreams } =
    useGetLiveReplayStreamsQuery({
      page: 1,
      shop: paramShopId,
    });
  const { data: shop, isLoading: isLoadingShop } = useShop(paramShopId);
  const { setToastWithTimeout } = useToast();
  const { setSignInCallback } = useCallbacks();
  const { data: followedShops } = useGetFollowedShopsQuery(undefined, {
    skip: !user,
  });
  const [follow, { isLoading: isUnfollowingLoading }] = useFollowShopMutation();
  const [unfollow, { isLoading: isFollowingLoading }] =
    useUnfollowShopMutation();
  const isLoadingFollowAction = isFollowingLoading || isUnfollowingLoading;
  const isLoading = isLoadingShop || isLoadingStreams;

  const isFollowingShop = followedShops?.some(
    (shop: FollowedShop) => shop.shop === paramShopId
  );

  const handleUnfollow = async () => {
    await unfollow({
      shopId: paramShopId,
    });
  };

  const handleFollow = async () => {
    if (!user || user?.isGuest) {
      setToastWithTimeout({
        type: ToastType.error,
        message: 'You must be logged in to follow a shop.',
      });

      setTimeout(() => {
        setSignInCallback(
          () => (userArg: User) =>
            follow({
              shopId: paramShopId,
            })
        );
      }, 1000);

      return;
    }
    await follow({
      shopId: paramShopId,
    });
  };

  if (isLoading) {
    return <LoadingIndicator />;
  } else if (!paramShopId || !shop) {
    return <Redirect to={'/'} />;
  }

  return (
    <div>
      <div className="flex items-center bg-white p-4">
        <div>
          <ImageWithFallback
            alt={`${shop?.shop_name} logo`}
            className="mb-2 inline-block h-12 w-12 object-contain"
            data-testid={`shop-logo-${paramShopId}`}
            fallbackSrc={shopPlaceholder}
            src={shop?.settings?.app_icon || shop?.settings?.favicon || ''}
          />
          <h1>{shop?.shop_name}</h1>
          {shop?.number_of_followers} Follower
          {shop?.number_of_followers === 1 ? '' : 's'}
        </div>
        <div className="ml-auto">
          {!isFollowingShop && (
            <Button
              outlined
              className="px-8 sm:px-12 md:px-24"
              data-testid={`follow-shop-${paramShopId}`}
              disabled={isLoadingFollowAction}
              onClick={handleFollow}
            >
              Follow
            </Button>
          )}
          {isFollowingShop && (
            <Button
              outlined
              className="px-8 sm:px-12 md:px-24"
              data-testid={`unfollow-shop-${paramShopId}`}
              disabled={isLoadingFollowAction}
              onClick={handleUnfollow}
            >
              Unfollow
            </Button>
          )}
        </div>
      </div>
      <div className="grid grid-cols-1 gap-6 p-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4">
        {streams?.data?.map((stream, i) => (
          <div
            key={`${stream.name} - ${stream.id} [${i}]`}
            className="flex h-144 sm:h-96 md:h-96 lg:h-128 xl:h-144"
          >
            <StreamPreview
              endTime={stream.ended_at}
              gifSrc={stream.animated_thumb || stream.source_thumb}
              isLive={false}
              isSelected={false}
              shopId={stream.shop}
              shopLogo={stream.app_icon || stream.favicon || ''}
              shopName={stream.shop_name || stream.shop}
              startTime={stream.started_at}
              streamId={stream.id}
              streamName={stream.name}
              thumbnailSrc={stream.source_thumb}
              useExplicitSize={false}
              views={stream.peak_viewers}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export { Shop };
