import React, { ButtonHTMLAttributes } from 'react';
import cn from 'classnames';

type IconButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  className?: string;
  type?: 'button' | 'submit' | 'reset';
};

export const IconButton: React.FC<IconButtonProps> = ({
  children,
  type = 'button',
  className = '',
  ...props
}) => (
  <button
    {...props}
    className={cn(
      'flex h-8 w-8 items-center justify-center rounded-full bg-white shadow',
      'hover:bg-black hover:text-white active:bg-black active:text-white',
      className
    )}
    type={type}
  >
    {children}
  </button>
);
