import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { AccountPage } from '../Account/AccountPage';
import { CartPage } from '../Cart/CartPage';
import { CheckoutPage } from '../Checkout/CheckoutPage';
import { DebugPage } from '../Debug/DebugPage';
import { DiscoverPage } from '../DiscoverPage/DiscoverPage';
import { ForgotPasswordPage } from '../Auth/ForgotPasswordPage';
import { ResetPasswordPage } from '../Auth/ResetPasswordPage';
import { SignInPage } from '../Auth/SignInPage';
import { SignOutPage } from '../Auth/SignOutPage';
import { SignUpPage } from '../Auth/SignUpPage';
import { ProductPage } from '../Product/ProductPage';
import { WaitlistPage } from '../Waitlist/WaitlistPage';
import { OrderPage } from '../Order/OrderPage';
import { useAppSelector } from '../../store/hooks';
import { selectCurrentUser } from '../Auth/authSlice';
import { ShopPage } from '../Shop/ShopPage';

const Routes: React.FC = () => {
  const user = useAppSelector(selectCurrentUser);

  return (
    <Switch>
      <Route component={AccountPage} path="/account" />
      <Route component={CartPage} path="/cart" />
      <Route component={CheckoutPage} path="/checkout" />

      {process.env.REACT_APP_ENVIRONMENT !== 'production' && (
        <Route component={DebugPage} path="/debug" />
      )}

      <Route component={ForgotPasswordPage} path="/forgot-password" />
      <Route component={ResetPasswordPage} path="/reset-password/:token" />
      <Route
        component={ProductPage}
        path="/shops/:shopId/products/:productId"
      />
      <Route component={SignInPage} path="/sign-in" />
      <Route component={SignOutPage} path="/sign-out" />
      <Route component={SignUpPage} path="/sign-up" />
      {user && !user.isGuest && (
        <Route component={WaitlistPage} path="/waitlist" />
      )}
      <Route component={OrderPage} path="/order" />
      <Route component={ShopPage} path="/shops" />

      <Route component={DiscoverPage} path={['/']} />
    </Switch>
  );
};

export { Routes };
