import React from 'react';
import cn from 'classnames';
import { FlexSpacer } from '../../../components/atoms/FlexSpacer';
import { PaymentMethod } from '../../Api/apiTypes';

interface PaymentPreviewProps {
  payment: PaymentMethod;
  action?: React.ReactNode;
  className?: string;
}

export const PaymentPreview: React.FC<PaymentPreviewProps> = ({
  payment,
  action,
  className = '',
}) => {
  return (
    <div className={cn(className, `flex gap-2 rounded border p-4`)}>
      <div>
        {payment.brand} ****{payment.last_four} Expires {payment.exp_month}/
        {payment.exp_year}
      </div>
      <FlexSpacer />
      <div className="whitespace-nowrap">{action}</div>
    </div>
  );
};
