import React from 'react';
import { Button } from '../atoms/Button';
import { Icon, IconName } from '../atoms/Icon';
import cn from 'classnames';

export type BannerProps = {
  type: 'success' | 'error';
  id?: string;
  isDismissible?: boolean;
  onDismiss?: () => void;
};

export const Banner: React.FC<BannerProps> = ({
  children,
  type,
  id = '',
  isDismissible = false,
  onDismiss = undefined,
  ...props
}) => {
  const classes = cn('flex items-center gap-5 border p-5 rounded', {
    'border-error': type === 'error',
    'border-green-500 bg-green-50 bg-opacity-50': type === 'success',
  });

  return (
    <div className={classes} {...props}>
      <div className="flex w-12 justify-center">
        <Icon name={type as IconName} />
      </div>
      <div>
        {id && (
          <h4 className="font-bold" data-testid="id">
            {id}
          </h4>
        )}
        {children && <p data-testid="message">{children}</p>}
      </div>
      <div className="flex-grow"></div>
      {isDismissible && (
        <div>
          <Button data-testid="dismiss-button" onClick={onDismiss}>
            Dismiss
          </Button>
        </div>
      )}
    </div>
  );
};
