import { OrderProduct } from './OrderHistory';
import React from 'react';
import { ImageWithFallback } from '../../../atoms/ImageWithFallback';

export function OrderProductDetails({
  orderProduct,
  showShopInfo = true,
  showStatus = true,
  showPrice = false,
}: {
  orderProduct: OrderProduct;
  showShopInfo?: boolean;
  showStatus?: boolean;
  showPrice?: boolean;
}) {
  return (
    <div className={'flex justify-between'}>
      <ImageWithFallback
        alt={orderProduct.productName}
        className="h-16 w-16 rounded-md object-cover"
        src={orderProduct.productImgSrc}
      />

      <div
        className={
          'm-auto ml-4 flex-1 items-center space-y-0.5 text-sm text-textDarkSecondary'
        }
      >
        <h4
          className={'text-base font-semibold text-black'}
          style={{ marginTop: -6 }}
        >
          {orderProduct.productName}
        </h4>
        {showShopInfo && (
          <div>
            {orderProduct.shopName} - {orderProduct.shopEmail}
          </div>
        )}
        {(orderProduct.size || orderProduct.color) && (
          <div data-testid={'orderSizeAndColor'}>
            {buildSizeAndColor(orderProduct)}
          </div>
        )}
        {showStatus && <div>Status: {orderProduct.shippingStatus}</div>}
        {orderProduct.shippingTrackingNumber && (
          <div>
            Tracking No.{' '}
            {orderProduct.shippingTrackingUrl ? (
              <a
                className={'text-blue-600 underline hover:no-underline'}
                href={orderProduct.shippingTrackingUrl}
                rel={'noreferrer'}
                target={'_blank'}
              >
                {orderProduct.shippingTrackingNumber}
              </a>
            ) : (
              orderProduct.shippingTrackingNumber
            )}
          </div>
        )}
      </div>
      {showPrice && (
        <div className="m-auto">
          <h4 className={'text-base font-semibold text-black'}>
            {orderProduct.price}
          </h4>
        </div>
      )}
    </div>
  );
}

// PRIVATE

function buildSizeAndColor(orderProduct: OrderProduct) {
  if (orderProduct.size && orderProduct.color) {
    return `${orderProduct.size}, ${orderProduct.color}`;
  }

  if (orderProduct.size) {
    return orderProduct.size;
  }

  if (orderProduct.color) {
    return orderProduct.color;
  }

  return '';
}
