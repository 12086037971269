import React from 'react';
import { Container } from '../../components/layout/Container';
import { Order } from './Order';

const OrderPage: React.FC = () => {
  return (
    <Container>
      <Order />
    </Container>
  );
};

export { OrderPage };
