import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store/store';

export enum ToastType {
  success,
  info,
  warning,
  error,
}

export type ToastState = null | {
  type: ToastType;
  message: string;
  details?: string;
  link?: {
    to: string;
    name: string;
  };
};

const slice = createSlice({
  name: 'auth',
  initialState: null as ToastState,
  reducers: {
    setToast: (state, { payload }: PayloadAction<ToastState>) => {
      return (
        // Could be null, which is used to hide the Toast
        payload && {
          type: payload.type,
          message: payload.message,
          details: payload.details,
          link: payload.link,
        }
      );
    },
  },
});

export const { setToast } = slice.actions;

export default slice.reducer;

export const selectCurrentToast = (state: RootState) => state.toast;
