const MARKETPLACE_LOCAL_STORAGE_PREFIX = 'cs-marketplace: ';
const TOKEN = 'token';
const GUEST_TOKEN = 'guestToken';

export function getOrSetLocalStorageItem(
  name: string,
  value?: string | null
): string | null {
  if (!name) return null;

  const n = MARKETPLACE_LOCAL_STORAGE_PREFIX + name;

  if (value) {
    localStorage.setItem(n, value);
  }

  if (value === null) {
    localStorage.removeItem(n);
  }

  return localStorage.getItem(n);
}

export const getToken = () => getOrSetLocalStorageItem(TOKEN);
export const setToken = (val: string | null) =>
  getOrSetLocalStorageItem(TOKEN, val);

export const getGuestToken = () => getOrSetLocalStorageItem(GUEST_TOKEN);
export const setGuestToken = (val: string | null) =>
  getOrSetLocalStorageItem(GUEST_TOKEN, val);
