import React, { FormEvent, useEffect } from 'react';
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { Card } from '../../../components/layout/Card';
import { User } from '../../Auth/authTypes';
import { Button } from '../../../components/atoms/Button';
import { InputWithLabel } from '../../../components/molecules/InputWithLabel';
import DropdownInput from '../../../components/molecules/DropdownInput';
import {
  provinceOptions,
  stateOptions,
} from '../../../constants/statesOptions';
import useErrorHandling from '../../../hooks/useErrorHandling';
import NumberFormat from 'react-number-format';
import { useAppSelector } from '../../../store/hooks';
import { selectCurrentUser } from '../../Auth/authSlice';
import { useUpdateMyUserMutation } from '../../Api/apiSlice';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';

// noinspection JSUnusedGlobalSymbols,RegExpSimplifiable
const rules = {
  firstName: {
    required: 'First name is required',
  },
  lastName: {
    required: 'Last name is required',
  },
  email: {
    required: 'Email is required',
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: 'Must be a valid email',
    },
  },
  phoneNumber: {
    required: false,
    validate: {
      validPhoneNumber: function (value: string | null) {
        if (!value?.replace(/\D/g, '')) return true;
        return (
          value.replace(/\D/g, '').length === 10 ||
          'Phone number must be 10 digits'
        );
      },
    },
  },
};

export const ProfileSection: React.FC = () => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const user = useAppSelector(selectCurrentUser);
  const handleError = useErrorHandling();
  const {
    register,
    reset,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<User>({
    mode: 'onSubmit',
  });
  const [updateMyUser, { isLoading }] = useUpdateMyUserMutation();
  const countryCodeState = useWatch({
    control,
    name: 'countryCode',
  });

  useEffect(() => {
    if (user) {
      reset(user);
    }
  }, [reset, user]);

  const submitHandler: SubmitHandler<User> = async (rawData) => {
    try {
      const data = formatFormData(rawData);

      await updateMyUser({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        streetAddress: data.streetAddress,
        city: data.city,
        state: data.state,
        zip: data.zip,
        apartment: data.apartment,
        countryCode: data.countryCode,
        phoneNumber: data.phoneNumber,
      }).unwrap();

      history.push(path);
    } catch (error) {
      handleError({ error });
    }
  };

  const preventSpaces = (
    event: React.FormEvent<HTMLInputElement>,
    key: 'firstName'
  ) => {
    setValue(key, removeSpaces(event));
  };

  return (
    user && (
      <Card className="px-4 pb-6" withBorder={true} withShadow={false}>
        {isLoading ? (
          <div>Updating user...</div>
        ) : (
          <Switch>
            <Route exact path={path}>
              <form noValidate onSubmit={handleSubmit(submitHandler)}>
                <div className="my-4 flex items-center justify-between">
                  <h2 className="font-medium">Your Profile Information</h2>
                  <div>
                    <Button
                      asInternalLink
                      primary
                      data-testid={'profile-edit'}
                      href={`${path}/edit`}
                    >
                      Edit
                    </Button>
                  </div>
                </div>

                <div className="divide-y">
                  <div className="flex justify-between space-x-4 py-4">
                    <div>Name</div>
                    <div>
                      {user.firstName} {user.lastName}
                    </div>
                  </div>

                  <div className="flex justify-between space-x-4 py-4">
                    <div>Email</div>
                    <div className={'truncate'}>{user.email}</div>
                  </div>

                  <div className="flex justify-between space-x-4 py-4">
                    <div>Phone Number</div>
                    <div>{formatPhoneNumber(user.phoneNumber)}</div>
                  </div>

                  <div className="flex flex-col justify-between truncate py-4 md:flex-row">
                    <div>Shipping Address</div>
                    <div className="md:text-right">
                      {user.streetAddress} {user.apartment}
                      <br />
                      {user.city}
                      {user.city && user.state ? ',' : ''} {user.state}{' '}
                      {user.zip}
                    </div>
                  </div>
                </div>
              </form>
            </Route>

            <Route path={`${path}/edit`}>
              <form noValidate onSubmit={handleSubmit(submitHandler)}>
                <div className="my-4 flex items-center justify-between">
                  <h2 className="font-medium">Your Profile Information</h2>
                  <div className="flex space-x-3">
                    <Button
                      asInternalLink
                      className="flex items-center border py-2"
                      href={path}
                    >
                      Cancel
                    </Button>
                    <Button primary className="py-2" type="submit">
                      Save
                    </Button>
                  </div>
                </div>
                <div className="space-y-4">
                  <div className="grid grid-cols-1 gap-4 md:grid-cols-4">
                    <div className="col-span-1 md:col-span-2">
                      <InputWithLabel
                        id="firstName"
                        labelText="First Name"
                        type="text"
                        {...register('firstName', rules.firstName)}
                        autoComplete="given-name"
                        error={errors?.firstName?.message}
                        onChange={(event) => preventSpaces(event, 'firstName')}
                      />
                    </div>
                    <div className="col-span-1 md:col-span-2">
                      <InputWithLabel
                        id="lastName"
                        labelText="Last Name"
                        type="text"
                        {...register('lastName', rules.lastName)}
                        autoComplete="family-name"
                        error={errors?.lastName?.message}
                      />
                    </div>
                  </div>
                  <h3 className="font-bold">Shipping Address</h3>
                  <div className="grid grid-cols-1 gap-4 md:grid-cols-4">
                    <div className="col-span-1 md:col-span-2">
                      <InputWithLabel
                        error={errors?.email?.message}
                        id="email"
                        labelText="Email"
                        type="text"
                        {...register('email', rules.email)}
                        autoComplete="email"
                      />
                    </div>
                    <div className="col-span-1 md:col-span-2">
                      <Controller
                        control={control}
                        name="phoneNumber"
                        render={({ field }) => (
                          <NumberFormat
                            allowEmptyFormatting={true}
                            autoComplete="tel"
                            customInput={InputWithLabel}
                            error={errors?.phoneNumber?.message}
                            format="###-###-####"
                            id="phone"
                            label="Phone Number"
                            labelText="Phone Number"
                            mask="_ "
                            type="tel"
                            value={field.value}
                            onChange={field.onChange}
                          />
                        )}
                        rules={rules.phoneNumber}
                      />
                    </div>
                  </div>
                  <InputWithLabel
                    autoComplete="shipping address-line1"
                    id="address1"
                    labelText="Shipping Address Line 1"
                    type="text"
                    {...register('streetAddress')}
                  />
                  <InputWithLabel
                    autoComplete="shipping address-line2"
                    id="address2"
                    labelText="Shipping Address Line 2"
                    type="text"
                    {...register('apartment')}
                  />
                  <div className="grid grid-cols-4 gap-4 md:grid-cols-4 lg:grid-cols-8">
                    <div className="col-span-4 md:col-span-2 lg:col-span-4">
                      <InputWithLabel
                        autoComplete="shipping locality"
                        id="city"
                        labelText="City"
                        type="text"
                        {...register('city')}
                      />
                    </div>
                    <div className="col-span-4 md:col-span-2 lg:col-span-1">
                      <DropdownInput
                        autoComplete="shipping region"
                        defaultValue={user.state ?? ''}
                        id="state"
                        labelText={
                          countryCodeState === 'CA' ? 'Province' : 'State'
                        }
                        options={
                          countryCodeState === 'CA'
                            ? provinceOptions
                            : stateOptions
                        }
                        {...register('state')}
                      />
                    </div>
                    <div className="col-span-2">
                      <InputWithLabel
                        autoComplete="shipping postal-code"
                        id="zip"
                        labelText="Postal or Zip Code"
                        type="text"
                        {...register('zip')}
                      />
                    </div>
                    <div className="col-span-2 lg:col-span-1">
                      <DropdownInput
                        autoComplete="shipping country"
                        defaultValue={user.countryCode ?? ''}
                        id="country"
                        labelText="Country"
                        options={[
                          { value: 'US', label: 'US' },
                          { value: 'CA', label: 'CA' },
                        ]}
                        {...register('countryCode')}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </Route>
          </Switch>
        )}
      </Card>
    )
  );
};

const formatFormData = (data: User) => {
  const phoneNumber = data?.phoneNumber?.replace(/\D/g, '');
  return {
    ...data,
    phoneNumber: phoneNumber || '',
  };
};

const formatPhoneNumber = (phoneNumber: string | null) => {
  return phoneNumber?.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
};

function removeSpaces(event: FormEvent) {
  const element = event?.currentTarget as HTMLInputElement;
  return element.value?.replace(/\s/g, '');
}
