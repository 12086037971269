import React, { ComponentPropsWithoutRef } from 'react';
import cn from 'classnames';

const icons = {
  account: require('../../assets/icons/account-icon.svg').default,
  cart: require('../../assets/icons/cart-icon.svg').default,
  clock: require('../../assets/icons/clock-icon.svg').default,
  clockSolid: require('../../assets/icons/clock-solid-icon.svg').default,
  close: require('../../assets/icons/close-icon.svg').default,
  confetti: require('../../assets/icons/confetti-icon.svg').default,
  error: require('../../assets/icons/error-icon.svg').default,
  errorCircle: require('../../assets/icons/error-circle-icon.svg').default,
  eye: require('../../assets/icons/eye-icon.svg').default,
  info: require('../../assets/icons/info-icon.svg').default,
  record: require('../../assets/icons/record-icon.svg').default,
  success: require('../../assets/icons/success-icon.svg').default,
  waitlist: require('../../assets/icons/waitlist-icon.svg').default,
};

export enum IconName {
  Account = 'account',
  Cart = 'cart',
  Clock = 'clock',
  ClockSolid = 'clockSolid',
  Close = 'close',
  Confetti = 'confetti',
  Error = 'error',
  ErrorCircle = 'errorCircle',
  Eye = 'eye',
  Info = 'info',
  Success = 'success',
  Record = 'record',
  Waitlist = 'waitlist',
}

export interface IconProps extends ComponentPropsWithoutRef<'img'> {
  name: IconName;
}

export const Icon: React.FC<IconProps> = ({
  name,
  className = '',
  ...props
}) => (
  <img
    alt={name}
    className={cn(className, 'inline-block h-full')}
    data-testid={`icon-${name}`}
    src={icons?.[name]}
    {...props}
  />
);
