import React, { useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import useErrorHandling from '../../hooks/useErrorHandling';
import { useForgotPasswordMutation } from '../Api/apiSlice';
import { ForgotPasswordForm } from '../../components/organisms/ForgotPasswordForm/ForgotPasswordForm';
import { FaCheckCircle } from 'react-icons/fa';

type Inputs = {
  email: string;
};

const ForgotPasswordPage: React.FC = () => {
  const [showSentMessage, setShowSentMessage] = useState(false);
  const [forgotPassword] = useForgotPasswordMutation();
  const handleError = useErrorHandling();

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      await forgotPassword(data).unwrap();
      setShowSentMessage(true);
    } catch (error) {
      handleError({ error });
    }
  };

  return (
    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <ForgotPasswordForm onSubmit={onSubmit} />

        {showSentMessage && (
          <div className={'mt-5 flex items-center font-semibold text-success'}>
            <FaCheckCircle className={'mr-2'} size={'20px'} />
            Reset link sent! Please check your email.
          </div>
        )}
      </div>
    </div>
  );
};

export { ForgotPasswordPage };
