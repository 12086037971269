import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import App from './features/App/App';
import log from 'loglevel';
import '@fontsource/epilogue';
import '@fontsource/roboto';
import 'react-loading-skeleton/dist/skeleton.css';
import './styles/postcss/index.css';
import './styles/scss/index.scss';

// Show all logs when in development mode
// Show warn and error in production mode
log.setLevel(process.env.NODE_ENV === 'development' ? 'trace' : 'warn');

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
