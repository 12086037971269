// From env
export const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY || '';
export const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID || '';
export const FRESHPAINT_ID = process.env.REACT_APP_FRESHPAINT_ID || '';
export const FULLSTORY_ORG_ID = process.env.REACT_APP_FULLSTORY_ORG_ID || '';
export const META_PIXEL_ID = process.env.REACT_APP_META_PIXEL_ID || '';

// Hardcoded
export const TIME_FORMATS = {
  DURATION_HOURS_MINUTES_SECONDS: 'H:mm:ss',
  DURATION_MINUTES_SECONDS: 'm:ss',
};
