// Endpoint: register
export interface RegisterParams {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  token?: string;
}

export interface UserResponse {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  street_address: string;
  city: string;
  state: string;
  zip: string;
  apartment: string;
  country_code: string;
  phone_number: string;
  token: string;
  is_guest: boolean;
}

export interface UserParams {
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  streetAddress: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
  apartment: string | null;
  countryCode: string | null;
  phoneNumber: string | null;
}

// Endpoint: login
export interface LoginParams {
  email: string;
  password: string;
  token?: string | null;
  migrateShopCart?: string | null;
}

export interface FacebookLoginParams {
  accessToken: string;
  token?: string | null;
  migrateShopCart?: string | null;
}

export type LoginResponse = UserResponse;

//Endpoint: passwords/forgot-link
export interface ForgotPasswordParams {
  email: string;
}

//Endpoint: passwords/reset
export interface ResetPasswordParams {
  email: string;
  token: string;
  password: string;
  password_confirmation: string;
}

// Endpoint: getStreams
export type StreamsFromApi = StreamFeed[];

export interface StreamsFromApiParams {
  type: string;
  page?: number;
}

// Endpoint: getLiveStreams
export interface LiveStreamFromApiParams {
  page: number | void;
  shop?: string | null;
}

export interface StreamFeed {
  data: StreamData[];
  links: {
    first: string;
    last: string;
    prev: string | null;
    next: string | null;
  };
  meta: PaginationMeta;
  name: string;
  id: string;
}

export interface PaginationMeta {
  current_page: number;
  from: number | null;
  last_page: number;
  path: string;
  per_page: number;
  to: number | null;
  total: number;
}

export interface StreamData {
  animated_thumb: string;
  current_viewers: number;
  ended_at: number;
  id: number;
  is_live: boolean;
  name: string;
  peak_viewers: number;
  product_count: number;
  shop: string;
  shop_logo: string | null;
  app_icon: string | null;
  favicon: string | null;
  shop_name: string;
  source_thumb: string;
  source_url: string;
  started_at: number;
}

// Endpoint: getStream
export interface StreamFromApiParams {
  shopId: string;
  streamId: number;
}

export interface StreamFromApi {
  animated_thumb: string;
  ended_at: number;
  events: {
    comments: {
      comment: string;
      shown_at: number;
      type: string;
      user_image_url: string;
      user_name: string;
    }[];
    has_more: false;
    reactions: {
      count: number;
      duration: number;
      shown_at: number;
    }[];
  };
  id: number;
  is_live: false;
  is_wide_cell: false;
  label: null;
  name: string;
  peak_viewers: number;
  product_count: number;
  products: [
    {
      badge_label: string | null;
      hidden_at: null;
      identifier: string;
      is_favorite: false;
      overlay_text: [string, string];
      price: number;
      price_label: string;
      product_id: number;
      product_name: string;
      quantity: number;
      shown_at: number;
      thumbnail: null;
    }
  ];
  sharing: {
    display: string;
    shareMessage: string;
    shareTitle: string;
    shareUrl: string;
  };
  source_thumb: string;
  source_url: string;
  started_at: number;
  stream_info: StreamInfoFromApi | null;
}

export interface StreamInfoFromApi {
  auth_url: string;
  current_products: ProductFromStreamInfoFromApi[];
  overlay_text: [string, string];
  pusher_channel: string;
  pusher_cluster: string;
  pusher_key: string;
  reactions_enabled: boolean;
  reactions_rate_limit: number;
  seconds_live: number;
  stream_server: string;
  strikethrough_enabled: boolean;
  user_count: number;
  user_count_display: string;
  user_id: number;
  username: string;
}

export interface ProductFromStreamInfoFromApi {
  allow_waitlist: boolean;
  badge_label: string | null;
  comments: unknown[];
  description: string;
  extra_media: {
    media_url: string;
  }[];
  featured_in_live: boolean;
  filename: string;
  identifier: string;
  image_width: number;
  image_height: number;
  inventory: {
    position: number;
    price: number;
    inventory_id: number;
    quantity: number;
    color: string;
    size: string;
  }[];
  is_favorite: boolean;
  price_label: string;
  product_id: number;
  product_name: string;
  product_type: unknown;
  quantity: number;
  sharing: {
    display: string;
    shareTitle: string;
    shareMessage: string;
    shareUrl: string;
  };
  strikethrough_label: unknown;
  style: string;
  thumbnail: string;
  videos: unknown[];
}
// END Endpoint: getStreams

// Endpoint: getProduct
export interface ProductFromApiParams {
  shopId: string;
  productId: string;
}

export interface ProductFromApi {
  product_id: number;
  product_name: string;
  product_type: string | null;
  description: string;
  quantity: number;
  style: string;
  price_label: string;
  badge_label: string | null;
  strikethrough_label: string | null;
  thumbnail: string;
  filename: string;
  videos: Video[];
  extra_media: ExtraMedia[];
  featured_in_live: boolean;
  image_width: number;
  image_height: number;
  inventory: InventoryItem[];
  comments: unknown[];
  sharing: {
    display: string;
    shareTitle: string;
    shareMessage: string;
    shareUrl: string;
  };
  is_favorite: boolean;
  allow_waitlist: boolean;
  split_color?: string | null;
  split_size?: string | null;
}

export interface InventoryItem {
  position: number;
  price: number;
  inventory_id: number;
  quantity: number;
  color: string;
  size: string;
}

type ExtraMedia =
  | Video
  | {
      media_url: string;
    };

interface Video {
  media_url: string;
  media_type: string;
  thumbnail_url: string;
}

export function isVideo(thing: ExtraMedia): thing is Video {
  // I think this is right.
  return !!(thing as Video).media_type;
}
// END Endpoint: getProduct

// Endpoint: addToCart
export interface AddToCartParams {
  shopId: string;
  userId: number;
  variantId: number;
  experience: string;
  experienceId: number;
  platform: string;
  guestToken?: string | null;
}

export interface AddToCartResponse {
  message: string;
  cart_id?: number;
  guest_cart_id?: number;
  waitlist_id?: number;
}
// END Endpoint: addToCart

// Endpoint: getCart
export interface GetCartParams {
  userId?: number | null;
  guestToken?: string | null;
}

export interface GetCartResponse {
  shop: string;
  shop_name: string;
  locations: {
    id: number;
    state: string;
    zip_code: string;
    address: string;
    default_address: string | null;
  }[];
  location_id: string | null;
  is_local: boolean;
  pay_url: string;
  email: string;
  phone_number: string | null;
  ship_charged: number;
  subtotal: number;
  state_tax: number;
  municipal_tax: number;
  county_tax: number;
  tax_total: number;
  coupon_code: string | null;
  coupon_discount: number;
  deducted_balance: number;
  deducted_payment_balance: number;
  deducted_discount_balance: number;
  free_24shipping_enabled: boolean;
  free_24shipping_expire: string | null;
  free_1shipping_enabled: boolean;
  free_1shipping_expire: string | null;
  prompt_for_email: boolean;
  local_pickup_enabled: boolean;
  checkout_message: string | null;
  items: CartItem[];
  street_address: string | null;
  apartment: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
}

export interface CartItem {
  id: number;
  product_id: number;
  inventory_id: number;
  created_at: number;
  price: number;
  product_name: string;
  color: string;
  size: string;
  comment_id: string | null;
  style: string;
  order_source: number;
  thumbnail: string | null;
  filename: string | null;
  image_width: number | null;
  image_height: string | null | number;
  waitlist: number;
  expires_in: number;
  inventory_not_held: boolean;
  inventory_not_held_message: string | null;
}
// END Endpoint: getCart

// Endpoint: removeFromCart
export interface RemoveFromCartParams {
  shopId: string;
  userId?: number | null;
  variantId: number;
  guestToken?: string | null;
}

export type RemoveFromCartResponse = unknown;
// END Endpoint: removeFromCart

// Endpoint: checkoutCart
export interface CheckoutCartParams {
  userId: number;
  // Optional Stripe card ID to use for checkout. If no card is given, the default payment method will be used instead.
  cardId?: string;
  // Optional notes to include on the order for the merchant.
  note?: string;
}

export type CheckoutCartResponse = {
  shop: string;
  items: CartItem[];
};
// END Endpoint: checkoutCart

export interface GuestCheckoutParams {
  shopId: string;
  guestToken: string;
}

export type GuestCheckoutResponse = {
  success: boolean;
  marketplace_user_id: number;
  jwt: string;
  pay_url: string;
};

export type GetGuestTokenResponse = {
  jwt: string;
};

// Endpoint: guestRegister
export interface GuestRegisterParams {
  email: string;
  password: string;
  passwordConfirmation: string;
}

// Endpoint: createAddress
export interface CreateAddressParams {
  userId: number;
  streetAddress: string;
  city: string;
  state: string;
  zip: string;
  apartment: string;
  isBilling?: boolean;
}

export interface CreateAddressResponse {
  apartment: null | string;
  city: string;
  id: number;
  is_billing: boolean;
  marketplace_user_id: number;
  state: string;
  street_address: string;
  zip: string;
}
// END Endpoint: createAddress

// Endpoint: updateAddress
export interface UpdateAddressParams {
  addressId: number;
  streetAddress: string;
  city: string;
  state: string;
  zip: string;
  apartment: string;
  isBilling?: boolean;
}

export interface UpdateAddressResponse {
  apartment: null | string;
  city: string;
  id: number;
  is_billing: boolean;
  marketplace_user_id: number;
  state: string;
  street_address: string;
  zip: string;
}
// END Endpoint: updateAddress

// Endpoint: getPaymentMethods
export interface GetPaymentMethodsParams {
  userId: number;
}

export type GetPaymentMethodsResponse = PaymentMethod[];

export interface PaymentMethod {
  id: number;
  card_id: string;
  brand: string;
  last_four: string;
  exp_month: string;
  exp_year: string;
  default: boolean;
}
// END Endpoint: getPaymentMethods

// Endpoint: addPaymentMethod
export interface AddPaymentMethodsParams {
  userId: number;
  token: string;
}

export type AddPaymentMethodsResponse = { status: number; data: unknown };
// END Endpoint: addPaymentMethod

// Endpoint: setDefaultPaymentMethod
export interface SetDefaultPaymentMethodParams {
  userId: number;
  cardId: string;
}

export type SetDefaultPaymentMethodResponse = unknown;
// END Endpoint: setDefaultPaymentMethod

export interface RemovePaymentParams {
  userId: number;
  cardId: string;
}

export type RemovePaymentResponse = unknown;

// Endpoint: getShop
export interface GetShopParams {
  shopId: string;
}

export type GetShopResponse = {
  shop_name: string;
  shop_logo: string;
  last_time_live: number;
  profile_links: {
    title: string;
    url: string;
  }[];
  appstore_url: string | null;
  sharing: {
    display: string;
    shareTitle: string;
    shareMessage: string;
    shareUrl: string;
  };
  number_of_followers: number;
  settings: {
    shoppingCartQuantityUpdateEnabled: boolean;
    shoppingCartCanRemoveFromCart: boolean;
    waitlistVariantLimit: number;
    soldKeyword: string | null;
    hideAvailableStockCount: boolean;
    app_logo: string | null;
    app_icon: string | null;
    favicon: string | null;
  };
};
// END Endpoint: getShop

// Endpoint: getWaitlists
export interface GetWaitlistsParams {
  userId: number;
  pageNumber?: number;
  perPage?: number;
}

export interface GetWaitlistsResponse {
  data: {
    badge_label: null;
    color: string;
    created_at: number;
    filename: string | null;
    has_video: boolean;
    id: number;
    image_height: number;
    image_width: number;
    pre_authorization_enabled: boolean;
    preauthorized: boolean;
    price: number;
    price_label: string;
    product_id: number;
    product_name: string;
    shop: string;
    shop_logo: string;
    shop_name: string;
    size: string;
    strikethrough_label: null;
    thumbnail: string | null;
    pay_url: string;
    pre_auth_url: string;
    variant: {
      id: number;
      quantity: number;
      color: string | null;
      size: string | null;
      // Lots of other unneeded data
    };
  }[];
  links: {
    first: string;
    last: string;
    prev: null;
    next: null;
  };
  meta: {
    current_page: number;
    from: number;
    last_page: number;
    path: string;
    per_page: number;
    to: number;
    total: number;
  };
}
// END Endpoint: getWaitlists

export interface GetWaitlistedCartsParams {
  pageNumber?: number;
  perPage?: number;
}

export interface GetWaitlistedCartsResponse {
  data: {
    color: string;
    created_at: number;
    filename: string | null;
    has_video: boolean;
    id: number;
    image_height: number;
    image_width: number;
    price: number;
    product_id: number;
    product_name: string;
    shop: string;
    shop_logo: string;
    shop_name: string;
    size: string;
    thumbnail: string | null;
    pay_url: string;
    variant: {
      id: number;
      quantity: number;
      color: string | null;
      size: string | null;
      price?: number | null;
      // Lots of other unneeded data
    };
  }[];
  links: {
    first: string;
    last: string;
    prev: null;
    next: null;
  };
  meta: {
    current_page: number;
    from: number;
    last_page: number;
    path: string;
    per_page: number;
    to: number;
    total: number;
  };
}
// END Endpoint: getWaitlistedCarts

export interface WaitlistCheckoutParams {
  shopId: string;
  waitlistId: string;
}

export interface WaitlistCheckoutResponse {
  success: boolean;
  cart_id?: number;
  pay_url: string;
}
// END Endpoint: waistlistCheckout

// Endpoint: getOrder
export interface GetOrderParams {
  shopId: string;
  orderId: number;
}

// Endpoint: getOrderHistory
export interface GetOrderHistoryParams {
  userId: number;
  pageNumber?: number;
  perPage?: number;
}

export interface GetOrderHistoryResponse {
  data: OrderResponse[];
  links: {
    first: string;
    last: string;
    prev: null;
    next: null;
  };
  meta: {
    current_page: number;
    from: number;
    last_page: number;
    path: string;
    per_page: number;
    to: number;
    total: number;
  };
}

export type OrderPaymentMethods =
  | 'PayPal'
  | 'Authorize'
  | 'Account Credit'
  | 'Squareup'
  | 'Sezzle'
  | 'Credit Card';

export type OrderResponse = {
  id: number;
  customer_id: number;
  shop: string;
  shop_name: string;
  shop_logo: string;
  company_email: string;
  location: LocalPickupLocation;
  status: Status;
  tracking_url: string | null;
  tracking_number: string | null;
  date: number;
  total: number;
  subtotal: number;
  tax_total: number;
  ship_charged: number;
  apply_balance: number;
  coupon: string | null;
  coupon_discount: number;
  local_pickup: boolean;
  apartment: string | null;
  street: string;
  state: string;
  city: string;
  zip: string;
  country_code: string;
  phone_number: string | null;
  customer_note: string;
  payment_method: OrderPaymentMethods;
  card_last_four: string;
  card_brand: string;
  products: OrderHistoryProductsByTrackingNumber;
};

export type Status =
  | 'Paid'
  | 'Fulfilled'
  | 'Returned'
  | 'Processing'
  | 'Printed';

export type LocalPickupLocation = {
  id: number;
  location: string;
  state: string;
  zip_code: string;
  address: string;
  default_address: string | null;
  warehouse_name: string | null;
} | null;

export type OrderHistoryProductsByTrackingNumber = Record<
  string,
  OrderHistoryProduct[]
>;

export type OrderHistoryProduct = {
  product_id: number;
  product_name: string;
  color: string;
  thumbnail: string | null;
  description: string;
  size: string;
  price: number;
  filename: string | null;
  image_width: number;
  image_height: number;
  tracking_url: string | null;
  tracking_number: string | null;
};
// END Endpoint: getOrderHistory

export interface FollowedShop {
  shop: string;
  shop_name: string;
  shop_logo: string | null;
}

// Endpoint: getFollowedShops
export type GetFollowedShopsResponse = FollowedShop[];

// Endpoint: followShop
export interface FollowShopParams {
  shopId: string;
}

// Endpoint: unfollowShop
export interface UnfollowShopParams {
  shopId: string;
}
