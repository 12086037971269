import React, { useEffect } from 'react';
import { CartContainer } from './CartContainer';
import { setIsSidebarCartOpen } from '../App/uiSlice';
import { useAppDispatch } from '../../store/hooks';
import { Container } from '../../components/layout/Container';

const CartPage: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setIsSidebarCartOpen(false));
  }, [dispatch]);

  return (
    <Container>
      <CartContainer />
    </Container>
  );
};

export { CartPage };
