import React from 'react';
import cn from 'classnames';

interface DropdownInputProps {
  id?: string;
  name: string;
  options: DropdownOptionInterface[];
  defaultValue?: string | null;
  labelText?: string;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  autoComplete?: string;
}

interface DropdownOptionInterface {
  value: string;
  label?: string;
}

const DropdownInput = React.forwardRef<HTMLSelectElement, DropdownInputProps>(
  (
    {
      id,
      name,
      options,
      defaultValue,
      labelText,
      onChange,
      autoComplete,
      ...props
    },
    ref
  ) => {
    const inputClasses = cn(
      'block w-full h-12 text-base border-gray-300 focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm rounded-md',
      {
        'mt-1': labelText,
      }
    );

    return (
      <div>
        {labelText && (
          <label
            className="block text-base font-medium text-gray-700"
            htmlFor={id}
          >
            {labelText}
          </label>
        )}
        <select
          ref={ref}
          autoComplete={autoComplete}
          className={inputClasses}
          defaultValue={defaultValue || ''}
          id={id}
          name={name}
          onChange={onChange}
          {...props}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label || option.value}
            </option>
          ))}
        </select>
      </div>
    );
  }
);

DropdownInput.displayName = 'DropdownInput';

export default DropdownInput;
