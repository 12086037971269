import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store/store';
import { User } from './authTypes';
import { marketplaceApi } from '../Api/apiSlice';
import {
  setGuestToken,
  setToken,
} from '../../helpers/getOrSetLocalStorageItem';

type AuthState = {
  user: User | null;
  shouldShowSignInModal: boolean;
};

const slice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    shouldShowSignInModal: false,
  } as AuthState,
  reducers: {
    setUser: (state, { payload }: PayloadAction<User | null>) => {
      state.user = payload;
    },
    setShouldShowSignInModal: (state, { payload }: PayloadAction<boolean>) => {
      state.shouldShowSignInModal = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      marketplaceApi.endpoints.logout.matchFulfilled,
      (state) => {
        setToken(null);
        state.user = null;
      }
    );
    builder.addMatcher(
      marketplaceApi.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        // useAuth will watch for a token in localStorage
        // and then fetch the user from /me
        setGuestToken(null);
        setToken(payload.token);
      }
    );
    builder.addMatcher(
      marketplaceApi.endpoints.register.matchFulfilled,
      (state, { payload }) => {
        // useAuth will watch for a token in localStorage
        // and then fetch the user from /me
        setGuestToken(null);
        setToken(payload.token);
      }
    );
    builder.addMatcher(
      marketplaceApi.endpoints.guestCheckout.matchFulfilled,
      (state, { payload }) => {
        // useAuth will watch for a token in localStorage
        // and then fetch the user from /me
        setGuestToken(null);
        setToken(payload.jwt);
      }
    );
    builder.addMatcher(
      marketplaceApi.endpoints.facebookLogin.matchFulfilled,
      (state, { payload }) => {
        // useAuth will watch for a token in localStorage
        // and then fetch the user from /me
        setGuestToken(null);
        setToken(payload.token);
      }
    );
  },
});

export const { setUser, setShouldShowSignInModal } = slice.actions;

export default slice.reducer;

export const selectAuth = (state: RootState) => state.auth;
export const selectCurrentUser = createSelector(
  selectAuth,
  (auth) => auth.user
);
