import { createContext, useContext } from 'react';

const makeContext = <T>() => {
  const context = createContext<T>({} as T);
  const useMadeContext = () => useContext(context);

  return { MadeProvider: context.Provider, useMadeContext };
};

export { makeContext };
