import { AnyAction, combineReducers, Reducer } from '@reduxjs/toolkit';
import authReducer from '../features/Auth/authSlice';
import toastReducer from '../features/Toast/toastSlice';
import uiReducer from '../features/App/uiSlice';
import cartReducer from '../features/Cart/cartSlice';
import streamReducer from '../features/Stream/streamSlice';
import trackerReducer from '../features/Tracker/trackerSlice';
import { marketplaceApi } from '../features/Api/apiSlice';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

export const ROOT_RESET_STATE = 'root/resetState';

const combinedReducer = combineReducers({
  router: connectRouter(history) as Reducer,
  [marketplaceApi.reducerPath]: marketplaceApi.reducer,
  auth: authReducer,
  toast: toastReducer,
  ui: uiReducer,
  cart: cartReducer,
  stream: streamReducer,
  tracker: trackerReducer,
});

export const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  switch (action.type) {
    case ROOT_RESET_STATE:
      return combinedReducer(undefined, action);
    default:
      return combinedReducer(state, action);
  }
};

export type RootState = ReturnType<typeof combinedReducer>;
