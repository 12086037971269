import React, { ButtonHTMLAttributes, forwardRef } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { LoadingIndicator } from '../molecules/LoadingIndicator';

export type OutlinedButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  color?: 'primary' | 'secondary' | 'tertiary' | 'accent';
  text?: boolean;
  fullWidth?: boolean;
  rounded?: 'default' | 'full' | null;
  isLoading?: boolean;
  disabled?: boolean;
  asExternalLink?: boolean;
  asInternalLink?: boolean;
  href?: string;
  targetBlank?: boolean;
};

/**
 * This pattern is to prevent build issue with Tailwind.
 * See this: https://tailwindcss.com/docs/content-configuration#dynamic-class-names
 */
const colors = {
  primary: 'border-primary text-primary',
  secondary: 'border-secondary text-secondary',
  tertiary: 'border-tertiary text-tertiary',
  accent: 'border-accent text-accent',
};

export const OutlinedButton = forwardRef<
  HTMLButtonElement,
  OutlinedButtonProps
>(
  (
    {
      children,
      color = 'primary',
      className = '',
      fullWidth = false,
      text = false,
      rounded = 'default',
      asExternalLink = false,
      asInternalLink = false,
      isLoading = false,
      disabled = isLoading,
      href = '',
      targetBlank = false,
      ...props
    },
    ref
  ) => {
    const classes = cn(
      className,
      colors[color],
      `border w-fit flex items-center justify-center px-4 py-2`,
      {
        'w-full': fullWidth,
        rounded: rounded === 'default',
        'rounded-full': rounded === 'full',
        'opacity-50': disabled,
      }
    );

    if (asExternalLink) {
      return (
        // You can trust me, eslint
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line react/jsx-no-target-blank
        <a
          {...props}
          className={classes}
          href={href}
          rel={targetBlank ? 'noreferrer' : undefined}
          target={targetBlank ? '_blank' : undefined}
        >
          {isLoading && <LoadingIndicator inline />}
          {children}
        </a>
      );
    }

    if (asInternalLink) {
      return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Link
          {...props}
          className={classes}
          rel={targetBlank ? 'noreferrer' : undefined}
          target={targetBlank ? '_blank' : undefined}
          to={href}
        >
          {isLoading && <LoadingIndicator inline />}
          {children}
        </Link>
      );
    }

    return (
      <button
        {...props}
        ref={ref}
        className={classes}
        disabled={disabled}
        type={props.type || 'button'}
      >
        {isLoading && <LoadingIndicator inline />}
        {children}
      </button>
    );
  }
);

OutlinedButton.displayName = 'Button';
