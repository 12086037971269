import { subscribeToLOTWEvent } from '@commentsold/live-on-the-web';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTracker } from './useTracker';

export const Tracker: React.FC = ({ children }) => {
  const { init, trackPageView, trackEvent } = useTracker();
  const location = useLocation();

  // initialize
  useEffect(init, [init]);

  // track page views
  useEffect(() => {
    trackPageView(location?.pathname || '');
  }, [location, trackPageView]);

  // track stream views
  useEffect(() => {
    const unsubscribe = subscribeToLOTWEvent('ConnectToStream', (streamId) =>
      trackEvent('view stream', { streamId })
    );
    return unsubscribe;
  }, [trackEvent]);

  // track product views
  useEffect(() => {
    const unsubscribe = subscribeToLOTWEvent(
      'ViewProduct',
      function ({ inventory, extraMedia, videos, comments, ...product }) {
        trackEvent('view product', { ...product });
      }
    );
    return unsubscribe;
  }, [trackEvent]);

  // track comment submission
  useEffect(() => {
    const unsubscribe = subscribeToLOTWEvent('SubmitComment', () =>
      trackEvent('submit comment')
    );
    return unsubscribe;
  }, [trackEvent]);

  // track heart reaction
  useEffect(() => {
    const unsubscribe = subscribeToLOTWEvent('Reaction', (reaction) => {
      trackEvent(`${reaction?.reactionType || ''} reaction`.trim());
    });
    return unsubscribe;
  }, [trackEvent]);

  return <>{children}</>;
};
