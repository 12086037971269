import React from 'react';
import { Container } from '../../components/layout/Container';
import { Checkout } from './Checkout';

const CheckoutPage: React.FC = () => {
  return (
    <Container>
      <Checkout />
    </Container>
  );
};

export { CheckoutPage };
