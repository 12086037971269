import React from 'react';
import { Button } from '../../atoms/Button';
import { Card } from '../../layout/Card';

interface OrderConfirmedCardProps {
  shopName?: string;
  orderId?: string;
  orderDateText?: string;
  showCreateAccount?: boolean;
  onCreateAccountClick?: () => void;
  continueShoppingLink?: string;
}

const OrderConfirmedCard: React.FC<OrderConfirmedCardProps> = ({
  shopName,
  orderId,
  orderDateText,
  showCreateAccount = false,
  onCreateAccountClick,
  continueShoppingLink,
}) => {
  return (
    <Card withBorder withShadow={false}>
      <div className="px-4 py-6">
        <div className="grid grid-cols-1 md:grid-cols-6">
          <div className="col-span-1 hidden self-center text-center text-6xl md:block">
            🎉
          </div>
          <div className="col-span-1 md:col-span-2">
            <h2 className="hidden text-xl font-bold md:block">
              {`Congrats! Your Order with ` + shopName + ` Has Been Confirmed`}
            </h2>
            <div className="flex items-center text-sm font-bold md:hidden">
              <div className="mr-2 text-xl">🎉</div>Congrats! Your Order Has
              Been Confirmed
            </div>
            <div className="flex flex-row space-x-1 text-sm text-gray-700 md:mt-2 md:flex-row">
              <div>Order Number:</div>
              <div>{orderId}</div>
            </div>
            <div className="text-sm text-gray-700 md:mt-1">{orderDateText}</div>
          </div>
          <div className="col-span-1 mt-2 flex justify-center space-y-2 self-center md:col-span-3 md:mt-0">
            {showCreateAccount && (
              <>
                <Button
                  fullWidth
                  primary
                  className="hidden md:block"
                  rounded="full"
                  onClick={onCreateAccountClick}
                >
                  <span className="md:text-lg">Create an Account</span>
                </Button>
                <Button
                  fullWidth
                  primary
                  className="block md:hidden"
                  onClick={onCreateAccountClick}
                >
                  <span className="md:text-lg">Create an Account</span>
                </Button>
              </>
            )}
            <Button
              secondary
              asInternalLink={true}
              className="hidden text-center md:block md:w-4/5 lg:w-3/5 xl:w-1/2"
              href={continueShoppingLink}
              rounded="full"
            >
              <span className="text-lg">Continue Shopping</span>
            </Button>
            <Button
              fullWidth
              secondary
              asInternalLink={true}
              className="block text-center md:hidden"
              href={continueShoppingLink}
            >
              Close & Continue Shopping
            </Button>
          </div>
        </div>
      </div>
    </Card>
  );
};

export { OrderConfirmedCard };
