import { Card } from '../../../layout/Card';
import { Button } from '../../../atoms/Button';
import React from 'react';
import { Order } from './OrderHistory';
import { OrderProductDetails } from './OrderProductDetails';

export function OrderHistoryItem(props: { order: Order; onClick: () => void }) {
  return (
    <Card withBorder={true} withShadow={false}>
      <div
        className={
          'flex items-center justify-between rounded-t bg-csGray px-4 py-2 text-sm'
        }
      >
        <div>
          <h3>Order: {props.order.orderNumber}</h3>
          <div className={' text-textDarkTertiary'}>
            Purchased: {props.order.purchaseDate}
          </div>
          {props.order.localPickupLocation && (
            <div className={'text-sm font-medium'}>Local Pickup</div>
          )}
        </div>

        <div className={'text-textDarkTertiary'}>
          Total: {props.order.total}
        </div>
      </div>

      <div className={'px-4 py-5'}>
        {props.order.orderProducts.map((orderProduct, i) => (
          <div
            key={orderProduct.localIdForFrontend}
            className={
              'mb-7 grid grid-cols-1 sm:flex sm:flex-row-reverse sm:justify-between'
            }
          >
            {i === 0 && (
              <div
                className={
                  'mb-4 flex justify-between space-x-2 sm:mb-0 sm:block sm:space-x-0'
                }
              >
                <Button
                  primary
                  className={'flex-1 sm:mb-2'}
                  style={{ width: 136 }}
                  onClick={props.onClick}
                >
                  Order Details
                </Button>

                <Button
                  outlined
                  asExternalLink={true}
                  className={'flex-1'}
                  href={`mailto:${props.order.shopEmail}`}
                  style={{ width: 136 }}
                >
                  Contact Shop
                </Button>
              </div>
            )}

            <div className={'mr-6 flex-1'}>
              <OrderProductDetails orderProduct={orderProduct} />
            </div>
          </div>
        ))}
      </div>
    </Card>
  );
}
