import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { LoadingIndicator } from '../../components/molecules/LoadingIndicator';
import { CartItemSkeleton } from '../../components/organisms/Cart/CartItemSkeleton';

const WaitlistSkeleton: React.FC = () => {
  return (
    <div className={'w-full'}>
      <LoadingIndicator containerClassName="bg-gray-500 bg-opacity-25" />
      <div className="mt-2 px-4">
        <Skeleton className="p-4" />
      </div>
      <div className="mt-6 px-4">
        <CartItemSkeleton />
      </div>
    </div>
  );
};

export { WaitlistSkeleton };
