import { useCallback } from 'react';
import ReactGA from 'react-ga4';
import * as FS from '@fullstory/browser';
import {
  FRESHPAINT_ID,
  FULLSTORY_ORG_ID,
  GA_MEASUREMENT_ID,
} from '../../config';
import { getGoogleAnalyticsClientId } from '../../helpers/googleAnalytics';
import { setGoogleClientId } from './trackerSlice';
import { useAppDispatch } from '../../store/hooks';

declare global {
  interface Window {
    freshpaint: {
      identify: (id: string | { name: string; email: string }) => void;
      track: (event: string, opts?: unknown) => void;
    };
  }
}

export function useTracker() {
  const dispatch = useAppDispatch();
  const freshpaint = window?.freshpaint;

  const init = useCallback(() => {
    if (GA_MEASUREMENT_ID) {
      ReactGA.initialize([
        {
          trackingId: GA_MEASUREMENT_ID,
          gtagOptions: {
            debug_mode: process.env.NODE_ENV === 'development',
          },
        },
      ]);
      getGoogleAnalyticsClientId().then((id) => {
        dispatch(setGoogleClientId(id ?? null));
      });
    }

    if (FULLSTORY_ORG_ID) {
      FS.init({
        orgId: FULLSTORY_ORG_ID,
      });
    }
  }, [dispatch]);

  const trackPageView = useCallback((path: string) => {
    if (GA_MEASUREMENT_ID) {
      ReactGA.send({ hitType: 'pageview', page: path });
    }
  }, []);

  const identify = useCallback(
    (name: string, email: string) => {
      if (GA_MEASUREMENT_ID) {
        ReactGA.set({ user_id: email, crm_id: email });
      }

      if (FRESHPAINT_ID) {
        freshpaint.identify({ name, email });
      }

      if (FULLSTORY_ORG_ID) {
        FS.identify(email, { name, email });
      }
    },
    [freshpaint]
  );

  const trackEvent = useCallback(
    (event: string, payload: { [key: string]: unknown } = {}) => {
      if (GA_MEASUREMENT_ID) {
        ReactGA.event(
          event
            .trim()
            .replaceAll(/[^a-z]+/gi, '_')
            .toLowerCase(),
          payload
        );
      }

      if (FRESHPAINT_ID) {
        freshpaint.track(event, payload);
      }

      if (FULLSTORY_ORG_ID) {
        FS.event(event, payload);
      }
    },
    [freshpaint]
  );

  return {
    init,
    identify,
    trackPageView,
    trackEvent,
  };
}
