import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useCallback, useEffect, useState } from 'react';
import {
  useGetCartQuery,
  useRemoveFromCartMutation,
  useAddToCartMutation,
  useEmptyCartMutation,
  useGetGuestTokenMutation,
  useGuestCheckoutMutation,
} from '../Api/apiSlice';
import { selectCurrentUser } from '../Auth/authSlice';
import { setCart, selectCart, setIsAddingToCart } from './cartSlice';
import {
  AddToCartParams,
  CartItem,
  RemoveFromCartParams,
} from '../Api/apiTypes';
import useErrorHandling from '../../hooks/useErrorHandling';
import { setIsSidebarCartOpen, setIsSidebarWaitlistOpen } from '../App/uiSlice';
import { selectCurrentStreamId, selectIsLive } from '../Stream/streamSlice';
import { skipToken } from '@reduxjs/toolkit/query';
import { CartItemWithQuantity } from '../../components/organisms/Cart/CartItem';
import { User } from '../Auth/authTypes';
import useShop from '../Shop/useShop';
import { useCallbacks } from '../../context/CallbacksContext';
import {
  getGuestToken,
  setGuestToken,
} from '../../helpers/getOrSetLocalStorageItem';

export default function useCart() {
  const user = useAppSelector(selectCurrentUser) as User;
  const streamId = useAppSelector(selectCurrentStreamId);
  const isLive = useAppSelector(selectIsLive);
  const guestToken = getGuestToken();
  const cart = useAppSelector(selectCart);
  const { setEmptyCartCallback } = useCallbacks();

  const {
    data: cartData,
    isFetching,
    isLoading,
  } = useGetCartQuery(
    user?.id || guestToken ? { guestToken: guestToken } : skipToken
  );

  const [removeFromCartMutation, { isLoading: isRemovingItem }] =
    useRemoveFromCartMutation();
  const [addToCartMutation, { isLoading: isAddingItem }] =
    useAddToCartMutation();
  const [emptyCartMutation, { isLoading: isEmptyingCart }] =
    useEmptyCartMutation();
  const [getGuestTokenMutation] = useGetGuestTokenMutation();
  const [guestCheckoutMutation, { isLoading: isLoadingGuestCheckout }] =
    useGuestCheckoutMutation();

  const dispatch = useAppDispatch();
  const handleError = useErrorHandling();
  const { data: shop } = useShop(cart?.shop);
  const origin = window.location.origin;
  const checkoutUrl = cart ? `${cart.pay_url}&successUrl=${origin}` : '';
  const [itemsWithQuantities, setItemsWithQuantities] = useState<
    CartItemWithQuantity[]
  >([]);

  useEffect(() => {
    setItemsWithQuantities(combineItemsAndAddQuantity(cart?.items));
  }, [cart?.items]);

  useEffect(() => {
    dispatch(setCart(cartData ?? null));
  }, [cartData, dispatch]);

  const guestCheckout = useCallback(async () => {
    if (cart && guestToken) {
      const { pay_url } = await guestCheckoutMutation({
        shopId: cart.shop,
        guestToken,
      }).unwrap();
      window.location.href = `${pay_url}&successUrl=${origin}`;
    }
  }, [cart, guestToken, origin, guestCheckoutMutation]);

  const addItem = useCallback(
    async ({
      shopId,
      variantId,
      quantity = 1,
      skipOpeningSidebar,
      userArg,
      isWaitlist,
    }: {
      shopId: string;
      variantId: number;
      quantity?: number;
      // I don't like the UI concerns being in this hook but doing this for now
      skipOpeningSidebar?: boolean;
      // userArg is a bit of tech debt. There are occasions where the user variable
      // is in the addItem closure and is therefore stale when we call addItem in
      // a callback. So, to have access to the most up-to-date user we have to pass
      // it in as an argument.
      userArg?: User;
      isWaitlist?: boolean;
    }) => {
      try {
        dispatch(setIsAddingToCart(true));
        (!skipOpeningSidebar &&
          isWaitlist &&
          dispatch(setIsSidebarWaitlistOpen(true))) ||
          dispatch(setIsSidebarCartOpen(true));
        let guestTokenJwt = guestToken;

        if (!userArg && !user && !guestTokenJwt) {
          const { jwt } = await getGuestTokenMutation().unwrap();
          setGuestToken(jwt);
          guestTokenJwt = jwt;
        }

        // Probably not the best solution. We should update the API to take quantity
        // and do this server side. This will work for now.
        const values = await Promise.all(
          new Array(quantity).fill(null).map(() =>
            addToCartMutation({
              shopId,
              userId: userArg?.id || user?.id,
              variantId,
              experience: isLive ? 'live' : 'live_replay',
              experienceId: streamId,
              platform: 'marketplace_web',
              // When a userArg is passed in, we are explicitly
              // NOT a guest, regardless of the guestToken in the closure.
              guestToken: userArg ? null : guestTokenJwt,
            } as AddToCartParams).unwrap()
          )
        );

        const lastValue = values[values.length - 1];

        if (lastValue.waitlist_id) {
          dispatch(setIsSidebarCartOpen(false));
          dispatch(setIsSidebarWaitlistOpen(true));
        }
      } catch (error) {
        if (error.status === 409) {
          setEmptyCartCallback(
            // The useState setter can take a function as a setter
            // The value is also a function
            // That's why this extra arrow is needed
            () => () => addItem({ shopId, variantId, quantity })
          );

          return;
        }

        handleError({
          error,
          message: 'Could not add to cart',
        });
      } finally {
        dispatch(setIsAddingToCart(false));
      }
    },
    [
      guestToken,
      user,
      dispatch,
      getGuestTokenMutation,
      addToCartMutation,
      isLive,
      streamId,
      handleError,
      setEmptyCartCallback,
    ]
  );

  const removeItem = async (props: {
    shopId: string;
    userId?: number | null;
    variantId: number;
    guestToken?: string | null;
  }) => {
    try {
      let guestTokenJwt = guestToken;
      if (!user && !guestTokenJwt) {
        const { jwt } = await getGuestTokenMutation().unwrap();
        setGuestToken(jwt);
        guestTokenJwt = jwt;
      }
      const data: RemoveFromCartParams = {
        ...props,
        guestToken: guestTokenJwt,
      };
      removeFromCartMutation(data).unwrap();
    } catch (error) {
      if (error.status === 409) {
        setEmptyCartCallback(() =>
          removeItem({
            shopId: props.shopId,
            variantId: props.variantId,
            userId: props.userId,
            guestToken: props.guestToken,
          })
        );

        return;
      }

      handleError({
        error,
        message: 'Could not remove from cart',
      });
    }
  };

  const changeQuantity = (
    itemToUpdate: CartItemWithQuantity,
    newQuantity: number
  ) => {
    if (!cart?.shop) return;

    const isAdding = newQuantity > itemToUpdate.quantity;

    // Right now, this can only add or subtract one at a time
    if (isAdding) {
      void addItem({
        shopId: cart.shop,
        variantId: itemToUpdate.inventory_id,
        skipOpeningSidebar: true,
      });
    } else {
      void removeItem({
        shopId: cart.shop,
        userId: user.id,
        variantId: itemToUpdate.id,
      });
    }
  };

  const emptyCart = useCallback(async () => {
    await emptyCartMutation({
      userId: user?.id,
      guestToken,
      shopId: cart?.shop,
    }).unwrap();
  }, [emptyCartMutation, user, guestToken, cart]);

  const cartQuantity = countQuantities(itemsWithQuantities);

  return {
    isFetching,
    isLoading,
    addItem,
    isAddingItem,
    removeItem,
    isRemovingItem,
    changeQuantity,
    cartQuantity,
    checkoutUrl,
    itemsWithQuantities,
    shopId: cart?.shop || '',
    shopName: cart?.shop_name || '',
    shopLogo: cart?.shop_logo || null,
    subtotal: cart?.subtotal || 0,
    allowUpdateQuantity:
      shop?.settings?.shoppingCartQuantityUpdateEnabled || false,
    allowRemove: shop?.settings?.shoppingCartCanRemoveFromCart || false,
    isEmptyingCart,
    emptyCart,
    guestCheckout,
    isLoadingGuestCheckout,
  };
}

// PRIVATE

function combineItemsAndAddQuantity(items?: CartItem[]) {
  if (!items) return [];

  return items.reduce<CartItemWithQuantity[]>((newItems, itemToAdd) => {
    // Does the array we are building already have an item in it
    // with the current itemToAdd's inventory_id?
    const alreadyHasItem = newItems.some((item) => {
      return item.inventory_id === itemToAdd.inventory_id;
    });

    return alreadyHasItem
      ? // If so,
        newItems.map((item) =>
          // find that one item
          item.inventory_id === itemToAdd.inventory_id
            ? // then add 1 to the quantity
              { ...item, quantity: item.quantity + 1 }
            : // and leave the other items unchanged
              item
        )
      : // If not,
        // push a new item to the end of the array we are building
        // and set the quantity to 1.
        [...newItems, { ...itemToAdd, quantity: 1 }];
  }, []);
}

function countQuantities(itemsWithQuantities: CartItemWithQuantity[]) {
  return itemsWithQuantities.reduce((acc, curr) => {
    return acc + curr.quantity;
  }, 0);
}
