import { useGetShopQuery } from '../Api/apiSlice';
import { skipToken } from '@reduxjs/toolkit/query';

export default function useShop(shopId?: string | null) {
  const { data, isLoading } = useGetShopQuery(shopId ? { shopId } : skipToken);

  return {
    data,
    isLoading,
  };
}
