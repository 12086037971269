import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_KEY } from '../../config';

const stripePromise = loadStripe(STRIPE_KEY);

export const StripeProvider: React.FC = ({ children }) => (
  <Elements stripe={stripePromise}>{children}</Elements>
);
