import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Nav.module.css';
import cn from 'classnames';
import {
  selectIsSidebarCartOpen,
  selectIsSidebarWaitlistOpen,
  setIsSidebarCartOpen,
  setIsSidebarWaitlistOpen,
} from '../../../features/App/uiSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { Icon, IconName } from '../../atoms/Icon';
import { selectCurrentUser } from '../../../features/Auth/authSlice';

const Nav: React.FC<{ numCartItems?: number; numWaitlistItems?: number }> = ({
  numCartItems,
  numWaitlistItems,
}) => {
  const dispatch = useAppDispatch();
  const isSidebarCartOpen = useAppSelector(selectIsSidebarCartOpen);
  const isSidebarWaitlistOpen = useAppSelector(selectIsSidebarWaitlistOpen);
  const user = useAppSelector(selectCurrentUser);
  const showWaitlist = user && !user.isGuest;

  const toggleSidebarWaitlist = () => {
    dispatch(setIsSidebarWaitlistOpen(!isSidebarWaitlistOpen));
  };

  const toggleSidebarCart = () => {
    dispatch(setIsSidebarCartOpen(!isSidebarCartOpen));
  };

  return (
    <nav className={cn(styles.nav)}>
      <Link data-testid="nav-header-link" to="/">
        <h1>Spreesy</h1>
      </Link>

      <div className="flex flex-shrink-0 gap-4 space-x-2">
        <Link
          className="inline-block"
          data-testid="nav-header-account-link"
          to="/account"
        >
          <Icon name={IconName.Account} />
        </Link>

        {showWaitlist && (
          <button
            className="inline-block"
            data-testid="nav-cart-button-waitlist"
            onClick={toggleSidebarWaitlist}
          >
            <IconWithBadge
              iconName={IconName.Clock}
              numVal={numWaitlistItems}
            />
          </button>
        )}

        <button
          className="inline-block"
          data-testid="nav-cart-button-cart"
          onClick={toggleSidebarCart}
        >
          <IconWithBadge iconName={IconName.Cart} numVal={numCartItems} />
        </button>
      </div>
    </nav>
  );
};

export { Nav };

// PRIVATE

function IconWithBadge(props: {
  iconName: IconName;
  numVal: number | undefined;
}) {
  return (
    <div className={'relative inline-block h-full overflow-visible'}>
      <Icon name={props.iconName} />

      {!!props.numVal && (
        <span
          className={
            'absolute flex items-center justify-center rounded-full bg-black p-1 font-semibold text-white'
          }
          style={{
            width: 16,
            height: 16,
            fontSize: 10,
            top: -3,
            right: 3,
          }}
        >
          {props.numVal}
        </span>
      )}
    </div>
  );
}
