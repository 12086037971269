import { useCallback, useMemo, useState } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeError } from '@stripe/stripe-js';
import useErrorHandling from '../../../hooks/useErrorHandling';
import { useAppSelector } from '../../../store/hooks';
import AuthError from '../../../types/AuthError';
import {
  useAddPaymentMethodMutation,
  useGetPaymentMethodsQuery,
  useRemovePaymentMethodMutation,
  useSetDefaultPaymentMethodMutation,
} from '../../Api/apiSlice';
import { selectCurrentUser } from '../../Auth/authSlice';
import { PaymentMethod } from '../../Api/apiTypes';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';

export function usePaymentMethods() {
  const stripe = useStripe();
  const elements = useElements();
  const handleError = useErrorHandling();
  const user = useAppSelector(selectCurrentUser);
  const [addPaymentMethod] = useAddPaymentMethodMutation();
  const [setDefaultPaymentMethod, { isLoading: isMakingPrimary }] =
    useSetDefaultPaymentMethodMutation();
  const [removePaymentMethod] = useRemovePaymentMethodMutation();
  const { data: paymentMethodsRaw, isFetching } = useGetPaymentMethodsQuery({
    userId: user?.id,
  });
  const [selectedPayment, setSelectedPayment] = useState<PaymentMethod>();

  const paymentMethods = useMemo(
    () => sortBy(paymentMethodsRaw, ['id']),
    [paymentMethodsRaw]
  );

  const handleCardError = useCallback(
    (error?: StripeError) => {
      handleError({
        error,
        message: 'There was an error verifying your card',
      });
    },
    [handleError]
  );

  const addCreditCard = useCallback(async () => {
    try {
      if (!user) {
        throw new AuthError('You must sign in.');
      }

      // Ensure we have the stripe hook data we need
      if (!stripe || !elements) {
        return handleCardError();
      }

      // Ensure we have a cardElement
      const cardElement = elements.getElement(CardElement);

      if (!cardElement) {
        return handleCardError();
      }

      // Create the payment method and ensure it worked
      const { error: errorCreatingPaymentMethod, paymentMethod } =
        await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
        });

      if (errorCreatingPaymentMethod || !paymentMethod?.id) {
        return handleCardError(errorCreatingPaymentMethod);
      }

      // Create the token and ensure it worked
      const { error: errorCreatingToken, token } = await stripe.createToken(
        cardElement
      );

      if (errorCreatingToken || !token) {
        return handleCardError(errorCreatingToken);
      }

      // Send that token to the API
      if (!user) {
        throw new AuthError('You must sign in.');
      }

      return addPaymentMethod({
        userId: user.id,
        token: token.id,
      }).unwrap();
    } catch (error) {
      return handleCardError(error as StripeError);
    }
  }, [addPaymentMethod, elements, handleCardError, stripe, user]);

  const removePayment = useCallback(
    (payment: PaymentMethod) => {
      if (!user) {
        throw new AuthError('You must sign in.');
      }

      try {
        return removePaymentMethod({
          userId: user.id,
          cardId: payment.card_id,
        }).unwrap();
      } catch (error) {
        handleError({
          error,
          message: get(error, 'data.message') || error,
        });
      }
    },
    [handleError, removePaymentMethod, user]
  );

  const makePrimary = useCallback(
    (payment: PaymentMethod) => {
      if (!user) {
        throw new AuthError('You must sign in.');
      }

      return setDefaultPaymentMethod({
        userId: user.id,
        cardId: payment.card_id,
      }).unwrap();
    },
    [setDefaultPaymentMethod, user]
  );

  return {
    addCreditCard,
    isFetching,
    isMakingPrimary,
    makePrimary,
    paymentMethods,
    selectedPayment,
    setSelectedPayment,
    removePayment,
  };
}
