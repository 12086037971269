import React from 'react';
import wavyBuddiesOrderCancelled from '../../assets/wavy-buddies-order-cancelled.svg';
import { Link } from 'react-router-dom';

const WaitlistEmpty: React.FC = () => {
  return (
    <div className={'flex h-full flex-col items-center justify-between p-4'}>
      {/* Spacer */}
      <div className={'h-1/5'} />

      <div className={'flex h-full flex-1 flex-col items-center text-center'}>
        <figure className={'h-72'}>
          <img
            alt={'Shopping bags flying away'}
            src={wavyBuddiesOrderCancelled}
          />
        </figure>

        <p className={'mt-4 font-semibold'}>
          You don&apos;t have any items in your waitlist!
        </p>

        <p className={'mt-1 text-sm text-textDarkSecondary'}>
          View the{' '}
          <Link className={'underline hover:no-underline'} to={'/'}>
            Discover
          </Link>{' '}
          page to start shopping now!
        </p>
      </div>
    </div>
  );
};

export { WaitlistEmpty };
