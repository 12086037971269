import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  SignUpInputs,
  SignUpForm,
} from '../../components/organisms/SignUpForm/SignUpForm';
import useAuth from './useAuth';

export const SignUpPage: React.FC = () => {
  const { registerUser } = useAuth();
  const history = useHistory();

  const onSubmit = async (data: SignUpInputs) => {
    await registerUser(data);
    history.push('/');
  };

  return (
    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div className="relative bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <SignUpForm
          onSignIn={() => history.push('/sign-in')}
          onSkipSignIn={() => history.push('/')}
          onSubmit={onSubmit}
        />
      </div>
    </div>
  );
};
