import React from 'react';
import cn from 'classnames';

type InputWithLabelProps = React.HTMLProps<HTMLInputElement> & {
  id?: string;
  icon?: JSX.Element;
  showButton?: boolean;
  buttonText?: string;
  onClick?: () => void;
  showAppendIcon?: boolean;
  appendIcon?: JSX.Element;
  onClickAppendIcon?: () => void;
  appendIconId?: string;
  labelText?: string;
  error?: string | boolean;
};

const InputWithLabel = React.forwardRef<HTMLInputElement, InputWithLabelProps>(
  (
    {
      id,
      icon,
      showButton,
      buttonText,
      onClick,
      labelText,
      error = '',
      type = 'text',
      showAppendIcon,
      appendIcon,
      onClickAppendIcon,
      appendIconId = '',
      ...props
    },
    ref
  ) => {
    return (
      <div>
        {labelText && (
          <label
            className="block text-base font-medium text-gray-700"
            htmlFor={id}
          >
            {labelText}
          </label>
        )}
        <div className="relative rounded-md shadow-sm">
          {icon && (
            <div
              className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
              data-testid="input-button-icon-wrapper"
            >
              {icon}
            </div>
          )}
          <input
            {...props}
            ref={ref}
            className={cn('block h-12 w-full rounded-md sm:text-sm ', {
              'pr-10': showAppendIcon,
              'pl-10': icon,
              'mt-1': labelText,
              'border border-gray-300 focus:ring-gray-500': !error,
              'focus:outline-error border-2 border-error focus:border-error focus:ring-error':
                !!error,
            })}
            id={id}
            type={type}
          />

          {showAppendIcon && (
            <div className="absolute inset-y-0 right-0 flex items-center pr-4">
              <button
                id={appendIconId}
                type="button"
                onClick={onClickAppendIcon}
              >
                {appendIcon}
              </button>
            </div>
          )}

          {showButton && (
            <div className="absolute inset-y-0 right-0 flex items-center">
              <button type="button" onClick={onClick}>
                <span>{buttonText}</span>
              </button>
            </div>
          )}
        </div>

        {error && <p className="mt-1 text-sm text-error">{error}</p>}
      </div>
    );
  }
);
InputWithLabel.displayName = 'InputWithLabel';

export { InputWithLabel };
