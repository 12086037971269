import React, { useCallback, useMemo, useState } from 'react';
import { InventoryItem, isVideo, ProductFromApi } from '../Api/apiTypes';
import removeMd from 'remove-markdown';
import { ImageWithFallback } from '../../components/atoms/ImageWithFallback';
import styles from './ProductDetail.module.css';
import { MediaGallery } from '../../components/molecules/MediaGallery';
import { v4 } from 'uuid';
import { VariantSelector } from '../../components/organisms/VariantSelector/VariantSelector';

interface ProductDetailProps {
  product: ProductFromApi;
  soldText?: string;
  onAdd: (variantId: number) => void;
}

const ProductDetail: React.FC<ProductDetailProps> = ({
  product,
  soldText,
  onAdd,
}) => {
  const [selectedVariant, setSelectedVariant] = useState<
    InventoryItem | undefined
  >();

  const images = useMemo(
    () =>
      product.extra_media
        .filter((mediaItem) => !isVideo(mediaItem))
        .map((image) => {
          return {
            id: v4(),
            src: image.media_url,
            alt: product.product_name,
          };
        }),
    [product.extra_media, product.product_name]
  );

  const videos = useMemo(
    () =>
      product.videos.map((video) => {
        return {
          id: v4(),
          videoSrc: video.media_url,
          src: video.thumbnail_url,
          alt: product.product_name,
        };
      }),
    [product.videos, product.product_name]
  );

  const media = useMemo(() => [...videos, ...images], [videos, images]);

  const handleChooseVariant = useCallback(
    (item?: InventoryItem) => {
      setSelectedVariant(item);
    },
    [setSelectedVariant]
  );

  const handleAdd = async () => {
    if (typeof selectedVariant?.inventory_id !== 'number') {
      throw new Error('No variant found');
    } else {
      onAdd(selectedVariant?.inventory_id);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className="flex justify-between">
        <ImageWithFallback
          alt={product.product_name}
          className="rounded-md object-cover"
          src={product.thumbnail}
          style={{ height: 65, width: 65 }}
        />

        <div className="mx-2 flex-1">
          <h3 className="text-sm font-bold text-black">
            {product.product_name}
          </h3>
          {soldText && <div className="text-sm">{removeMd(soldText)}</div>}
        </div>

        <div>
          <div className="text-lg font-bold text-black">
            {product.price_label}
          </div>
        </div>
      </div>

      <hr className="my-4" />

      <VariantSelector product={product} onChoose={handleChooseVariant} />

      <h4 className="mt-6">Description</h4>
      <p className="mt-2">{product.description}</p>

      <div className="mt-8">
        <MediaGallery media={media} />
      </div>

      {selectedVariant && (
        <div className="absolute bottom-6 z-20 w-full text-center">
          <button
            className="focus:outline-none inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-xl shadow-sm hover:bg-indigo-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            type="button"
            onClick={handleAdd}
          >
            {`${selectedVariant.quantity ? 'Add to Cart' : 'Add to Waitlist'}`}
          </button>
        </div>
      )}
    </div>
  );
};

export { ProductDetail };
