import React, { useState } from 'react';
import { Modal } from '../../components/molecules/Modal';
import {
  SignInInputs,
  SignInForm,
} from '../../components/organisms/SignInForm/SignInForm';
import useErrorHandling from '../../hooks/useErrorHandling';
import {
  useForgotPasswordMutation,
  useRegisterMutation,
} from '../Api/apiSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectAuth, setShouldShowSignInModal } from './authSlice';
import {
  SignUpForm,
  SignUpInputs,
} from '../../components/organisms/SignUpForm/SignUpForm';
import {
  ForgotPasswordForm,
  ForgotPasswordInputs,
} from '../../components/organisms/ForgotPasswordForm/ForgotPasswordForm';
import useToast from '../Toast/useToast';
import { ToastType } from '../Toast/toastSlice';
import { useCallbacks } from '../../context/CallbacksContext';
import useAuth from './useAuth';
import convertUserResToUser from './helpers/convertUserResToUser';
import { User } from './authTypes';
import { logOutIfLoggedInFacebook } from '../../helpers/facebookSdk';

export const SignInModal: React.FC = () => {
  const { shouldShowSignInModal } = useAppSelector(selectAuth);
  const { signInCallback, setSignInCallback } = useCallbacks();
  const dispatch = useAppDispatch();
  const { setToastWithTimeout } = useToast();
  const { loginUser, loginWithFacebook } = useAuth();

  const [registerNewUser] = useRegisterMutation();
  const [forgotPassword] = useForgotPasswordMutation();
  const handleError = useErrorHandling();
  const [form, setForm] = useState<'signIn' | 'signUp' | 'forgotPassword'>(
    'signIn'
  );

  const handleClose = () => {
    setSignInCallback(undefined);
    dispatch(setShouldShowSignInModal(false));

    setTimeout(() => {
      // So it doesn't get stuck on forgotPassword.
      // Timeout is to make sure it happens after the animation.
      setForm('signIn');
    }, 1000);
  };

  const handleUserAndClose = (user: User) => {
    // See CartFullModal for a similar pattern.
    // This timeout is due to a CSS problem when opening the sidebar from this callback
    // and then immediately closing this modal at setSignInCallback(undefined) below.
    // This one-second wait time makes sure that this modal is fully closed, and the
    // transition is completed, before a new transition is started when opening the sidebar.
    // Doing it this way was way easier than trying to hack out the CSS problem. The UX is fine too.
    setTimeout(() => {
      signInCallback?.(user);
    }, 1000);

    handleClose();
  };

  const handleSignIn = async (data: SignInInputs) => {
    try {
      const userRes = await loginUser(data);
      const user = convertUserResToUser(userRes);

      setToastWithTimeout({
        type: ToastType.success,
        message: 'You are now signed in!',
      });

      handleUserAndClose(user);
    } catch (error) {
      handleError({ error });
      throw error;
    }
  };

  const handleSignUp = async (data: SignUpInputs) => {
    try {
      const userRes = await registerNewUser(data).unwrap();

      const user = convertUserResToUser(userRes);

      setToastWithTimeout({
        type: ToastType.success,
        message: 'You are now signed in!',
      });

      handleUserAndClose(user);
    } catch (error) {
      handleError({ error });
    }
  };

  const handleForgotPassword = async (data: ForgotPasswordInputs) => {
    try {
      await forgotPassword(data).unwrap();
      setToastWithTimeout({
        type: ToastType.success,
        message: 'Reset link sent! Please check your email!',
      });

      handleClose();
    } catch (error) {
      handleError({ error });
    }
  };

  const handleLoginWithFacebook = async () => {
    try {
      const userRes = await loginWithFacebook();
      const user = convertUserResToUser(userRes);

      setToastWithTimeout({
        type: ToastType.success,
        message: 'You are now signed in!',
      });

      handleUserAndClose(user);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      await logOutIfLoggedInFacebook();
      handleError({ error, message: error?.data?.error });
      throw error;
    }
  };

  const isShown = shouldShowSignInModal || !!signInCallback;

  return (
    <Modal isShown={isShown} onClose={handleClose}>
      <div className="p-4">
        {form === 'signIn' && (
          <SignInForm
            hideSkipSignIn={true}
            onFacebookLogin={handleLoginWithFacebook}
            onForgotPassword={() => setForm('forgotPassword')}
            onSignUp={() => setForm('signUp')}
            onSkipSignIn={handleClose}
            onSubmit={handleSignIn}
          />
        )}

        {form === 'signUp' && (
          <SignUpForm
            hideSkipSignIn={true}
            onSignIn={() => setForm('signIn')}
            onSkipSignIn={handleClose}
            onSubmit={handleSignUp}
          />
        )}

        {form === 'forgotPassword' && (
          <ForgotPasswordForm onSubmit={handleForgotPassword} />
        )}
      </div>
    </Modal>
  );
};
