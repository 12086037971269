import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ImageWithFallback } from '../atoms/ImageWithFallback';
import imagePlaceholder from '../../assets/image-placeholder.png';
import { FaPlay } from 'react-icons/fa';
import SwiperClass from 'swiper/types/swiper-class';
import 'swiper/swiper.scss';
import { LightboxMediaGallery } from './LightboxMediaGallery';

interface Media {
  id: string;
  videoSrc?: string;
  src: string;
  alt: string;
}

interface MediaGalleryProps {
  media: Media[];
}

const MediaGallery: React.FC<MediaGalleryProps> = ({ media }) => {
  const [openedMediaIndex, setOpenedMediaIndex] = useState<
    number | undefined
  >();

  const handleMediaClick = (swiper: SwiperClass) => {
    setOpenedMediaIndex(swiper.clickedIndex);
  };

  return (
    <>
      {openedMediaIndex !== undefined && (
        <LightboxMediaGallery
          media={media}
          selectedIndex={openedMediaIndex}
          onClose={() => setOpenedMediaIndex(undefined)}
        />
      )}

      <Swiper
        slidesPerView="auto"
        spaceBetween={16}
        style={{ width: '100%' }}
        onClick={handleMediaClick}
      >
        {media.map((mediaItem) => {
          return (
            <SwiperSlide key={mediaItem.id} style={{ width: 150, height: 150 }}>
              <button>
                {mediaItem.videoSrc && (
                  <div className="absolute inset-0 flex items-center justify-center rounded-md bg-gray-500 bg-opacity-75 transition-opacity">
                    <FaPlay color="white" size="2rem" />
                  </div>
                )}

                <ImageWithFallback
                  alt={mediaItem.alt}
                  className="rounded-md object-cover"
                  fallbackSrc={imagePlaceholder}
                  src={mediaItem.src}
                  style={{ width: '100%', height: '100%' }}
                />
              </button>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
};

export { MediaGallery };
